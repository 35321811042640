var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticStyle:{"padding-top":"68px","background-color":"#868686"}},[(_vm.AppStateStore.getCurrentTab === 'newContract')?[_c('NewContract',{attrs:{"grave":_vm.currentGrave},on:{"contractAdded":_vm.contractAdded}})]:_vm._e(),(_vm.AppStateStore.getCurrentTab === 'map')?[_c('vl-map',{ref:"map",staticStyle:{"position":"relative","background-color":"#868686"},attrs:{"data-projection":"EPSG:4326","move-tolerance":2},on:{"singleclick":_vm.singleClick}},[_c('vl-view',{attrs:{"zoom":_vm.mapProperties.zoom,"center":_vm.mapProperties.center,"rotation":_vm.mapProperties.rotation,"min-zoom":1,"max-zoom":23},on:{"update:zoom":function($event){return _vm.$set(_vm.mapProperties, "zoom", $event)},"update:center":function($event){return _vm.$set(_vm.mapProperties, "center", $event)},"update:rotation":function($event){return _vm.$set(_vm.mapProperties, "rotation", $event)}}}),_c('vl-layer-tile',[_c('vl-source-xyz',{attrs:{"url":_vm.mapProperties.url}})],1),_c('MapFeature',{attrs:{"coordinates":_vm.getFeatureCoordinates(_vm.$root.location),"color":'#304fff',"featureRadius":10,"border":{ display: true, width: 2, color: 'white' }}}),_c('MapFeatureCollection',{attrs:{"features":_vm.graves.valid_inactive,"icon":{ display: true, path: 'goal_noNumber.svg' }}}),_c('MapFeatureCollection',{attrs:{"features":_vm.graves.unassigned_inactive,"icon":{
          display: true,
          path: 'goal_unassigned.svg',
        }}}),_c('MapFeatureCollection',{attrs:{"features":_vm.graves.valid_active,"icon":{ display: true, path: 'goal.svg' }}}),_c('MapFeatureCollection',{attrs:{"features":_vm.graves.unassigned_active,"icon":{ display: true, path: 'goal_noPerson.svg' }}}),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogs.unassign),callback:function ($$v) {_vm.$set(_vm.dialogs, "unassign", $$v)},expression:"dialogs.unassign"}},[(
              _vm.currentGrave.status == 'valid_active' ||
              _vm.currentGrave.status == 'valid_inactive'
            )?[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" "+_vm._s(_vm.currentGrave.number)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"red"},on:{"click":function($event){return _vm.unassignNumber(_vm.currentGrave.id)}}},[_vm._v(" "+_vm._s(_vm.$t("unassign_number"))+" ")])],1)],1)]:_vm._e()],2)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogs.assign),callback:function ($$v) {_vm.$set(_vm.dialogs, "assign", $$v)},expression:"dialogs.assign"}},[_c('v-card',[_c('v-flex',{staticClass:"py-0",attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":_vm.unassignedGraveList,"item-text":"number","return-object":"","label":_vm.$t('grave_number_'),"required":"","no-data-text":_vm.$t('no_data_text')},model:{value:(_vm.unassignedGraveData),callback:function ($$v) {_vm.unassignedGraveData=$$v},expression:"unassignedGraveData"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"disabled":!_vm.unassignedGraveData.number,"color":"blue"},on:{"click":function($event){return _vm.assignNumber(_vm.currentGrave)}}},[_vm._v(" "+_vm._s(_vm.$t("assign_number"))+" ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogs.assigmentMethod),callback:function ($$v) {_vm.$set(_vm.dialogs, "assigmentMethod", $$v)},expression:"dialogs.assigmentMethod"}},[[_c('v-card',[_c('v-card-actions',[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"blue"},on:{"click":function($event){return _vm.switchToAssignNumber()}}},[_vm._v(" "+_vm._s(_vm.$t("from_list"))+" ")])],1)],1)],[_c('v-card',[_c('v-card-actions',[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"blue"},on:{"click":function($event){return _vm.switchToInputData()}}},[_vm._v(" "+_vm._s(_vm.$t("input_gravedata"))+" ")])],1)],1)]],2)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }