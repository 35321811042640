<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("contracts") }}
      </v-card-text>
      <v-divider></v-divider>

      <v-card class="elevation-5 mx-5">
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            :label="$t('search')"
            single-line
            hide-details
            left
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="contracts"
          :search="search"
          :footer-props="{
            itemsPerPageText: '',
            itemsPerPageAllText: $t('all'),
          }"
        >
          <template #item.grave_number="{ item }">
            {{ item.grave_number }}
          </template>

          <template #item.graveyard="{ item }">
            {{ item.graveyard }}
          </template>
          <template #item.firstname="{ item }">
            {{ item.firstname }}
          </template>
          <template #item.lastname="{ item }">
            {{ item.lastname }}
          </template>
          <template #item.address="{ item }">
            {{ item.address }}
          </template>
          <template #item.description="{ item }">
            {{ item.description }}
          </template>

          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }} /
            {{ items.itemsLength }}
          </template>
          <template slot="no-results">
            {{ $t("no_results") }}
          </template>
          <template v-slot:no-data>
            {{ $t("no_data_text") }}
          </template>
        </v-data-table>
      </v-card>
      <download-csv
        :data="contracts"
        name="Vertraege.csv"
        delimiter=";"
        class="mr-5 pr-5"
      >
        <v-btn
          class="ml-5 mt-5"
          style="min-width: 100%"
          color="info"
          @click="deleteContracts()"
        >
          {{ $t("export") }}
        </v-btn>
      </download-csv>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
export default {
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
  data() {
    return {
      search: "",
      contracts: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("grave_number_"),
          sortable: true,
          value: "grave_number",
        },
        { text: this.$t("graveyard"), value: "graveyard", sortable: true },
        { text: this.$t("firstname"), value: "firstname", sortable: true },
        { text: this.$t("lastname"), value: "lastname", sortable: true },
        { text: this.$t("address"), value: "address", sortable: true },
        { text: this.$t("description"), value: "description", sortable: true },
      ];
    },
  },
  methods: {
    async getNewContracts() {
      let formData = new FormData();
      formData.set("getNewContracts", true);

      try {
        const response = await this.$root.postData(formData);
        this.contracts = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async deleteContracts() {
      let formData = new FormData();
      formData.set("deleteContracts", true);

      await this.$root.postData(formData);
      this.getNewContracts();
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getNewContracts);
    this.getNewContracts();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getNewContracts);
  },
};
</script>
