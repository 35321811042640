var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vl-map',{ref:"map",staticStyle:{"position":"absolute","background-color":"#868686"},attrs:{"data-projection":"EPSG:4326","move-tolerance":2}},[_c('vl-view',{attrs:{"zoom":_vm.zoom,"center":_vm.center,"rotation":_vm.rotation,"min-zoom":1,"max-zoom":23},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.center=$event},"update:rotation":function($event){_vm.rotation=$event}}}),_c('vl-layer-tile',{attrs:{"z-index":0}},[_c('vl-source-osm')],1),_c('vl-layer-tile',{attrs:{"z-index":1}},[_c('vl-source-xyz',{attrs:{"url":_vm.url}})],1),(!_vm.AppStateStore.getAppLoading && _vm.locationSnappedToFootPath.geometry)?_c('Route',{attrs:{"route":[
      _vm.mainRoute[0],
      [
        parseFloat(_vm.locationSnappedToFootPath.geometry.coordinates[0]),
        parseFloat(_vm.locationSnappedToFootPath.geometry.coordinates[1]),
      ],
    ],"routeColor":'#2979ff'}}):_vm._e(),(
      !_vm.AppStateStore.getAppLoading &&
      _vm.$root.location &&
      _vm.locationSnappedToFootPath.geometry
    )?_c('Route',{attrs:{"route":[
      _vm.getFeatureCoordinates(_vm.$root.location),
      [
        parseFloat(_vm.locationSnappedToFootPath.geometry.coordinates[0]),
        parseFloat(_vm.locationSnappedToFootPath.geometry.coordinates[1]),
      ],
    ],"routeColor":'#2979ff',"width":6,"lineDash":{ display: true, distances: [5, 10] }}}):_vm._e(),(!_vm.AppStateStore.getAppLoading && _vm.mainRoute)?_c('Route',{attrs:{"route":_vm.mainRoute,"routeColor":'#2979ff'}}):_vm._e(),(_vm.$root.location)?_c('vl-layer-vector',[_c('MapFeature',{attrs:{"coordinates":_vm.getFeatureCoordinates(_vm.$root.location),"color":'#304fff',"featureRadius":10,"border":{ display: true, width: 2, color: 'white' }}})],1):_vm._e(),(_vm.grave)?_c('MapFeature',{attrs:{"coordinates":[parseFloat(this.grave.lon), parseFloat(this.grave.lat)],"color":'#76FF03'}}):_vm._e(),_c('BottomMenu',{attrs:{"marginBottom":'56px',"toggled":_vm.bottomMenuToggled,"grave":_vm.grave,"displayBottomMenu":true},on:{"toggle":_vm.toggleBottomMenu}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }