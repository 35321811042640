<template>
  <v-card flat>
    <v-img
      class="thumbnail float-center mb-2"
      :src="getGraveImage"
      aspect-ratio="1.7"
      contain
      max-height="500px"
      style="background: grey; border-radius: 0"
    >
      <template v-if="canUpload">
        <input
          ref="image"
          id="imgInp"
          type="file"
          name="image"
          accept="image/*"
          capture="camera"
          style="display: none"
          @change="onFilePicked"
        />
        <v-btn
          fab
          small
          color="blue"
          right
          bottom
          class="mr-5"
          style="margin-bottom: 30px"
          absolute
          @click="takePicture()"
        >
          <v-icon>camera_alt</v-icon>
        </v-btn>
      </template>
    </v-img>
  </v-card>
</template>

<script>
import { getImage } from "@utils/utilFunctions";

export default {
  name: "GraveImage",
  props: {
    graveId: Number,
    canUpload: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    getGraveImage() {
      return this.getImage(this.graveId);
    },
  },
  methods: {
    getImage,
    takePicture() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const file = e.target.files[0];
      if (!file) return;

      if (file.name.lastIndexOf(".") <= 0) {
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        this.uploadImage(file);
      };
      reader.readAsDataURL(file);
    },

    async uploadImage(image) {
      let formData = new FormData();

      formData.append("uploadImage", true);
      formData.append("id", this.graveId);
      formData.append("image", image);

      await this.$root.postData(formData);
      this.$emit("imageUploaded");
    },
  },
};
</script>
