<template>
  <v-card class="elevation-5">
    <v-card-title>
      <v-text-field
        v-model="searchFieldContent"
        prepend-icon="search"
        :label="$t('search')"
        single-line
        hide-details
        left
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="searchFieldContent"
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageAllText: $t('all'),
        'items-per-page-options': [10, 100, 200, 500, -1],
      }"
    >
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>
            {{ $t("users") + ": " + customerName }}
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="newUserDialog = true"
          >
            {{ $t("new_user") }}
          </v-btn>

          <NewUserDialog
            v-if="newUserDialog"
            @createUser="createUser"
            @cancel="newUserDialog = false"
          />
        </v-toolbar>
      </template>

      <template v-if="$root.isLanguageGerman" #item.role="{ item }">
        {{ item.role_de }}
      </template>
      <template v-else #item.role="{ item }">
        {{ item.role_en }}
      </template>
      <template #item.username="{ item }">{{ item.username }}</template>
      <template #item.firstname="{ item }">{{ item.firstname }}</template>
      <template #item.lastname="{ item }">{{ item.lastname }}</template>
      <template #item.delete="{ item }">
        <v-btn
          fab
          small
          dark
          color="red"
          @click="
            userToDelete = item.id;
            deleteUserDialog = true;
          "
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>
      <template slot="no-results">
        {{ $t("no_results") }}
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_text") }}
      </template>
    </v-data-table>

    <v-layout row justify-center>
      <ConfirmDialog
        :text="$t('delete_question_user')"
        :show="deleteUserDialog"
        @confirm="
          deleteUser();
          deleteUserDialog = false;
        "
        @cancel="
          deleteUserDialog = false;
          userToDelete = '';
        "
      />
    </v-layout>
  </v-card>
</template>

<script>
import NewUserDialog from "@components/dialogs/NewUserDialog.vue";
import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";
export default {
  name: "UsersTable",
  components: {
    NewUserDialog,
    ConfirmDialog,
  },
  props: {
    users: Array,
    customerName: String,
    customerId: Number,
  },
  data() {
    return {
      searchFieldContent: "",
      deleteUserDialog: false,
      newUserDialog: false,
      userToDelete: "",
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("authorization"), value: "role", sortable: true },
        { text: this.$t("username"), value: "username", sortable: true },
        { text: this.$t("firstname"), value: "firstname", sortable: true },
        { text: this.$t("lastname"), value: "lastname", sortable: true },
        { text: this.$t("delete"), value: "delete", sortable: false },
      ];
    },
  },
  methods: {
    async createUser(user) {
      let formData = new FormData();
      formData.set("addUser", true);
      formData.set("customer_id", this.customerId);
      formData.set("role_id", user.role);
      formData.set("username", user.username);
      formData.set("firstname", user.firstname);
      formData.set("lastname", user.lastname);
      formData.set("password", user.password);

      const response = await this.$root.postData(formData);

      if (response.error) {
        this.AppStateStore.createError("Customers", "userName");
      } else {
        this.newUserDialog = false;
        this.$emit("userCreated");
      }
    },
    async deleteUser() {
      let formData = new FormData();
      formData.set("deleteUser", true);
      formData.set("user_id", this.userToDelete);
      formData.set("customer_id", this.customerId);

      await this.$root.postData(formData);
      this.$emit("userDeleted");
    },
  },
};
</script>
