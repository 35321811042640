<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ "Logec Import" }}
      </v-card-text>
      <v-divider></v-divider>
      <v-select
        class="mx-5"
        v-model="selectImportType"
        :items="importTypes"
        :item-text="
          (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
        "
        return-object
        @change="selectionChanged"
        :label="$t('import_type')"
      ></v-select>
      <v-file-input
        class="mt-5 mx-5"
        accept=".csv"
        :label="$t('csv_click_to_select')"
        outlined
        v-model="selectedFile"
        @change="processImport"
        @click:clear="resetData"
      ></v-file-input>

      <PflegeGiessenTable
        v-if="
          (selectImportType.id === TaskType.PFLEGE ||
            selectImportType.id === TaskType.GIESSEN) &&
          fileData
        "
        :tasks="fileData"
        class="mx-5 mb-5"
      />

      <PflanzenTable
        v-if="selectImportType.id === TaskType.PFLANZEN && fileData"
        :items="pflanzenDataToDisplay"
        class="mx-5 mb-5"
      />

      <StandardImportTable
        v-if="selectImportType.id > TaskType.PFLANZEN && fileData"
        :graves="fileData"
        class="mx-5 mb-5"
      />

      <template v-if="selectImportType.id <= TaskType.PFLANZEN">
        <v-select
          class="mx-5"
          v-model="selectedTeams"
          :items="teams"
          item-text="name"
          :label="$t('team')"
          return-object
          :no-data-text="$t('no_data_text')"
          chips
          multiple
          required
        ></v-select>
        <v-text-field
          class="mx-5"
          type="number"
          v-model="duration"
          :rules="[rules.number]"
          :label="$t('duration')"
        ></v-text-field>
      </template>
      <v-btn
        v-if="fileData"
        class="mx-5 mt-5 right"
        color="info"
        @click="logecImport()"
      >
        {{ $t("import") }}
      </v-btn>

      <v-layout row justify-center>
        <ImportErrorDialog
          @close="clearErrors"
          :show="errorDialog"
          :invalidGraves="invalid"
        />
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { TaskType, ImportTypes } from "@utils/constants";

import PflegeGiessenTable from "@components/import/PflegeGiessenTable.vue";
import PflanzenTable from "@components/import/PflanzenTable.vue";
import StandardImportTable from "@components/import/StandardImportTable.vue";
import ImportErrorDialog from "@components/import/ImportErrorDialog.vue";

export default {
  components: {
    PflegeGiessenTable,
    PflanzenTable,
    StandardImportTable,
    ImportErrorDialog,
  },
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data() {
    return {
      TaskType: TaskType,
      duration: 5,
      errorDialog: false,
      selectedFile: [],
      fileData: [],
      pflanzenDataToDisplay: [],
      teams: [],
      selectedTeams: [],
      invalid: [],
      selectImportType: ImportTypes[0],
      rules: {
        number: (value) =>
          /^[1-9][0-9]*$/.test(value) || this.$t("error.number_rules"),
      },
    };
  },
  computed: {
    importTypes() {
      return ImportTypes;
    },
  },
  methods: {
    clearErrors() {
      this.errorDialog = false;
      this.invalid = [];
    },
    selectionChanged() {
      this.selectedFile.length = 0;
      this.selectedFile = [];
      this.fileData = [];
      this.pflanzenDataToDisplay = [];
    },

    async logecImport() {
      if (!this.isValid()) return;
      const teamIds = this.selectedTeams.map((team) => team.id);

      let formData = new FormData();
      formData.set("logecImport", true);
      formData.set("type", this.selectImportType.id);
      formData.set("data", JSON.stringify(this.fileData));
      formData.set("teams", JSON.stringify(teamIds));
      formData.set("duration", JSON.stringify(this.duration));

      try {
        const response = await this.$root.postData(formData);

        this.selectionChanged();

        if (response.error && response.error.length) {
          this.invalid = response.error;
          this.errorDialog = true;
        } else {
          this.invalid = [];
        }
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },

    processImport() {
      if (this.selectedFile) {
        if (!this.selectedFile) return;

        const reader = new FileReader();
        this.resetData();

        const needHeader = this.selectImportType.id < 4;
        reader.readAsText(this.selectedFile);

        reader.onload = () => {
          let parserData = this.$papa.parse(reader.result, {
            header: needHeader,
            encoding: "ANSI",
          }).data;

          switch (this.selectImportType.id) {
            case 1:
            case 2:
              this.processPflegeGiessen(parserData);
              break;
            case 3:
              this.processPflanzen(parserData);
              break;
            case 4:
            case 5:
            case 6:
            case 7:
              this.processGraves(parserData);
              break;
            default:
              break;
          }
        };
      }
    },

    extractOrdParts(ord, split, remove) {
      return ord.split(split).map((part) => {
        const trimmedPart = part.trim();
        return trimmedPart === remove || trimmedPart === "" ? "" : trimmedPart;
      });
    },

    processPflegeGiessen(parserData) {
      parserData.forEach((task) => {
        if (task.Ord) {
          const ordParts = this.extractOrdParts(task.Ord, "-", "_");

          this.fileData.push({
            graveyard: task.Friedhof,
            grave_logec_id: task.G_NR,
            ord1: ordParts[0],
            ord2: ordParts[1],
            ord3: ordParts[2],
            ord4: ordParts[3],
            description: task.Bem,
            logec_type_id: task.sArtikelNr,
            logec_type_name: task.Artikel,
          });
        }
      });
    },

    processPflanzen(parserData) {
      parserData.forEach((task) => {
        if (task.Ord) {
          const ordParts = this.extractOrdParts(task.Ord, "-", "_");
          const graveLogecId = task.G_NR.trim();

          let taskEntry = this.fileData.find(
            (taskEntry) => taskEntry.grave_logec_id === graveLogecId
          );

          if (!taskEntry) {
            taskEntry = {
              graveyard: task.Friedhof,
              grave_logec_id: graveLogecId,
              ord1: ordParts[0],
              ord2: ordParts[1],
              ord3: ordParts[2],
              ord4: ordParts[3],
              giessen: [],
              pflanzen: [],
            };
            this.fileData.push(taskEntry);
          }

          taskEntry.pflanzen.push({
            name: task.Artikel,
            quantity: task.fMenge,
            logec_id: task.sArtikelNr,
            description: task.Bem,
          });

          this.pflanzenDataToDisplay.push({
            Friedhof: task.Friedhof,
            Grab: task.Grab,
            ord1: ordParts[0],
            ord2: ordParts[1],
            ord3: ordParts[2],
            ord4: ordParts[3],
            grave_logec_id: graveLogecId,
            sArtikelNr: task.sArtikelNr,
            Artikel: task.Artikel,
            fMenge: task.fMenge,
            Bem: task.Bem,
          });
        }
      });
    },

    processGraves(parserData) {
      parserData.forEach((grave) => {
        if (grave[0] === "Datum:") {
          const ordParts = this.extractOrdParts(grave[10], "/", ".");

          this.fileData.push({
            customer_id: this.UserStore.customer.id,
            person: grave[9],
            graveyard: grave[3],
            ord1: ordParts[0],
            ord2: ordParts[1],
            ord3: ordParts[2],
            ord4: ordParts[3],
            grave_logec_id: grave[6].trim(),
          });
        }
      });
    },

    async getLists() {
      const lists = await this.$root.getLists();
      this.teams = lists["teams"];
    },

    resetData() {
      this.fileData = [];
      this.pflanzenDataToDisplay = [];
      this.invalid = [];
    },
    isValid() {
      let pattern = /^[1-9][0-9]*$/;
      return this.duration.toString().match(pattern);
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getLists);
    this.getLists();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getLists);
  },
};
</script>
