<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("menu.groups.teams.header") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-layout align-start justify-start row wrap>
        <template v-for="team in teams">
          <Team
            :key="'team ' + team.id"
            @deleteTeamClicked="openDeleteConfirmationDialog"
            @teamMemberAdded="getTeams"
            @teamMemberDeleted="getTeams"
            :team="team"
            class="my-6"
          />
        </template>
      </v-layout>
      <v-layout row justify-center>
        <ConfirmDialog
          :text="$t('delete_question_team')"
          :show="dialogs.deleteTeamConfirmation"
          @confirm="deleteTeam()"
          @cancel="
            dialogs.deleteTeamConfirmation = false;
            teamToDeleteId = '';
          "
        />
      </v-layout>

      <v-layout row justify-center>
        <NewTeamDialog
          v-if="dialogs.addTeam"
          @teamAdded="getTeams"
          @cancel="dialogs.addTeam = false"
        />
      </v-layout>

      <v-card-text bottom right>
        <v-fab-transition>
          <v-btn
            color="blue"
            dark
            app
            absolute
            bottom
            right
            fab
            style="bottom: 0"
            @click="dialogs.addTeam = true"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-text>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import Team from "@components/teams/Team.vue";
import NewTeamDialog from "@components/teams/NewTeamDialog.vue";
import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";
export default {
  name: "Teams",
  components: {
    Team,
    NewTeamDialog,
    ConfirmDialog,
  },
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data: () => ({
    dialogs: {
      deleteTeamConfirmation: false,
      addTeam: false,
    },
    teamToDeleteId: "",
    teams: [],
  }),
  methods: {
    openDeleteConfirmationDialog(teamId) {
      this.teamToDeleteId = teamId;
      this.dialogs.deleteTeamConfirmation = true;
    },
    async deleteTeam() {
      let formData = new FormData();
      formData.set("deleteTeam", true);
      formData.set("team_id", this.teamToDeleteId);

      await this.$root.postData(formData);

      this.getTeams();
    },

    async getTeams() {
      this.dialogs.deleteTeamConfirmation = false;
      this.dialogs.addTeam = false;
      this.dialogs.addTeamMember = false;

      let formData = new FormData();
      formData.set("getTeams", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.teams = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getTeams);
    this.getTeams();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getTeams);
  },
};
</script>
