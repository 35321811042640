import { defineStore } from "pinia";
import axios from "axios";
import PathFinder from "geojson-path-finder";
import { useNavStateStore } from "@store/navState";
import { NavigationType, PRECISION } from "@utils/constants";

export const useRouteStore = defineStore({
  id: "routeStore",
  state: () => ({
    routes: {
      foot: {
        _400: "",
        _600: "",
      },
      car: {
        _1150: "",
        _1550: "",
        _2300: "",
      },
    },

    pathFinder: {
      foot: {
        _400: "",
        _600: "",
      },
      car: {
        _1150: "",
        _1550: "",
        _2300: "",
      },
    },
  }),
  getters: {
    getCarRoute() {
      const NavStateStore = useNavStateStore();
      switch (NavStateStore.getNavigationType) {
        case NavigationType.WITHCARMK1703:
          return this.routes.car._1150;
        case NavigationType.WITHCARM26:
          return this.routes.car._1550;
        default:
          return this.routes.car._2300;
      }
    },
    getCarPathFinder() {
      const NavStateStore = useNavStateStore();
      switch (NavStateStore.getNavigationType) {
        case NavigationType.WITHCARMK1703:
          return this.pathFinder.car._1150;
        case NavigationType.WITHCARM26:
          return this.pathFinder.car._1550;
        default:
          return this.pathFinder.car._2300;
      }
    },
    getFootRoute() {
      const NavStateStore = useNavStateStore();
      return NavStateStore.getNavigationType === NavigationType.ONFOOTWITHLOAD
        ? this.routes.foot._600
        : this.routes.foot._400;
    },

    getFootPathFinder() {
      const NavStateStore = useNavStateStore();
      return NavStateStore.getNavigationType === NavigationType.ONFOOTWITHLOAD
        ? this.pathFinder.foot._600
        : this.pathFinder.foot._400;
    },
  },
  actions: {
    clearData() {
      this.routes = {
        foot: {
          _400: "",
          _600: "",
        },
        car: {
          _1150: "",
          _1550: "",
          _2300: "",
        },
      };
      this.pathFinder = {
        foot: {
          _400: "",
          _600: "",
        },
        car: {
          _1150: "",
          _1550: "",
          _2300: "",
        },
      };
    },
    async requestRoutes(graveyardName) {
      let formData = new FormData();
      formData.append("name", graveyardName);

      const response = await axios({
        method: "post",
        url: "routes.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      this.jsonData = response.data;

      this.routes = Object.freeze({
        foot: {
          _400: response.data["foot_400.json"],
          _600: response.data["foot_600.json"],
        },
        car: {
          _1150: response.data["car_1150.json"],
          _1550: response.data["car_1550.json"],
          _2300: response.data["car_2300.json"],
        },
      });

      this.setPathFinders();
    },
    setPathFinders() {
      this.pathFinder = Object.freeze({
        foot: {
          _400: new PathFinder(this.routes.foot._400, {
            tolerance: PRECISION,
          }),
          _600: new PathFinder(this.routes.foot._600, {
            tolerance: PRECISION,
          }),
        },
        car: {
          _1150: new PathFinder(this.routes.car._1150, {
            tolerance: PRECISION,
          }),
          _1550: new PathFinder(this.routes.car._1550, {
            tolerance: PRECISION,
          }),
          _2300: new PathFinder(this.routes.car._2300, {
            tolerance: PRECISION,
          }),
        },
      });
    },
  },
});
