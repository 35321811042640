<template>
  <v-container fluid class="mt-6 pa-0" id="gravePage">
    <v-layout row justify-center>
      <v-dialog v-model="taskAlreadyDoneDialog" persistent max-width="400">
        <v-card text>
          <v-card-title
            style="word-break: break-word"
            class="justify-center text-center text-wrap"
          >
            {{ $t("dialog_task_done") }}
          </v-card-title>
          <v-card-actions class="pa-0 ma-0">
            <v-btn
              color="primary"
              block
              tile
              @click="
                taskAlreadyDoneDialog = false;
                $emit('navigationTaskCompleted', grave.id);
              "
            >
              {{ $t("nav_next") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-flex xs12>
      <GraveImage
        v-if="grave"
        :key="Date.now()"
        @imageUploaded="getGrave"
        :graveId="grave.id"
        :canUpload="true"
      />

      <span class="subheading ml-5">{{ $t("grave_info") }}</span>
      <GraveTextData
        v-if="grave"
        @graveTextDataEdited="getGrave"
        :grave="grave"
        :editable="true"
      />

      <span class="subheading ml-5">{{ $t("menu.groups.tasks.header") }}</span>
      <GraveTasks
        class="mb-14"
        v-if="grave"
        @taskSelected="changeSelectedTask"
        @taskUpdated="getGraveTasks"
        :graveTasks="graveTasks"
        :selectedTask="selectedTask"
      />

      <template v-if="UserStore.hasFullAccess">
        <v-btn
          block
          color="grey lighten-2"
          light
          class="mb-14"
          @click="
            addNewTaskToggled = !addNewTaskToggled;
            scrollToBottom();
          "
        >
          {{ $t("create_task") }}
        </v-btn>
        <v-card flat v-if="addNewTaskToggled" class="mb-14">
          <TaskForm :task="task" :graveSelection="false" />
          <v-card-actions>
            <v-btn
              bottom
              class="white--text"
              block
              color="blue"
              :disabled="valid()"
              @click="addTask()"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-flex>
    <v-footer padless class="timer-buttons">
      <v-btn block color="grey lighten-2" light>
        {{ getSelectedTaskType() }}
      </v-btn>
      <v-btn block color="green" dark @click="startTimer()" height="54px">
        {{ start }}
      </v-btn>
    </v-footer>
  </v-container>
</template>
<style>
.timer-buttons {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 56px;
}
</style>

<script>
import GraveImage from "@components/grave/GraveImage.vue";
import GraveTextData from "@components/grave/GraveTextData.vue";
import GraveTasks from "@components/grave/GraveTasks.vue";
import TaskForm from "@components/forms/TaskForm.vue";

import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";

import { DashboardTab, TaskType } from "@utils/constants";
import { getFormattedDate, isEmpty } from "@utils/utilFunctions";

export default {
  name: "Grave",
  props: {
    id: Number,
    taskId: Number,
  },
  components: {
    GraveImage,
    GraveTextData,
    GraveTasks,
    TaskForm,
  },
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data() {
    return {
      DashboardTab: DashboardTab,
      TaskType: TaskType,

      task: {
        date: new Date().toISOString().substr(0, 10),
        task_type: "",
        items: [],
        duration: 1,
        selectedTeams: [],
        description: "",
      },
      grave: "",
      graveTasks: [],
      selectedTask: {},

      taskStartTime: "",
      taskTimer: {
        timer: null,
        totalTime: null,
        done: false,
        started: false,
      },

      addNewTaskToggled: false,
      taskAlreadyDone: false,
      taskAlreadyDoneDialog: false,
    };
  },
  computed: {
    isTaskIdSet() {
      return this.taskId !== null;
    },

    computedDateFormatted() {
      return getFormattedDate(this.task.date);
    },
    start: function () {
      if (this.isEmpty(this.selectedTask)) return this.$t("task_select");
      else if (this.selectedTask && !this.taskTimer.started)
        return this.$t("start");
      else if (this.taskTimer.started && !this.taskTimer.done)
        return this.minutes + ":" + this.seconds;
      else if (this.taskTimer.done) return this.$t("confirm");
      return " ";
    },
    minutes: function () {
      const minutes = Math.floor(this.taskTimer.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function () {
      const seconds = this.taskTimer.totalTime - this.minutes * 60;
      return this.padTime(seconds);
    },
  },
  methods: {
    getFormattedDate,
    isEmpty,
    scrollToBottom() {
      setTimeout(() => {
        this.$nextTick(() => {
          window.scrollTo({
            top: document.body.scrollHeight + 54,
            behavior: "smooth",
          });
        });
      }, 100);
    },
    valid() {
      const isNonEmptyString = (value) => value !== "";
      const areCommonFieldsFilled =
        isNonEmptyString(this.computedDateFormatted) &&
        isNonEmptyString(this.task.task_type) &&
        isNonEmptyString(this.task.duration);

      const isDescriptionRequired =
        this.task.task_type &&
        (this.task.task_type.id === TaskType.PFLANZEN ||
          this.task.task_type.id === TaskType.SONDERAUFGABE);

      const additionalConditions = isDescriptionRequired
        ? isNonEmptyString(this.task.description)
        : true;

      return !(areCommonFieldsFilled && additionalConditions);
    },
    clearNewTask() {
      this.task.date = new Date().toISOString().substr(0, 10);
      this.task.duration = 1;
      this.task.selectedTeams = [];
      this.task.task_type = "";
      this.task.interval = "";
      this.task.description = "";
      this.task.items = [];
    },
    async addTask() {
      const add = this.task.selectedTeams.map((team) => team.id);

      let formData = new FormData();
      formData.set("addTask", true);
      formData.set("first_on", this.task.date);
      formData.set("interval_id", 1);
      formData.set("task_type_id", this.task.task_type.id);
      formData.set("items", JSON.stringify(this.task.items));
      formData.set("teams", JSON.stringify(add));
      formData.set("graveyard_id", this.grave.graveyard_id);
      formData.set("grave_id", this.grave.id);
      formData.set("description", this.task.description);
      formData.set("duration_default", this.task.duration);
      formData.set("approved", 0);

      await this.$root.postData(formData);
      this.getGraveTasks();
      this.addNewTaskToggled = false;
      this.clearNewTask();
    },
    async completeSingleTask(task) {
      let formData = new FormData();

      formData.set("completeSingleTask", true);
      formData.set("customer", this.UserStore.customer.name);
      formData.set("task", JSON.stringify(task));

      await this.$root.postData(formData);
    },
    async postTaskTimestamp(
      id,
      start,
      graveyard,
      grave,
      task_type,
      user,
      description
    ) {
      let formData = new FormData();

      formData.set("postTaskTimestamp", true);
      formData.set("id", id);
      formData.set("start", start);
      formData.set("graveyard", graveyard);
      formData.set("grave", grave);
      formData.set("task_type", task_type);
      formData.set("user", user);
      formData.set("description", description);

      await this.$root.postData(formData);
      await this.getGraveTasks();
    },
    async timestamp(task) {
      if (typeof task.grave_number !== "undefined") {
        await this.postTaskTimestamp(
          task.id,
          this.$moment(this.taskStartTime).format("YYYY-MM-DD HH:mm:ss"),
          task.graveyard_name,
          task.grave_number,
          task.task_type_id,
          this.UserStore.user.id,
          task.description
        );

        if (
          task.task_type_id === TaskType.GIESSEN ||
          task.task_type_id === TaskType.SONDERAUFGABE
        ) {
          task.start = this.$moment(this.taskStartTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          task.user = this.UserStore.user.fullname;
          await this.completeSingleTask(task);
        }

        this.taskTimer = {
          timer: null,
          totalTime: null,
          done: false,
          started: false,
        };
        this.selectedTask = {};
        this.taskStartTime = "";
        if (this.AppStateStore.getPreviousTab === DashboardTab.NAVIGATION)
          this.$emit("navigationTaskCompleted", this.grave.id);
      } else {
        this.$root.errorText = this.$t("incomplete_task_data");
        this.$root.snackbar = true;
      }
    },

    changeSelectedTask(task) {
      if (!this.taskTimer.started) {
        this.selectedTask = task;
      }
    },
    startTimer() {
      if (this.taskTimer.done) {
        this.finishTask();
        return;
      }

      if (!this.taskTimer.started) {
        this.AppStateStore.toggleBackToDisabled();
        this.taskTimer.totalTime =
          this.selectedTask.task_type_id === TaskType.PFLEGE
            ? 0
            : this.selectedTask.duration * 60;

        //TODO: the next line is only for testing --> delete it
        // this.taskTimer.totalTime = 1;
        this.taskTimer.done = this.taskTimer.totalTime === 0;
        this.taskTimer.started = true;
        this.taskStartTime = new Date();
        this.taskTimer.timer = setInterval(this.countdown, 1000);
      }
    },
    finishTask() {
      this.AppStateStore.toggleBackToDisabled();
      this.timestamp(this.selectedTask);
      this.resetTimer();
    },

    padTime: function (time) {
      return (time < 10 ? "0" : "") + time;
    },
    resetTimer: function () {
      clearInterval(this.taskTimer.timer);
    },
    countdown: function () {
      if (this.taskTimer.totalTime >= 1) {
        this.taskTimer.totalTime--;
      } else {
        this.taskTimer.totalTime = 0;
        this.taskTimer.done = true;
      }
    },
    getSelectedTaskType() {
      if (!this.isEmpty(this.selectedTask)) {
        const name = this.$root.isLanguageGerman
          ? this.selectedTask.task_type_de
          : this.selectedTask.task_type_en;
        if (!this.selectedTask.duration) return this.$t("task") + ": " + name;
        return (
          this.$t("task") +
          ": " +
          name +
          " " +
          this.selectedTask.duration +
          " min"
        );
      }
      return "";
    },
    async isTaskAlreadyDone() {
      let formData = new FormData();
      formData.set("isTaskAlreadyDone", true);
      formData.set("id", this.taskId);

      try {
        const response = await this.$root.postData(formData);
        this.taskAlreadyDone = !!+response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async getGrave() {
      let formData = new FormData();
      formData.set("getGrave", true);
      formData.set("id", this.id);

      try {
        const response = await this.$root.postData(formData);
        this.grave = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async getGraveTasks() {
      let formData = new FormData();
      formData.set("getGraveTasks", true);
      formData.set("id", this.id);

      try {
        const response = await this.$root.postData(formData);
        this.graveTasks = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  async mounted() {
    await this.getGrave();
    await this.getGraveTasks();
    if (this.AppStateStore.getPreviousTab === DashboardTab.NAVIGATION) {
      await this.isTaskAlreadyDone();

      if (this.isTaskIdSet && !this.taskAlreadyDone) {
        this.graveTasks.forEach((current_grave) => {
          if (current_grave.id === this.taskId)
            this.selectedTask = current_grave;
        });
        this.startTimer();
      } else {
        this.taskAlreadyDoneDialog = true;
      }
    }
  },
};
</script>
