<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("menu.groups.tasks.schedule") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-btn
        class="mt-5"
        style="width: 100%"
        color="info"
        @click="openDialog()"
      >
        {{ getButtonText() }}
      </v-btn>
      <v-btn
        class="mt-2"
        style="width: 100%"
        color="error"
        @click="deleteTasks()"
      >
        {{ $t("delete") }}
      </v-btn>
      <v-card class="elevation-5">
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            :label="$t('search')"
            single-line
            hide-details
            left
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tasks"
          :search="search"
          item-key="id"
          return-object
          show-select
          v-model="selected"
          :item-class="itemRowBackground"
          :expanded.sync="expanded"
          show-expand
          dense
          :items-per-page="200"
          :footer-props="{
            itemsPerPageText: '',
            itemsPerPageAllText: $t('all'),
            'items-per-page-options': [10, 100, 200, 500, -1],
          }"
        >
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }} /
            {{ items.itemsLength }}
          </template>
          <template v-slot:body.prepend>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <v-text-field
                  v-model="graveyard_name"
                  type="text"
                  :label="$t('graveyard')"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="grave_number"
                  type="text"
                  :label="$t('grave_number_')"
                ></v-text-field>
              </td>
              <td>
                <template v-if="$root.isLanguageGerman">
                  <v-text-field
                    v-model="task_type_de"
                    type="text"
                    :label="$t('task_type')"
                  ></v-text-field>
                </template>
                <template v-else>
                  <v-text-field
                    v-model="task_type_en"
                    type="text"
                    :label="$t('task_type')"
                  ></v-text-field>
                </template>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </template>
          <template #item.next="{ item }">
            {{ getFormattedDate(item.next) }}
          </template>
          <template #item.graveyard="{ item }">
            {{ item.graveyard_name }}
          </template>
          <template #item.grave_number="{ item }">
            {{ item.grave_number }}
          </template>
          <template v-if="$root.isLanguageGerman" #item.task_type="{ item }">
            {{ item.task_type_de }}
          </template>
          <template v-else #item.task_type="{ item }">
            {{ item.task_type_en }}
          </template>
          <template #item.description="{ item }">
            {{ item.description }}
          </template>
          <template #item.teams="{ item }">
            {{ makeStringFromArray(item.teams) }}
          </template>
          <template #item.duration="{ item }">{{ item.duration }}</template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              v-if="item.task_type_id === TaskType.PFLANZEN"
            >
              <div class="my-5">
                <template v-for="(item, index) in item.items">
                  <div :key="item.id + item.name + item.quantity + index">
                    {{ item.name }}
                    :
                    {{ item.quantity }}
                    {{ item.description ? ", " + item.description : "" }}
                  </div>
                </template>
              </div>
            </td>
          </template>
          <template slot="no-results">
            {{ $t("no_results") }}
          </template>
          <template v-slot:no-data>
            {{ $t("no_data_text") }}
          </template>
        </v-data-table>
      </v-card>

      <v-btn
        class="mt-5"
        style="width: 100%"
        color="info"
        @click="selectAndOpenDialog()"
      >
        {{ getButtonText() }}
      </v-btn>
      <v-btn
        class="mt-2"
        style="width: 100%"
        color="error"
        @click="deleteTasks()"
      >
        {{ $t("delete") }}
      </v-btn>

      <v-layout row justify-center>
        <TaskFormDialog
          v-if="taskDialog"
          @newTask="getTasks"
          :getDialog="getDialog"
          :closeDialog="closeDialog"
          :mode="getDialogMode"
          :taskDataForEdit="taskDataForEdit"
        ></TaskFormDialog>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import TaskFormDialog from "@components/dialogs/TaskFormDialog.vue";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";

import { TaskType } from "@utils/constants";
import { getFormattedDate, makeStringFromArray } from "@utils/utilFunctions";

export default {
  components: { TaskFormDialog },
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data() {
    return {
      TaskType: TaskType,
      expanded: [],
      taskDialog: false,
      taskDataForEdit: {},
      search: "",
      selected: [],
      tasks: [],
      graveyard_name: "",
      grave_number: "",
      task_type_de: "",
      task_type_en: "",
    };
  },
  methods: {
    getFormattedDate,
    makeStringFromArray,
    openDialog(dialogName) {
      switch (this.selected.length) {
        case 0:
          this.mode = "create";
          this.taskDataForEdit = {};
          break;
        case 1:
          this.mode = "editSingle";
          this.taskDataForEdit = {
            id: this.selected[0].id,
            date: this.selected[0].next,
            task_type: {
              id: this.selected[0].task_type_id,
              name_de: this.selected[0].task_type_de,
              name_en: this.selected[0].task_type_en,
            },
            items: this.selected[0].items,
            duration: this.selected[0].duration,
            selectedTeams: JSON.parse(this.selected[0].teams),
            grave_type: "",
            grave_selected: {
              id: this.selected[0].grave_id,
              graveyard_id: this.selected[0].graveyard_id,
              number: this.selected[0].grave_number,
            },
            description: this.selected[0].description,
          };

          break;
        default:
          this.mode = "editMultiple";
          this.taskDataForEdit = this.selected.map((task) => task.id);
          break;
      }

      this.taskDialog = true;
    },
    closeDialog(dialogName) {
      this.taskDialog = false;
      this.mode = "create";
      this.taskDataForEdit = {};
      this.selected = [];
      this.getTasks();
    },
    getDialog(dialogName) {
      return this.taskDialog;
    },
    itemRowBackground(item) {
      return this.isOverdue(item.next)
        ? "red--text text--darken-2"
        : "green--text";
    },
    getButtonText() {
      if (this.selected.length == 0) return this.$t("create_new_task");
      else if (this.selected.length === 1) return this.$t("edit_task");
      else if (this.selected.length > 1) return this.$t("edit_selected_tasks");
    },
    selectAndOpenDialog() {
      this.openDialog("TaskFormDialog");
    },
    isOverdue(date) {
      if (date) {
        let d = new Date(date.valueOf());
        d.setDate(d.getDate() + 1);

        return d < new Date();
      }
      return false;
    },
    async deleteTasks() {
      if (this.selected.length) {
        let tasks = this.selected.map((task) => task.id);

        let formData = new FormData();
        formData.set("deleteTasks", true);
        formData.set("taskIds", JSON.stringify(tasks));

        await this.$root.postData(formData);
        this.getTasks();

        this.selected = [];
      }
    },
    async getTasks() {
      let formData = new FormData();
      formData.set("getTasks", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.tasks = response;
        if (this.tasks.length)
          this.graveyard_name = this.tasks[0].graveyard_name;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  computed: {
    getDialogMode() {
      switch (this.selected.length) {
        case 0:
          return "create";
        case 1:
          return "editSingle";
        default:
          return "editMultiple";
      }
    },
    getTaskTypeValue() {
      if (this.$root.isLanguageGerman) return "task_type_de";
      return "task_type_en";
    },
    headers() {
      return [
        {
          text: this.$t("next"),
          sortable: true,
          value: "next",
        },
        {
          text: this.$t("graveyard"),
          value: "graveyard_name",
          sortable: true,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["graveyard_name"].toLowerCase());
          },
        },
        {
          text: this.$t("grave"),
          value: "grave_number",
          sortable: true,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["grave_number"].toLowerCase());
          },
        },
        {
          text: this.$t("task_type"),
          value: this.getTaskTypeValue,
          sortable: true,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(
                this["task_type_de"].toLowerCase() ||
                  this["task_type_en"].toLowerCase()
              );
          },
        },
        { text: this.$t("description"), value: "description", sortable: true },
        {
          text: this.$t("menu.groups.teams.header"),
          value: "teams",
          sortable: true,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getTasks);
    this.getTasks();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getTasks);
  },
};
</script>
