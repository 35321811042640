<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("menu.groups.working_hours.working_hours") }}
      </v-card-text>
      <v-divider></v-divider>
      <UserWorkStatus @workStatusUpdated="refresh" />
      <template v-if="UserStore.hasFullAccess || UserStore.isTeamLeader">
        <v-card class="elevation-5 mx-5 mt-5">
          <v-card-title>
            <v-text-field
              v-model="search"
              prepend-icon="search"
              :label="$t('search')"
              single-line
              hide-details
              @input="searchFilter()"
              left
            ></v-text-field>
          </v-card-title>
          <WorkingHoursTable
            :workingHours="workingHours"
            :search="search"
            :show="true"
            :itemsPerPage="10"
            :itemsPerPageOptions="true"
          />
          <WorkingHoursTable
            :id="'pdf'"
            :workingHours="workingHoursFiltered"
            :search="search"
            :show="false"
            :itemsPerPage="-1"
            :hideFooter="true"
            :disablePagination="true"
            :itemsPerPageOptions="false"
          />
        </v-card>

        <v-btn
          @click="exportPdf"
          class="ml-5 mt-5"
          style="min-width: 100%"
          color="info"
        >
          {{ $t("export") }}
        </v-btn>
      </template>
    </v-container>
  </v-main>
</template>

<script src="es6-promise.auto.min.js"></script>
<script src="jspdf.min.js"></script>
<script src="html2canvas.min.js"></script>
<script src="html2pdf.min.js"></script>

<script>
import { EventBus } from "@events/EventBus";

import html2pdf from "html2pdf.js";
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";
import UserWorkStatus from "@components/workingHours/UserWorkStatus";
import WorkingHoursTable from "@components/workingHours/WorkingHoursTable";
export default {
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  components: {
    UserWorkStatus,
    WorkingHoursTable,
  },
  data() {
    return {
      search: "",
      workingHours: [],
      workingHoursFiltered: [],
    };
  },
  methods: {
    async refresh() {
      await this.getWorkingHours();
      if (!this.AppStateStore.getErrorDialog) this.searchFilter();
    },
    exportPdf() {
      const element = document.getElementById("pdf");
      let clonedElement = element.cloneNode(true);
      clonedElement.setAttribute("style", "");

      html2pdf(clonedElement, {
        margin: 1,
        filename: "Arbeitsstunden.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      });

      clonedElement.remove();
    },
    searchFilter() {
      this.workingHoursFiltered =
        this.search !== ""
          ? this.workingHours.filter((hour) =>
              Object.values(hour).some(
                (value) =>
                  !Array.isArray(value) &&
                  typeof value === "string" &&
                  value.includes(this.search)
              )
            )
          : [...this.workingHours];
    },
    async getWorkingHours() {
      const formData = new FormData();
      formData.set("getWorkingHours", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.workingHours = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },

  mounted() {
    EventBus.$on("refreshComponent", this.refresh);
    this.refresh();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.refresh);
  },
};
</script>
