<template>
  <v-dialog
    :value="getDialog($options._componentTag)"
    persistent
    max-width="400"
  >
    <template v-slot:activator="{ on_test }"></template>
    <v-card text>
      <v-container grid-list-xl fluid>
        <v-card-text class="text-xs-left font-weight-bold pl-0 pt-0">{{
          $t("menu_settings.settings")
        }}</v-card-text>
        <v-layout wrap>
          <v-flex xs12 class="py-0">
            <v-overflow-btn
              v-model="$i18n.locale"
              :items="langs"
              :hint="$t('language')"
              persistent-hint
            ></v-overflow-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="closeDialog($options._componentTag)"
          >{{ $t("close") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SettingsDialog",
  props: {
    getDialog: Function,
    closeDialog: Function,
  },
  data() {
    return {
      langs: ["en", "de"],
    };
  },
};
</script>
