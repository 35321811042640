<template>
  <v-card class="elevation-5 mx-5">
    <v-card-title>{{ graveyard.name }}</v-card-title>
    <v-data-table
      :headers="headers"
      :items="graveyard.fields"
      return-object
      dense
      :items-per-page="10"
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageAllText: $t('all'),
        'items-per-page-options': [10, 100, 200, 500, -1],
      }"
      @click:row="rowClicked"
    >
      <template #item.random="{ item }">
        <v-checkbox
          v-model="item.random"
          @click.native.stop=""
          @change="setFieldRandom(item)"
        ></v-checkbox>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>
      <template slot="no-results">
        {{ $t("no_results") }}
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_text") }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "GraveyardFieldsTable",
  props: {
    graveyard: Object,
  },
  data() {
    return {};
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("graveyard"),
          sortable: true,
          value: "graveyard_name",
        },
        { text: "ord1", value: "ord1", sortable: true },
        { text: "Neighbours", value: "neighbours", sortable: true },
        { text: "random", value: "random", sortable: true },
      ];
    },
  },
  methods: {
    rowClicked(field, slot, event) {
      this.$emit("fieldClicked", {
        ord1Array: this.graveyard.ord1Array,
        field: field,
      });
    },
    async setFieldRandom(field) {
      let formData = new FormData();
      formData.set("setFieldRandom", true);
      formData.set("id", field.graveyard_field_id);
      formData.set("random", +field.random);

      await this.$root.postData(formData);
      this.$emit("fieldRandomChanged");
    },
  },
};
</script>
