import { defineStore } from "pinia";
import { useAppStateStore } from "@store/appState";

import { DashboardTab } from "@utils/constants";

export const useAppBarStore = defineStore({
  id: "appBarStore",

  state: () => ({
    appBarTitle: "",
    appBarSubtitle: "",
    color: "blue",
  }),
  getters: {
    isAppBarVisible() {
      const appStateStore = useAppStateStore();
      return (
        !appStateStore.isAppStateLoginOrGuest ||
        appStateStore.getCurrentTab === DashboardTab.GRAVE
      );
    },
    getAppBarTitle() {
      return this.appBarTitle;
    },
    getAppBarSubtitle() {
      return this.appBarSubtitle;
    },
    getColor() {
      const appStateStore = useAppStateStore();
      return appStateStore.isAppStateBackTo ? "grey" : "blue";
    },
  },
  actions: {
    setAppBarTitle(text) {
      this.appBarTitle = text;
    },
    setAppBarSubtitle(text) {
      this.appBarSubtitle = text;
    },
  },
});
