<template>
  <v-main>
    <v-container fluid fill-height style="background-color: #2196f3">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 class="text-xs-center">
          <v-img
            :src="require('@assets/logo.png')"
            max-width="100"
            max-height="100"
            aspect-ratio="1"
            class="ma-auto"
          ></v-img>
          <h5 class="headline white--text text-center pa-3 mt-1"></h5>

          <v-card class="pa-2">
            <v-card-actions>
              <v-btn color="primary" block @click="guestLogin()">
                {{ $t("start") }}
              </v-btn>
            </v-card-actions>
            <v-divider class="mt-3"></v-divider>
            <div class="body-2 grey--text text-uppercase pt-2">
              &copy; 2019 Gärtnerei Herbert Wörner GmbH
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>
<script>
import { EventBus } from "@events/EventBus";
export default {
  data: () => ({}),
  methods: {
    async guestLogin() {
      this.$router.push({
        name: "guest",
        query: { redirect: "/guest" },
      });
    },
    restartApp() {
      window.location.reload(true);
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.restartApp);
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.restartApp);
  },
};
</script>
