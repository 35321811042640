<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ "Logec Export" }}
      </v-card-text>
      <v-divider></v-divider>
      <v-select
        class="mx-5"
        v-model="selectExportType"
        :items="exportTypes"
        :item-text="
          (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
        "
        return-object
        @change="selectionChanged"
        :label="$t('export_type')"
      ></v-select>
      <v-card class="elevation-5 mx-5">
        <v-card-title>
          <v-menu
            v-model="intervalStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mx-5"
                v-model="intervalStart"
                prepend-icon="event"
                single-line
                hide-details
                readonly
                v-on="on"
                :label="$t('from')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="intervalStart"
              no-title
              @input="intervalStartMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="intervalEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mx-5"
                v-model="intervalEnd"
                prepend-icon="event"
                single-line
                hide-details
                readonly
                v-on="on"
                :label="$t('until')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="intervalEnd"
              no-title
              @input="intervalEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-title>

        <ExportTable
          v-show="selectExportType.id === exportTypes[0].id"
          ref="table_pflegegiessen"
          :tasks="logecTasks.pflegegiessen"
          :quantityHeader="false"
          :intervalStart="intervalStart"
          :intervalEnd="intervalEnd"
        />

        <ExportTable
          v-show="selectExportType.id === exportTypes[1].id"
          ref="table_pflanzen"
          :tasks="logecTasks.pflanzen"
          :quantityHeader="false"
          :intervalStart="intervalStart"
          :intervalEnd="intervalEnd"
        />
      </v-card>

      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="basePath"
                :label="$t('base_path')"
                single-line
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-btn
                @click="setPathForTasks()"
                color="info"
                style="height: 54px; min-width: 100%"
              >
                {{ $t("set_path") }}
              </v-btn>
            </v-col>

            <v-col cols="12" sm="4">
              <v-btn
                @click="getImages()"
                color="success"
                style="height: 54px; min-width: 100%"
                :disabled="!pathSet"
              >
                {{ $t("download_images") }}
              </v-btn>
            </v-col>
          </v-row>
          <download-csv
            v-if="downloadCsvData"
            :data="downloadCsvData"
            :name="csvName"
            :fields="fields"
            delimiter=";"
            style="width: 100%"
          >
            <v-btn style="height: 54px; min-width: 100%" color="info">
              {{ $t("export") }}
            </v-btn>
          </download-csv>
        </v-container>
      </v-form>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";

import ExportTable from "@components/export/ExportTable.vue";
import { ExportTypes } from "@utils/constants";

export default {
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  components: {
    ExportTable,
  },
  data() {
    return {
      fields: [
        "G_NR",
        "Grab",
        "Ord",
        "sArtikelNr",
        "Artikel",
        "fMenge",
        "Bem",
        "Friedhof",
        "Grab_Bem",
        "path",
      ],
      logecTasks: [],
      basePath: "D:\\R3-Bilder\\",
      gravesWithImage: [],
      pathSet: false,
      selectExportType: ExportTypes[0],
      intervalStartMenu: false,
      intervalEndMenu: false,
      intervalStart: "",
      intervalEnd: "",
    };
  },
  computed: {
    exportTypes() {
      return ExportTypes;
    },
    downloadCsvData() {
      switch (this.selectExportType.id) {
        case this.exportTypes[0].id:
          if (this.$refs.table_pflegegiessen)
            return this.$refs.table_pflegegiessen.$children[0].$data
              .internalCurrentItems;
          return this.logecTasks.pflegegiessen;

        case this.exportTypes[1].id:
          if (this.$refs.table_pflanzen)
            return this.$refs.table_pflanzen.$children[0].$data
              .internalCurrentItems;
          return this.logecTasks.pflanzen;
        default:
          return [];
      }
    },
    csvName() {
      const formattedDate = this.$moment().format("YYYY-M-D");

      let prefix;
      switch (this.selectExportType.id) {
        case 1:
          prefix = "WÖ-pf-Gie-";
          break;
        case 2:
          prefix = "WÖ-Pflanzen-";
          break;
        default:
          prefix = "WÖ-?-";
          break;
      }

      return `${prefix}${formattedDate}.csv`;
    },
  },
  methods: {
    selectionChanged() {
      (this.basePath = "D:\\R3-Bilder\\"), (this.pathSet = false);
    },

    setPathForTasks() {
      const tasks =
        this.selectExportType.id === this.exportTypes[0].id
          ? this.$refs.table_pflegegiessen?.$children[0].$data
              .internalCurrentItems
          : this.selectExportType.id === this.exportTypes[1].id
          ? this.$refs.table_pflanzen?.$children[0].$data.internalCurrentItems
          : [];

      tasks.forEach((task) => (task.path = this.getPath(task)));
      this.pathSet = true;
    },

    getPath(task) {
      if (
        !this.gravesWithImage.includes(task.G_NR) ||
        !task.Friedhof ||
        !task.Grab ||
        !task.G_NR
      ) {
        return "";
      }

      const formatStr = (str) =>
        str
          .replaceAll(" ", "_")
          .replaceAll('"', "_")
          .replaceAll("/", "_")
          .replaceAll(".", "_");
      const formattedGraveyard = formatStr(task.Friedhof);
      const formattedGrave = formatStr(task.Grab);
      const formattedDate = this.$moment(new Date()).format("YYYY_MM_DD");

      return `${this.basePath}${formattedGraveyard}\\${formattedGrave}_${task.G_NR}\\${formattedGrave}_${formattedDate}.jpg`;
    },

    async getImages() {
      let ids = [];

      if (this.selectExportType.id === this.exportTypes[0].id) {
        this.$refs.table_pflegegiessen.$children[0].$data.internalCurrentItems.forEach(
          (task) => {
            ids.push(task.G_NR);
          }
        );
      } else if (this.selectExportType.id === this.exportTypes[1].id) {
        this.$refs.table_pflanzen.$children[0].$data.internalCurrentItems.forEach(
          (task) => {
            ids.push(task.G_NR);
          }
        );
      }

      if (ids.length > 0) {
        let formData = new FormData();
        formData.set("getImages", true);
        formData.set("ids", JSON.stringify(ids));

        const response = await this.$root.postDataGetBlob(formData);

        if (response.size < 500) {
          this.AppStateStore.createError("Export", "noImages");
        } else {
          const blob = new Blob([response], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Bilder.zip";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    async getLogecTasks() {
      let formData = new FormData();
      formData.set("getLogecTasks", true);
      formData.set("customer", this.UserStore.customer.name);
      try {
        const response = await this.$root.postData(formData);
        this.logecTasks = response.tasks;
        this.gravesWithImage = response.images;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getLogecTasks);
    this.getLogecTasks();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getLogecTasks);
  },
};
</script>
