<template>
  <v-bottom-sheet
    eager
    persistent
    :value="true"
    no-click-animation
    hide-overlay
  >
    <v-sheet class="text-center" :style="{ marginBottom: marginBottom }">
      <template v-show="displayBottomMenu">
        <v-flex xs12>
          <v-card flat tile color="blue" class="py-1" max-height="200px">
            <v-card-actions style="padding: 0">
              <v-col cols="5" class="pa-0 text-left">
                <v-btn
                  text
                  small
                  @click="NavStateStore.setCarStopOverride(true)"
                  v-if="showOverrideButton"
                >
                  <v-icon small>skip_next</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" class="pa-0">
                <template v-if="toggled">
                  <v-btn text x-small @click="$emit('toggle')" height="54px">
                    <v-icon light>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    text
                    x-small
                    @click="$emit('toggle')"
                    height="54px"
                    :disabled="isToggleDisabled"
                  >
                    <v-icon dark>mdi-chevron-up</v-icon>
                  </v-btn>
                </template>
              </v-col>

              <v-col cols="5" class="pa-0 text-right">
                <template v-if="showRecalculateButtonsCar">
                  <v-btn
                    text
                    small
                    @click="$emit('recalculateTaskGroup', false)"
                  >
                    <v-icon small>mdi-arrow-right-bold</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    small
                    @click="$emit('recalculateTaskGroup', true)"
                  >
                    <v-icon small>refresh</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  text
                  small
                  @click="$emit('recalculateTask')"
                  v-if="showRecalculateButtonOnFoot"
                >
                  <v-icon small>refresh</v-icon>
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-flex>
        <GraveContent
          :key="Date.now()"
          :grave="grave"
          :buttonEnabled="openGraveButton"
          @graveReached="$emit('handleGraveReached')"
          v-if="toggled"
        />
      </template>
    </v-sheet>
  </v-bottom-sheet>
</template>
<style scoped>
.v-dialog__content v-dialog__content--active {
  z-index: 1000 !important;
}
.v-dialog__content {
  z-index: 1000 !important;
}
#app > div.v-dialog__content.v-dialog__content--active {
  z-index: 1000 !important;
}
</style>
<script>
import GraveContent from "@components/navigation/GraveContent.vue";
import { useNavStateStore } from "@store/navState";
export default {
  name: "BottomMenu",
  setup() {
    const NavStateStore = useNavStateStore();
    return {
      NavStateStore,
    };
  },
  components: {
    GraveContent,
  },
  props: {
    marginBottom: String,
    toggled: {
      type: Boolean,
      default: false,
    },
    grave: Object,
    displayBottomMenu: {
      type: Boolean,
      default: false,
    },
    showRecalculateButtonsCar: {
      type: Boolean,
      default: false,
    },
    showRecalculateButtonOnFoot: {
      type: Boolean,
      default: false,
    },
    showOverrideButton: {
      type: Boolean,
      default: false,
    },
    isToggleDisabled: {
      type: Boolean,
      default: false,
    },
    openGraveButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
