<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("backup") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-layout wrap>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="password"
            color="purple darken-2"
            :label="$t('login.password')"
            required
            :rules="[passwordRules.password]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-btn
            class="mt-5"
            style="height: 54px; min-width: 100%"
            color="info"
            @click="customerBackup()"
            :disabled="!valid"
          >
            {{ $t("backup") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";
export default {
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  data() {
    return {
      password: "",
      showPassword: false,
      valid: true,
      passwordRules: {
        password: (value) => {
          const lengthValid = value.length >= 8;
          const uppercaseMatches = (value.match(/[A-Z]/g) || []).length >= 2;
          const specialCharacterPattern =
            /[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\;\:\'\"\<\>\,\.\?\/\~\`]/;

          this.valid =
            lengthValid &&
            uppercaseMatches &&
            specialCharacterPattern.test(value);

          if (this.valid) return true;
          return this.$t("error.password_rules_complex");
        },
      },
    };
  },
  methods: {
    async customerBackup() {
      let formData = new FormData();
      formData.set("customerBackup", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set("customer_name", this.UserStore.customer.name);
      formData.set("password", this.password);

      try {
        const response = await this.$root.postData(formData);

        if (response.error) {
          this.AppStateStore.createError("Backup", "failed");
        } else {
          this.AppStateStore.createMessage("backupSuccessful");
        }
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
};
</script>
