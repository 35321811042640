import { defineStore } from "pinia";

export const useDrawerStore = defineStore({
  id: "drawerStore",

  state: () => ({
    toggled: false,
    draw: false,
  }),
  getters: {
    getDrawerToggled() {
      return this.toggled;
    },
  },
  actions: {
    toggleOffDrawer() {
      this.toggled = false;
    },
    toggleDrawer() {
      this.toggled = !this.toggled;
    },
  },
});
