<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("graves") }}
      </v-card-text>
      <v-divider></v-divider>

      <v-card class="elevation-5 mx-5">
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            :label="$t('search')"
            single-line
            hide-details
            left
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="graves"
          :search="search"
          :footer-props="{
            itemsPerPageText: '',
            itemsPerPageAllText: $t('all'),
          }"
        >
          <template #item.graveyard="{ item }">
            {{ item.graveyard }}
          </template>
          <template #item.number="{ item }">
            {{ item.number }}
          </template>

          <template #item.logec_id="{ item }">
            <v-edit-dialog
              :return-value.sync="item.logec_id"
              large
              persistent
              @save="setGraveLogecId(item)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
            >
              <div>{{ item.logec_id }}</div>
              <template v-slot:input>
                <div class="mt-4 title">{{ $t("update_logec_id") }}</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="item.logec_id"
                  :label="$t('edit')"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template #item.type="{ item }">
            <v-edit-dialog
              @save="setGraveType(item)"
              large
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
            >
              <div>
                {{
                  $root.isLanguageGerman
                    ? item.type1_name_de
                    : item.type1_name_en
                }}
              </div>
              <template v-slot:input>
                <v-select
                  v-model="item.type"
                  :items="graveTypes"
                  :item-text="
                    (item) =>
                      $root.isLanguageGerman ? item.name_de : item.name_en
                  "
                  return-object
                  :label="$t('type')"
                  required
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>

          <template #item.person="{ item }">
            <v-edit-dialog
              :return-value.sync="item.person"
              large
              persistent
              @save="setGravePerson(item)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
            >
              <div>{{ item.person }}</div>
              <template v-slot:input>
                <div class="mt-4 title">{{ $t("update_person") }}</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="item.person"
                  :label="$t('edit')"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template #item.description="{ item }">
            <v-edit-dialog
              :return-value.sync="item.description"
              large
              persistent
              @save="setGraveDescription(item)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
            >
              <div>{{ item.description }}</div>
              <template v-slot:input>
                <div class="mt-4 title">{{ $t("update_person") }}</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="item.description"
                  :label="$t('edit')"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template #item.comment="{ item }">
            <v-edit-dialog
              :return-value.sync="item.comment"
              large
              persistent
              @save="setGraveComment(item)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
            >
              <div>{{ item.comment }}</div>
              <template v-slot:input>
                <div class="mt-4 title">{{ $t("update_person") }}</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="item.comment"
                  :label="$t('edit')"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template #item.info="{ item }">
            <v-edit-dialog
              :return-value.sync="item.info"
              large
              persistent
              @save="setGraveInfo(item)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
            >
              <div>{{ item.info }}</div>
              <template v-slot:input>
                <div class="mt-4 title">{{ $t("update_person") }}</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="item.info"
                  :label="$t('edit')"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template #item.poi="{ item }">
            <v-checkbox
              v-model="item.poi"
              @change="setGravePoi(item)"
            ></v-checkbox>
          </template>

          <template #item.active="{ item }">
            <v-checkbox
              v-model="item.active"
              @change="setGraveActive(item)"
            ></v-checkbox>
          </template>

          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }} /
            {{ items.itemsLength }}
          </template>
          <template slot="no-results">
            {{ $t("no_results") }}
          </template>
          <template v-slot:no-data>
            {{ $t("no_data_text") }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
export default {
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data() {
    return {
      search: "",
      graveTypes: [],
      graves: [],
      selectedGrave: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("graveyard"),
          sortable: true,
          value: "graveyard",
        },

        {
          text: this.$t("number"),
          sortable: true,
          value: "number",
        },

        { text: "Logec ID", value: "logec_id", sortable: true },
        { text: this.$t("type"), value: "type", sortable: false },
        { text: this.$t("person"), value: "person", sortable: true },
        {
          text: this.$t("description"),
          sortable: true,
          value: "description",
        },
        {
          text: this.$t("comment"),
          sortable: true,
          value: "comment",
        },
        {
          text: this.$t("special"),
          sortable: true,
          value: "info",
        },
        { text: this.$t("poi"), value: "poi", sortable: true },
        { text: this.$t("active"), value: "active", sortable: true },
      ];
    },
  },
  methods: {
    async setGravePerson(grave) {
      let formData = new FormData();
      formData.set("setGravePerson", true);
      formData.set("id", grave.id);
      formData.set("person", grave.person);

      await this.$root.postData(formData);
    },
    async setGraveDescription(grave) {
      let formData = new FormData();
      formData.set("setGraveDescription", true);
      formData.set("id", grave.id);
      formData.set("description", grave.description);

      await this.$root.postData(formData);
    },
    async setGraveComment(grave) {
      let formData = new FormData();
      formData.set("setGraveComment", true);
      formData.set("id", grave.id);
      formData.set("comment", grave.comment);

      await this.$root.postData(formData);
    },
    async setGraveInfo(grave) {
      let formData = new FormData();
      formData.set("setGraveInfo", true);
      formData.set("id", grave.id);
      formData.set("info", grave.info);

      await this.$root.postData(formData);
    },

    async setGraveLogecId(grave) {
      let formData = new FormData();
      formData.set("setGraveLogecId", true);
      formData.set("id", grave.id);
      formData.set("logec_id", grave.logec_id);

      await this.$root.postData(formData);
    },
    async setGraveActive(grave) {
      let formData = new FormData();
      formData.set("setGraveActive", true);
      formData.set("id", grave.id);
      formData.set("active", +grave.active);

      await this.$root.postData(formData);
      this.refresh();
    },
    async setGravePoi(grave) {
      let formData = new FormData();
      formData.set("setGravePoi", true);
      formData.set("id", grave.id);
      formData.set("poi", +grave.poi);

      await this.$root.postData(formData);
      this.refresh();
    },
    async setGraveType(grave) {
      if (grave.type) {
        let formData = new FormData();
        formData.set("setGraveType", true);
        formData.set("id", grave.id);
        formData.set("type1_id", grave.type.id);

        await this.$root.postData(formData);
        this.refresh();
      }
    },
    async getGravesForManagement() {
      let formData = new FormData();
      formData.set("getGravesForManagement", true);
      formData.set("customer_id", this.UserStore.customer.id);
      try {
        const response = await this.$root.postData(formData);
        this.graves = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async getGraveTypes1() {
      let formData = new FormData();
      formData.set("getGraveTypes1", true);
      try {
        const response = await this.$root.postData(formData);
        this.graveTypes = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    refresh() {
      this.getGraveTypes1();
      this.getGravesForManagement();
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.refresh);
    this.refresh();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.refresh);
  },
};
</script>
