<template>
  <v-dialog :value="true" persistent max-width="400">
    <v-card text>
      <v-container grid-list-xl fluid>
        <v-layout wrap>
          <v-flex xs12 sm12>
            <v-text-field
              v-model="name"
              color="purple darken-2"
              :label="$t('team_name')"
              :rules="[rules.name]"
              required
            ></v-text-field>
            <v-select
              v-model="taskTypeId"
              :items="taskTypes"
              :item-text="
                (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
              "
              item-value="id"
              :label="$t('task_type')"
              required
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('cancel')">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!isNewTeamValid()"
          @click="addTeam()"
        >
          {{ $t("create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";
export default {
  name: "NewTeamDialog",
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  data() {
    return {
      name: "",
      taskTypeId: "",
      taskTypes: [],
      rules: {
        name: (value) => {
          const pattern = /^[a-zA-Z][a-zA-Z0-9 ]{2,}$/;
          return pattern.test(value) || this.$t("error.team_rule");
        },
      },
    };
  },
  methods: {
    isNewTeamValid() {
      const areFieldsNotEmpty = this.name.trim() && this.taskTypeId;

      const isTeamNameValid = this.rules.name(this.username) === true;

      return areFieldsNotEmpty && isTeamNameValid;
    },
    async getTaskTypeList() {
      const lists = await this.$root.getLists();
      this.taskTypes = lists["task_types"];
    },
    async addTeam() {
      let formData = new FormData();
      formData.set("addTeam", true);
      formData.set("name", this.name);
      formData.set("task_type_id", this.taskTypeId);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);

        if (response.error) {
          this.AppStateStore.createError("Teams", "nameTaken");
        }
        this.$emit("teamAdded");
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    this.getTaskTypeList();
  },
};
</script>
