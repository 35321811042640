<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("menu.groups.tasks.completed") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card class="elevation-5 mx-5">
        <v-card-title>
          <v-text-field
            class="mr-5"
            prepend-icon="search"
            v-model="search"
            :label="$t('search')"
            single-line
            hide-details
            left
          ></v-text-field>
          <v-menu
            v-model="intervalStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mx-5"
                v-model="intervalStart"
                prepend-icon="event"
                single-line
                hide-details
                readonly
                v-on="on"
                :label="$t('from')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="intervalStart"
              no-title
              @input="menu = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="intervalEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mx-5"
                v-model="intervalEnd"
                prepend-icon="event"
                single-line
                hide-details
                readonly
                v-on="on"
                :label="$t('until')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="intervalEnd"
              no-title
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-title>

        <CompletedTasksTable
          :completedTasks="completedTasks"
          :search="search"
          :show="true"
          :ordHeaders="true"
          :itemsPerPage="500"
          :itemsPerPageOptions="true"
          :intervalStart="intervalStart"
          :intervalEnd="intervalEnd"
        />

        <CompletedTasksTable
          :id="'pdf'"
          :completedTasks="completedTasks"
          :search="search"
          :show="false"
          :ordHeaders="false"
          :hideFooter="true"
          :disablePagination="true"
          :intervalStart="intervalStart"
          :intervalEnd="intervalEnd"
        />
      </v-card>

      <v-btn
        @click="exportPdf"
        class="ml-5 mt-5"
        style="min-width: 100%"
        color="info"
      >
        {{ $t("export") }}
      </v-btn>
    </v-container>
  </v-main>
</template>

<script src="es6-promise.auto.min.js"></script>
<script src="jspdf.min.js"></script>
<script src="html2canvas.min.js"></script>
<script src="html2pdf.min.js"></script>
<style>
#pdf * {
  background-color: #ffffff;
}
</style>
<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import html2pdf from "html2pdf.js";
import CompletedTasksTable from "@components/completed/CompletedTasksTable";

import { getFormattedDate } from "@utils/utilFunctions";

export default {
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  components: {
    CompletedTasksTable,
  },
  data() {
    return {
      search: "",
      intervalStartMenu: false,
      intervalEndMenu: false,
      intervalSearch: "",
      intervalStart: "",
      intervalEnd: "",
      completedTasks: [],
    };
  },
  methods: {
    getFormattedDate,
    exportPdf() {
      const element = document.getElementById("pdf");
      let clonedElement = element.cloneNode(true);
      clonedElement.setAttribute("style", "");

      html2pdf(clonedElement, {
        margin: 1,
        filename: "erledigteAufgaben.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      });

      clonedElement.remove();
    },
    async getCompletedTasks() {
      let formData = new FormData();
      formData.set("getCompletedTasks", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.completedTasks = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getCompletedTasks);
    this.getCompletedTasks();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getCompletedTasks);
  },
};
</script>
