<template>
  <v-dialog :value="true" max-width="500px" persistent>
    <v-card text>
      <v-container grid-list-xl fluid>
        <v-card-title>
          <span class="headline">{{ $t("new_user") }}</span>
        </v-card-title>
        <v-layout wrap>
          <v-flex xs12 sm12>
            <v-text-field
              v-model="username"
              color="purple darken-2"
              :label="$t('username')"
              :rules="[rules.username]"
              required
            ></v-text-field>
            <v-text-field
              v-model="firstname"
              color="purple darken-2"
              :label="$t('lastname')"
              required
            ></v-text-field>
            <v-text-field
              v-model="lastname"
              color="purple darken-2"
              :label="$t('firstname')"
              required
            ></v-text-field>
            <v-select
              v-model="role"
              :items="roles"
              :item-text="
                (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
              "
              item-value="id"
              :label="$t('role')"
            ></v-select>
            <v-text-field
              v-model="password"
              color="purple darken-2"
              :label="$t('login.password')"
              required
              :rules="[rules.password]"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : 'password'"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('cancel')">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!isNewUserValid()"
          @click="
            $emit('createUser', {
              username,
              firstname,
              lastname,
              role,
              password,
            })
          "
        >
          {{ $t("create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useAppStateStore } from "@store/appState.js";
export default {
  name: "NewUserDialog",
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
  data() {
    return {
      username: "",
      firstname: "",
      lastname: "",
      role: "",
      password: "",
      roles: [],
      showPassword: false,
      rules: {
        username: (value) => {
          const pattern = /^[a-zA-Z][a-zA-Z0-9]{2,}$/;
          return pattern.test(value) || this.$t("error.customer_rule");
        },
        password: (value) => {
          const pattern = /^.{8,}$/;
          return pattern.test(value) || this.$t("error.password_rules");
        },
      },
    };
  },
  methods: {
    isNewUserValid() {
      const areFieldsNotEmpty =
        this.username.trim() &&
        this.firstname.trim() &&
        this.lastname.trim() &&
        this.role &&
        this.password.trim();

      const isUsernameValid = this.rules.username(this.username) === true;
      const isPasswordValid = this.rules.password(this.password) === true;

      return areFieldsNotEmpty && isUsernameValid && isPasswordValid;
    },

    async getRoles() {
      let formData = new FormData();
      formData.set("getRoles", true);

      try {
        const response = await this.$root.postData(formData);
        this.roles = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    this.getRoles();
  },
};
</script>
