var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344","outlined":"","shaped":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(_vm._s(_vm.$t("current_user")))]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(" "+_vm._s(_vm.UserStore.user.fullname)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$root.isLanguageGerman ? _vm.UserStore.user.role.name_de : _vm.UserStore.user.role.name_en)+" ")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('v-img',{attrs:{"src":_vm.getSrc}})],1)],1),_c('v-card-actions',[(_vm.userWorkStatus.start === null && !_vm.userWorkStatus.done_today)?_c('v-btn',{on:{"click":function($event){return _vm.updateWorkStatus('startWork')}}},[_vm._v(" "+_vm._s(_vm.$t("hours.startWork"))+" ")]):_vm._e(),(_vm.userWorkStatus.start !== null && !_vm.userWorkStatus.done_today)?_c('v-btn',{on:{"click":function($event){return _vm.updateWorkStatus('endWork')}}},[_vm._v(" "+_vm._s(_vm.$t("hours.endWork"))+" ")]):_vm._e(),(
        _vm.userWorkStatus.start !== null &&
        _vm.userWorkStatus.break_start === null &&
        !_vm.userWorkStatus.done_today
      )?_c('v-btn',{on:{"click":function($event){return _vm.updateWorkStatus('startBreak')}}},[_vm._v(" "+_vm._s(_vm.$t("hours.startBreak"))+" ")]):_vm._e(),(
        _vm.userWorkStatus.start !== null &&
        _vm.userWorkStatus.break_start !== null &&
        !_vm.userWorkStatus.done_today
      )?_c('v-btn',{on:{"click":function($event){return _vm.updateWorkStatus('endBreak')}}},[_vm._v(" "+_vm._s(_vm.$t("hours.endBreak"))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }