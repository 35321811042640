import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import "typeface-roboto/index.css";
import "../src/styles/style.scss";
import JsonCSV from "vue-json-csv";
import messages from "./locale/locales.ts";
import tz from "moment-timezone";
import "typeface-roboto";
import i18n from "./i18n";

import axios from "axios";
import axiosRetry from "axios-retry";
import { EventBus } from "@events/EventBus";

import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(pinia);

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return axiosRetry.exponentialDelay(retryCount);
  },
  retryCondition: (error) => {
    return axiosRetry.isRetryableError(error);
  },
  shouldResetTimeout: true,
  onRetry: (retryCount, error, requestConfig) => {
    const AppStateStore = useAppStateStore();
    if (retryCount === 3) {
      console.error("Max retries reached. Triggering AppStateStore error.");

      if (!AppStateStore.getErrorDialog)
        AppStateStore.createError("Global", "timeout");
    }
  },
});

import VueGeolocation from "vue-browser-geolocation";
Vue.use(VueGeolocation);

import { VueCsvImport } from "vue-csv-import";
Vue.use(VueCsvImport);
Vue.component("vue-csv-import", VueCsvImport);

import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

const moment = require("moment");
Vue.use(require("vue-moment"), {
  moment,
  tz,
});
Vue.use(require("vue-moment"));

Vue.component("downloadCsv", JsonCSV);

Vue.use(VueLayers);
Vue.config.productionTip = false;

import {
  toFeature,
  getFeatureCoordinates,
  getDistanceInMetersOnPath,
} from "./utils/utilFunctions";

// Check if logged in before routing to page
router.beforeEach((to, from, next) => {
  const AppStateStore = useAppStateStore();
  const UserStore = useUserStore();

  AppStateStore.routeCurrent = to;
  AppStateStore.routeFrom = from;

  AppStateStore.resetAll();

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !UserStore.user.id
  ) {
    next("/");
  } else if (
    (to.path == "/schedule" ||
      to.path == "/taskApproval" ||
      to.path == "/newTasks" ||
      to.path == "/incompleteTasks" ||
      to.path == "/completed" ||
      to.path == "/teams" ||
      to.path == "/graveAssignment" ||
      to.path == "/newGraves" ||
      to.path == "/contracts" ||
      to.path == "/graves" ||
      to.path == "/logecExport" ||
      to.path == "/logecImport" ||
      to.path == "/backup" ||
      to.path == "/customers" ||
      to.path == "/fieldManager") &&
    !UserStore.hasFullAccess
  ) {
    next("/dashboard");
  } else if (
    to.path == "/customers" &&
    !UserStore.hasFullAccess &&
    !UserStore.isSuperAdmin
  ) {
    next("/dashboard");
  } else if (to.path == "/" && UserStore.user.id) {
    next("/dashboard");
  } else {
    next();
  }
});

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

import Geolocation from "ol/Geolocation.js";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { useNavStateStore } from "@store/navState.js";
import { useRouteStore } from "@store/route.js";

new Vue({
  components: { VueCsvImport },
  setup() {
    const UserStore = useUserStore();
    const NavStateStore = useNavStateStore();
    const RouteStore = useRouteStore();
    const AppStateStore = useAppStateStore();

    return { UserStore, NavStateStore, RouteStore, AppStateStore };
  },
  data: {
    dbURL: "",
    location: "",
    graveyard: "",
    graveyard_id: 1,
    geolocation: "",
  },
  async destroyed() {
    this.geolocation.setTracking(false);
  },
  async mounted() {
    this.AppStateStore.activateAppLoading();

    this.$nextTick(function () {
      this.AppStateStore.setAppProgress(10);
      this.initGeolocation();
      this.AppStateStore.setAppProgress(20);
      this.getPosition();
    });
  },
  computed: {
    isLanguageGerman() {
      return this.$i18n.locale === "de";
    },
  },
  methods: {
    toFeature,
    initGeolocation() {
      this.geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      });
    },
    async getPosition() {
      let hasPositionChanged = false;

      const locationTimeout = setTimeout(() => {
        if (!hasPositionChanged) {
          console.error("Error: Timeout - Could not get the user's location.");
          this.AppStateStore.resetAppLoading();
          this.AppStateStore.createError("Global", "location");
        }
      }, 8000);

      this.geolocation.once("change:position", async () => {
        clearTimeout(locationTimeout);
        hasPositionChanged = true;
        try {
          const location = this.geolocation.getPosition();
          this.location = this.toFeature(location);

          this.AppStateStore.setAppProgress(60);
          await sleep(50);

          await this.getClosestGraveyard(location);
        } catch (error) {
          console.error("Error getting position or closest graveyard:", error);
          this.AppStateStore.resetAppLoading();
          this.AppStateStore.createError("Global", "location");
        }
      });
    },

    async getClosestGraveyard(location) {
      let formData = new FormData();
      formData.set("getClosestGraveyard", true);
      formData.set("location", JSON.stringify(location));

      this.AppStateStore.setAppProgress(70);
      await sleep(50);

      this.$root.postData(formData).then(async (response) => {
        this.AppStateStore.setAppProgress(80);

        this.graveyard = response["graveyard_name"];
        this.graveyard_id = response["graveyard_id"];

        await this.RouteStore.requestRoutes(this.graveyard);

        this.AppStateStore.resetAppLoading();
      });
    },
    async getLists() {
      let formData = new FormData();
      formData.set("getLists", true);
      formData.set("customer_id", this.UserStore.customer.id);

      const response = await axios.post("/server.php", formData);

      return response.data;
    },

    async postData(formData) {
      let self = this;

      let i = 0;
      let name = "";
      for (let value of formData.keys()) {
        if (i++ == 0) name = value;
      }

      self.AppStateStore.resetServerLoading();
      self.AppStateStore.activateServerLoading();
      const response = await this.networkLoop(formData);
      self.AppStateStore.resetServerLoading();
      return response;
    },

    async postDataGetBlob(formData) {
      let self = this;
      self.AppStateStore.resetServerLoading();
      self.AppStateStore.activateServerLoading();
      const response = await this.networkLoopBlob(formData);
      self.AppStateStore.resetServerLoading();
      return response;
    },

    async networkLoopBlob(formData) {
      let self = this;
      const response = await axios
        .post("/server.php", formData, {
          responseType: "blob",
        })
        .then(function (response) {
          return Promise.resolve(response.data);
        })
        .catch(function (error) {
          const AppStateStore = useAppStateStore();
          AppStateStore.createError("Global", "timeout");
        })
        .finally(function () {});

      return response;
    },

    async networkLoop(formData) {
      let self = this;

      const response = await axios
        .post("/server.php", formData, {})
        .then(function (response) {
          return Promise.resolve(response.data);
        })
        .catch(function (error) {
          self.AppStateStore.resetServerLoading();
          self.AppStateStore.resetAppLoading();
          const AppStateStore = useAppStateStore();

          if (!AppStateStore.getErrorDialog)
            AppStateStore.createError("Global", "timeout");
          console.error(error);
        })
        .finally(function () {});

      return response;
    },
  },
  router,
  vuetify,
  render: (h) => h(App),
  destroyed() {
    const RouteStore = useRouteStore();
    RouteStore.clearData();
  },
  beforeDestroy() {
    const RouteStore = useRouteStore();
    RouteStore.clearData();
  },
  i18n,
  pinia,
}).$mount("#app");
