<template>
  <v-data-table
    :headers="headers"
    :items="tasks"
    :items-per-page="500"
    :footer-props="{
      itemsPerPageText: '',
      itemsPerPageAllText: $t('all'),
      'items-per-page-options': [10, 100, 200, 500, -1],
    }"
    dense
  >
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} /
      {{ items.itemsLength }}
    </template>
    <template slot="no-results">
      {{ $t("no_results") }}
    </template>
    <template v-slot:no-data>
      {{ $t("no_data_text") }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ExportTable",
  props: {
    tasks: Array,
    quantityHeader: Boolean,
    intervalStart: String,
    intervalEnd: String,
  },
  data() {
    return {};
  },
  computed: {
    headers() {
      let headers = [
        { text: "Friedhof", value: "Friedhof", sortable: true },
        { text: "Grab", value: "Grab", sortable: true },
        { text: "G_NR", value: "G_NR", sortable: true },
        { text: "Ord", value: "Ord", sortable: true },
        { text: "sArtikelNr", value: "sArtikelNr", sortable: true },
        { text: "Artikel", value: "Artikel", sortable: true },
        { text: "Mitarbeiter", value: "Mitarbeiter", sortable: true },
        { text: "Bem", value: "Bem", sortable: true },
        {
          text: this.$t("completed_on"),
          value: "completed",
          filter: (value) => {
            if (!this.intervalStart || !this.intervalEnd) return true;

            return this.$moment(value).isBetween(
              this.intervalStart,
              this.intervalEnd,
              "day",
              "[]"
            );
          },
          sortable: true,
        },
      ];

      const insertAt = 6;

      if (this.quantityHeader) {
        headers.splice(insertAt, 0, {
          text: "fMenge",
          value: "fMenge",
          sortable: true,
        });
      }
      return headers;
    },
  },
};
</script>
