<template>
  <v-list>
    <template v-for="graveTask in graveTasks">
      <v-list-item
        :key="graveTask.id"
        class="subheading mx-5"
        three-line
        :class="taskRowBackground(graveTask)"
        @click="$emit('taskSelected', graveTask)"
      >
        <v-list-item-content class="pt-0">
          <v-list-item-title>
            {{ getTaskText(graveTask) }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="graveTask.task_type_id === TaskType.PFLANZEN"
          >
            <v-flex>
              <ItemTable
                @actionPerformed="performItemAction"
                :items="graveTask.items"
                :taskId="graveTask.id"
              ></ItemTable>
            </v-flex>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider
        inset
        style="$divider-inset-margin: 0px"
        :key="graveTask.id + '_divider'"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
import ItemTable from "@components/tables/ItemTable.vue";
import { TaskType } from "@utils/constants";

export default {
  name: "GraveTasks",
  props: {
    graveTasks: Array,
    selectedTask: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ItemTable,
  },
  data() {
    return {
      TaskType: TaskType,
    };
  },
  methods: {
    getTaskText(task) {
      const name = this.$root.isLanguageGerman
        ? task.task_type_de
        : task.task_type_en;
      if (task.description != null) return name + ": " + task.description;
      else return name;
    },
    taskRowBackground(task) {
      return task.id == this.selectedTask.id ? "blue-grey lighten-4" : "";
    },
    async performItemAction(eventData) {
      const formData = new FormData();

      switch (eventData.type) {
        case "addItemToTask":
          formData.set("addItemToTask", true);
          formData.set("task_id", this.selectedTask.id);
          break;
        case "setItemQuantity":
          formData.set("setItemQuantity", true);
          break;
        case "setItemDescription":
          formData.set("setItemDescription", true);
          break;
        default:
          throw new Error("Invalid action type");
      }

      formData.set("item", JSON.stringify(eventData.item));

      await this.$root.postData(formData);

      this.$emit("taskUpdated");
    },
  },
};
</script>
