<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <Map
        v-if="AppStateStore.getCurrentTab === DashboardTab.MAP"
        ref="map"
        :key="componentKeys.map"
        @graveSelected="tabToGraveWithToolbar"
        :lastMapProperties="savedMapProperties"
      />

      <Taskgroups
        v-if="AppStateStore.getCurrentTab === DashboardTab.TASKGROUPS"
        ref="taskgroups"
        @setActiveToNavigation="setActiveToNavigation"
        @tabToTasksWithToolbar="tabToTasksWithToolbar"
      />

      <Tasks
        v-if="AppStateStore.getCurrentTab === DashboardTab.TASKS"
        ref="tasks"
        :taskList="taskList"
        :enableTaskListApproval="enableTaskListApproval"
        @tasksInRadiusDone="backToNavNextGraveGroup"
        :getTasks="getTasks"
      />

      <Navigation
        v-if="NavStateStore.isNavActive"
        v-show="AppStateStore.getCurrentTab === DashboardTab.NAVIGATION"
        ref="navigation"
        @graveReachedStartTask="tabToGraveWithToolbar"
        @taskgroupDoneSelectOtherTaskgroup="tabToTaskGroups"
        :getTasks="getTasks"
      />

      <Grave
        v-if="AppStateStore.getCurrentTab === DashboardTab.GRAVE"
        :id="graveId"
        :taskId="taskId"
        @navigationTaskCompleted="navigationTaskCompleted"
      />
      <v-bottom-navigation
        :value="AppStateStore.getCurrentTab"
        background-color="blue"
        color="white"
        fixed
        app
        grow
        dark
        style="z-index: 9999"
      >
        <v-btn text value="map" @click="mapTabClick()">
          <span>{{ $t("dashboard.map") }}</span>
          <v-icon>map</v-icon>
        </v-btn>

        <template>
          <v-btn value="taskgroups" text @click="taskgroupsTabClick()">
            <span>{{ $t("dashboard.taskgroups") }}</span>
            <v-icon>assignment</v-icon>
          </v-btn>
        </template>

        <v-btn text value="navigation" @click="navigationTabClick()">
          <span>{{ $t("dashboard.navigation") }}</span>
          <v-icon>navigation</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import Map from "@components/Map.vue";
import Taskgroups from "@components/Taskgroups.vue";
import Navigation from "@components/Navigation.vue";
import Grave from "@components/Grave.vue";
import Tasks from "@components/Tasks.vue";

import { useAppBarStore } from "@store/appBar.js";
import { useDrawerStore } from "@store/drawer.js";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { useNavStateStore } from "@store/navState.js";

import { DashboardTab, NavigationMode, TaskType } from "@utils/constants";

export default {
  components: {
    Map,
    Taskgroups,
    Navigation,
    Grave,
    Tasks,
  },

  setup() {
    const AppBarStore = useAppBarStore();
    const DrawerStore = useDrawerStore();
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    const NavStateStore = useNavStateStore();
    return {
      AppBarStore,
      DrawerStore,
      AppStateStore,
      UserStore,
      NavStateStore,
    };
  },
  data: () => ({
    NavigationMode: NavigationMode,
    DashboardTab: DashboardTab,
    TaskType: TaskType,
    savedMapProperties: {
      zoom: 17,
      center: [10.92623, 48.37976],
      rotation: 0,
      active: false,
    },
    graveId: null,
    taskId: null,
    taskList: [],
    enableTaskListApproval: "",
    componentKeys: {
      map: 1,
    },
  }),
  methods: {
    mapTabClick() {
      if (this.AppStateStore.getCurrentTab !== DashboardTab.MAP) {
        this.AppStateStore.backToPreviousTab();
        this.NavStateStore.stopNavigation();
        this.AppStateStore.switchTabTo(DashboardTab.MAP);
      }
    },
    taskgroupsTabClick() {
      if (
        this.AppStateStore.getCurrentTab !== DashboardTab.TASKGROUPS &&
        this.AppStateStore.getCurrentTab !== DashboardTab.TASKS
      )
        this.tabToTaskGroups();
    },
    navigationTabClick() {
      if (
        this.AppStateStore.getCurrentTab !== DashboardTab.NAVIGATION &&
        this.NavStateStore.isNavActive
      )
        this.AppStateStore.switchTabTo(DashboardTab.NAVIGATION);
    },
    setActiveToNavigation(eventData) {
      this.AppStateStore.activateServerLoading();

      const graveyardId = eventData.graveyardId;
      const taskTypeId = eventData.taskTypeId;

      this.tabToNavigation(taskTypeId, graveyardId);
    },
    async tabToNavigation(taskTypeId, graveyardId) {
      await this.getTasks({
        graveyardId: graveyardId,
        taskTypeId: taskTypeId,
        updateNavState: true,
      });

      this.AppStateStore.switchTabTo(DashboardTab.NAVIGATION);
    },
    async backToNavNextGraveGroup() {
      this.AppStateStore.backToPreviousTab();
      await this.getTasks({
        updateNavState: true,
      });
      if (this.NavStateStore.isTaskGroupCompleted) {
        this.NavStateStore.setNavigationMode(NavigationMode.NONE);
        this.$refs.navigation.dialog_done = true;
      } else {
        this.NavStateStore.setNavigationMode(NavigationMode.BACKTOCAR);
        this.NavStateStore.setCarStopOverride(true);
      }
    },
    async tabToTaskGroups() {
      if (
        this.NavStateStore.isNavActive &&
        this.NavStateStore.getActiveTask.taskType === TaskType.PFLANZEN
      ) {
        this.AppStateStore.switchTabTo(DashboardTab.TASKS);
      } else {
        this.AppStateStore.switchTabTo(DashboardTab.TASKGROUPS);
      }
    },

    async tabToTasksWithToolbar(eventData) {
      this.NavStateStore.stopNavigation();

      const { subtitle, graveyardId, taskTypeId } = eventData;

      await this.getTasks({
        graveyardId: graveyardId,
        taskTypeId: taskTypeId,
      });

      this.AppBarStore.setAppBarSubtitle(subtitle);
      this.AppStateStore.setCurrentTabAndBackTo(this.DashboardTab.TASKS);
    },
    async tabToGraveWithToolbar(eventData) {
      this.graveId = eventData.grave.id;
      this.taskId = eventData.grave.taskId;

      const { zoom, center, rotation } = eventData.mapState;
      this.savedMapProperties = { zoom, center, rotation, active: true };

      this.tabToGrave();
    },
    async navigationTaskCompleted(id) {
      this.backToTab();
      this.$refs.navigation.navToNextGrave(id);
    },
    async backToTab() {
      if (
        this.AppStateStore.getPreviousTab === DashboardTab.NAVIGATION &&
        this.AppStateStore.getCurrentTab === DashboardTab.GRAVE &&
        this.NavStateStore.getNavigationMode &&
        this.NavStateStore.isNavigationStopped
      ) {
        this.NavStateStore.backToPreviousNavigationMode();
      }

      if (this.AppStateStore.getCurrentTab === DashboardTab.TASKS) {
        this.NavStateStore.stopNavigation();
        this.AppStateStore.switchTabTo(DashboardTab.TASKGROUPS);
        this.AppBarStore.setAppBarSubtitle("");
      } else {
        this.AppStateStore.backToPreviousTab();
      }
    },
    tabToGrave() {
      this.DrawerStore.toggleOffDrawer();
      this.AppBarStore.setAppBarTitle(this.$t("grave_details"));
      this.AppStateStore.setCurrentTabAndBackTo(DashboardTab.GRAVE);
    },
    checkTaskListApprovalEnabled() {
      const hasPendingTask =
        this.taskList && this.taskList.some((task) => task.end === null);

      this.enableTaskListApproval = !hasPendingTask;
    },
    async getTasks({
      graveyardId = this.NavStateStore.getActiveTask.graveyardId,
      taskTypeId = this.NavStateStore.getActiveTask.taskType,
      updateNavState = false,
      refreshGravesInRadius = false,
    } = {}) {
      let formData = new FormData();
      formData.set("getTaskGroup", true);
      formData.set("graveyard_id", graveyardId);
      formData.set("task_type_id", taskTypeId);
      formData.set("user_id", this.UserStore.user.id);

      try {
        const response = await this.$root.postData(formData);
        this.taskList = response.taskList;

        if (updateNavState) {
          this.NavStateStore.setTaskGroup(response.taskgroup_graves);
          this.NavStateStore.setOrdGroups(response.ordGroups);
        }

        this.checkTaskListApprovalEnabled();

        if (this.taskList.length > 0)
          this.AppBarStore.setAppBarTitle(this.taskList[0].graveyard_name);

        if (refreshGravesInRadius) {
          await this.refreshTaskGroupInRadius(graveyardId, taskTypeId);
        }
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },

    async refreshTaskGroupInRadius(graveyardId, taskTypeId) {
      let formData = new FormData();
      formData.set("refreshTaskGroupInRadius", true);
      formData.set(
        "graves",
        JSON.stringify(this.NavStateStore.getTaskGroupInRadius)
      );
      formData.set("graveyard_id", graveyardId);
      formData.set("task_type_id", taskTypeId);
      formData.set("user_id", this.UserStore.user.id);

      try {
        const response = await this.$root.postData(formData);

        this.NavStateStore.setTaskGroupInRadius(response);
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    initDashboard() {
      this.NavStateStore.stopNavigation();
      this.componentKeys.map += 1;
      this.AppStateStore.setCurrentTab(DashboardTab.MAP);
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.initDashboard);
    this.initDashboard();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.initDashboard);
  },
};
</script>
