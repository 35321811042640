<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("menu.groups.employees.manage") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card class="elevation-5 mx-5">
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            :label="$t('search')"
            single-line
            hide-details
            left
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :items-per-page="20"
          :footer-props="{
            itemsPerPageText: '',
            itemsPerPageAllText: $t('all'),
            'items-per-page-options': [10, 20, 50, 100, -1],
          }"
        >
          <template #item.id="{ item }">{{ item.id }}</template>
          <template #item.username="{ item }">
            {{ item.username }}
          </template>
          <template #item.firstname="{ item }">
            {{ item.firstname }}
          </template>
          <template #item.lastname="{ item }">
            {{ item.lastname }}
          </template>
          <template v-if="$root.isLanguageGerman" #item.role="{ item }">
            {{ item.role_de }}
          </template>
          <template v-else #item.role="{ item }">
            {{ item.role_en }}
          </template>
          <template #item.edit="{ item }">
            <v-btn
              fab
              small
              dark
              color="blue"
              @click="
                userToEdit = {
                  ...item,
                  role: {
                    id: item.role_id,
                    name_de: item.role_de,
                    name_en: item.role_en,
                  },
                };
                dialogs.editUser = true;
              "
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <template #item.lock="{ item }">
            <v-btn
              fab
              small
              dark
              color="red"
              v-if="isLocked(item)"
              @click="toggleUser(item.id)"
            >
              <v-icon>lock</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              dark
              color="blue"
              v-else
              @click="toggleUser(item.id)"
            >
              <v-icon>lock_open</v-icon>
            </v-btn>
          </template>
          <template #item.delete="{ item }">
            <v-btn
              fab
              small
              dark
              color="red"
              @click="
                userToDelete = item.id;
                dialogs.deleteUser = true;
              "
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }} /
            {{ items.itemsLength }}
          </template>
          <template slot="no-results">
            {{ $t("no_results") }}
          </template>
          <template v-slot:no-data>
            {{ $t("no_data_text") }}
          </template>
        </v-data-table>
      </v-card>

      <v-layout row justify-center>
        <NewUserDialog
          v-if="dialogs.newUser"
          @createUser="createUser"
          @cancel="dialogs.newUser = false"
        />
      </v-layout>

      <v-layout row justify-center>
        <UserEditDialog
          v-if="dialogs.editUser"
          :userToEdit="userToEdit"
          @userEdited="refreshAndCloseEdit"
          @cancel="dialogs.editUser = false"
        />
      </v-layout>

      <v-layout row justify-center>
        <ConfirmDialog
          :text="$t('delete_question_user')"
          :show="dialogs.deleteUser"
          @confirm="deleteUser()"
          @cancel="
            dialogs.deleteUser = false;
            userToDelete = '';
          "
        />
      </v-layout>

      <v-card-text bottom right>
        <v-fab-transition>
          <v-btn
            color="blue"
            dark
            app
            absolute
            bottom
            right
            fab
            style="bottom: 0"
            @click="dialogs.newUser = true"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-text>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import NewUserDialog from "@components/dialogs/NewUserDialog.vue";
import UserEditDialog from "@components/employees/UserEditDialog.vue";
import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";
export default {
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  components: { NewUserDialog, UserEditDialog, ConfirmDialog },
  data() {
    return {
      userToEdit: {},
      dialogs: {
        deleteUser: false,
        editUser: false,
        newUser: false,
      },
      search: "",
      userToDelete: "",
      users: [],
    };
  },
  computed: {
    getRoleValue() {
      if (this.$root.isLanguageGerman) return "role_de";
      return "role_en";
    },

    headers() {
      return [
        { text: "ID", value: "id", sortable: true },
        { text: this.$t("username"), value: "username", sortable: true },
        { text: this.$t("firstname"), value: "firstname", sortable: true },
        { text: this.$t("lastname"), value: "lastname", sortable: true },

        {
          text: this.$t("authorization"),
          value: this.getRoleValue,
          sortable: true,
        },
        { text: this.$t("edit"), value: "edit", sortable: false },
        { text: this.$t("lock"), value: "lock", sortable: false },
        { text: this.$t("delete"), value: "delete", sortable: false },
      ];
    },
  },

  methods: {
    async refreshAndCloseEdit() {
      await this.getUsers();
      this.dialogs.editUser = false;
    },
    isLocked(item) {
      return Boolean(Number(item.locked));
    },
    async toggleUser(user_id) {
      let formData = new FormData();
      formData.set("toggleUser", true);
      formData.set("user_id", user_id);

      await this.$root.postData(formData);
      this.getUsers();
    },
    async deleteUser() {
      let formData = new FormData();
      formData.set("deleteUser", true);
      formData.set("user_id", this.userToDelete);
      formData.set("customer_id", this.UserStore.customer.id);

      await this.$root.postData(formData);
      this.dialogs.deleteUser = false;
      this.getUsers();
    },
    async createUser(user) {
      let formData = new FormData();
      formData.set("addUser", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set("role_id", user.role);
      formData.set("username", user.username);
      formData.set("firstname", user.firstname);
      formData.set("lastname", user.lastname);
      formData.set("password", user.password);

      try {
        const response = await this.$root.postData(formData);

        if (response.error) {
          this.AppStateStore.createError("Customers", "userName");
        } else {
          this.dialogs.newUser = false;
          this.getUsers();
        }
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },

    async getUsers() {
      let formData = new FormData();
      formData.set("getUsers", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.users = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getUsers);
    this.getUsers();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getUsers);
  },
};
</script>
