<template>
  <v-dialog :value="true" max-width="500px" persistent>
    <v-card text>
      <v-container grid-list-xl fluid>
        <v-card-title>
          <span class="headline">{{ $t("new_customer") }}</span>
        </v-card-title>

        <v-layout wrap>
          <v-flex xs12 class="py-0">
            <v-text-field
              v-model="customerName"
              :label="$t('name')"
              :rules="[nameRule]"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('cancel')">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          v-if="isNewCustomerValid"
          @click="$emit('createCustomer', customerName)"
        >
          {{ $t("create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewCustomerDialog",
  props: {},
  data() {
    return {
      customerName: "",
      nameRule: (value) => {
        const pattern = /^[a-zA-Z][a-zA-Z0-9]{2,}$/;
        return pattern.test(value) || this.$t("error.customer_rule");
      },
    };
  },
  methods: {
    isNewCustomerValid() {
      const pattern = /^[a-zA-Z][a-zA-Z0-9]{2,}$/;
      return pattern.test(this.customerName);
    },
  },
};
</script>
