<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("customers") }}
      </v-card-text>
      <v-divider></v-divider>
      <CustomersTable
        @customerSelected="customerSelected"
        @customerCreated="getCustomers"
        @customerStatusChanged="getCustomers"
        :customers="customers"
        class="mx-5 mb-5"
      />

      <template v-if="selectedCustomer">
        <GraveyardsTable
          @graveyardCreated="getCustomers"
          @graveyardDeleted="getCustomers"
          :graveyards="selectedCustomer.graveyards"
          :customerName="selectedCustomer.name"
          :customerId="selectedCustomer.id"
          class="mx-5 mb-10"
        />

        <UsersTable
          @userCreated="getCustomers"
          @userDeleted="getCustomers"
          :users="selectedCustomer.users"
          :customerName="selectedCustomer.name"
          :customerId="selectedCustomer.id"
          class="mx-5"
        />
      </template>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import CustomersTable from "@components/customers/tables/CustomersTable.vue";
import GraveyardsTable from "@components/customers/tables/GraveyardsTable.vue";
import UsersTable from "@components/customers/tables/UsersTable.vue";

export default {
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
  components: {
    CustomersTable,
    GraveyardsTable,
    UsersTable,
  },
  data() {
    return {
      selectedCustomer: "",
      customers: [],
    };
  },

  methods: {
    customerSelected(customer) {
      this.selectedCustomer = customer;
    },

    async getCustomers() {
      let formData = new FormData();
      formData.set("getCustomers", true);

      try {
        const response = await this.$root.postData(formData);
        this.customers = response;
        if (this.selectedCustomer)
          this.selectedCustomer =
            this.customers.find(
              (customer) => customer.id === this.selectedCustomer.id
            ) || "";
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },

  mounted() {
    EventBus.$on("refreshComponent", this.getCustomers);
    this.getCustomers();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getCustomers);
  },
};
</script>
