<template>
  <v-app id="app">
    <ProgressBar></ProgressBar>

    <TheAppBar></TheAppBar>

    <router-view :key="$route.fullPath"></router-view>

    <TheDrawer></TheDrawer>

    <ErrorDialog></ErrorDialog>
    <MessageDialog></MessageDialog>
  </v-app>
</template>

<script>
import TheAppBar from "@components/global/TheAppBar.vue";
import TheDrawer from "@components/global/TheDrawer.vue";
import ErrorDialog from "@components/global/ErrorDialog.vue";
import MessageDialog from "@components/global/MessageDialog.vue";
import ProgressBar from "@components/global/ProgressBar.vue";
import { useAppStateStore } from "@store/appState.js";
import { useRouteStore } from "@store/route.js";
export default {
  components: {
    TheAppBar,
    TheDrawer,
    ErrorDialog,
    MessageDialog,
    ProgressBar,
  },
  setup() {
    const AppStateStore = useAppStateStore();
    const RouteStore = useRouteStore();

    return { AppStateStore, RouteStore };
  },
  beforeUnmount() {
    useRouteStore.clearData();
    this.RouteStore.clearData();
  },
  beforeDestroy() {
    useRouteStore.clearData();
    this.RouteStore.clearData();
  },
  destroyed() {
    useRouteStore.clearData();
    this.RouteStore.clearData();
  },
};
</script>
