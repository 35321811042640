<template>
  <vl-feature>
    <vl-geom-point :coordinates="coordinates"></vl-geom-point>
    <vl-style-box :z-index="zIndex">
      <vl-style-text
        v-if="text.display"
        :text="text.index"
        font="14px monospace"
      ></vl-style-text>
      <vl-style-circle :radius="featureRadius">
        <vl-style-fill :color="color"></vl-style-fill>
        <vl-style-stroke
          v-if="border.display"
          :width="border.width"
          :color="border.color"
        ></vl-style-stroke>
      </vl-style-circle>
    </vl-style-box>
  </vl-feature>
</template>

<script>
export default {
  name: "MapFeature",
  props: {
    coordinates: Array,
    color: String,
    featureRadius: {
      type: Number,
      default: 6,
    },
    border: {
      type: Object,
      default: () => {
        return { display: false, width: 2, color: "white" };
      },
    },
    zIndex: {
      type: Number,
      default: 9999,
    },
    text: {
      type: Object,
      default: () => {
        return { display: false, index: 1 };
      },
    },
  },
  methods: {
    getText(feature) {
      const index = this.NavStateStore.getTaskGroupInRadius.findIndex(
        (x) => x.id == feature.id
      );
      return `${index}`;
    },
  },
};
</script>
