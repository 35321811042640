<template>
  <v-data-table
    :id="id"
    v-show="show"
    :headers="headers"
    :items="workingHours"
    :search="search"
    :items-per-page="10"
    :hide-default-footer="hideFooter"
    :diable-pagination="disablePagination"
    :footer-props="footerProps"
    dense
  >
    <template #item.firstname="{ item }">
      {{ item.firstname }}
    </template>
    <template #item.lastname="{ item }">
      {{ item.lastname }}
    </template>
    <template #item.start="{ item }">{{ item.start }}</template>
    <template #item.end="{ item }">{{ item.end }}</template>
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} /
      {{ items.itemsLength }}
    </template>
    <template slot="no-results">
      {{ $t("no_results") }}
    </template>
    <template v-slot:no-data>
      {{ $t("no_data_text") }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "WorkingHoursTable",
  props: {
    id: {
      type: String,
      default: "",
    },
    workingHours: Array,
    search: String,
    show: Boolean,
    itemPerPage: Number,
    itemsPerPageOptions: Boolean,
    hideFooter: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    footerProps() {
      if (this.itemsPerPageOptions)
        return {
          itemsPerPageText: "",
          itemsPerPageAllText: this.$t("all"),
          "items-per-page-options": [10, 100, 200, 500, -1],
        };
      else return {};
    },
    headers() {
      return [
        {
          text: this.$t("firstname"),
          value: "firstname",
          sortable: true,
        },
        { text: this.$t("lastname"), value: "lastname", sortable: true },
        { text: this.$t("start"), value: "start", sortable: true },
        { text: this.$t("end"), value: "end", sortable: true },
        {
          text: this.$t("time_spent_on_breaks"),
          value: "break_sum",
          sortable: true,
        },
      ];
    },
  },
};
</script>
