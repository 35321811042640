// i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./locale/locales.ts";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "de", // set locale
  messages, // set locale messages
});

export default i18n;
