<template>
  <v-list three-line class="py-0">
    <v-list-item class="subheading mx-5">
      <v-list-item-content class="py-0">
        <v-list-item-subtitle>
          <v-icon>{{ "location_on" }}</v-icon>
          {{ grave.graveyard }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="ml-4 pl-1">
          {{ $t("grave_number") + " " + grave.number }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-icon>{{ "person" }}</v-icon>
          {{ grave.person }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider light></v-divider>
    <v-spacer class="mt-2"></v-spacer>
    <v-list-item class="ml-5 mr-5" style="min-height: 20px">
      <v-list-item-subtitle>{{ $t("description") }}</v-list-item-subtitle>
    </v-list-item>
    <v-list-item class="mx-5 pt-0">
      <v-textarea
        v-if="editDescription && editable"
        class="body-1 mb-0"
        v-model="grave.description"
        :hint="$t('description')"
      ></v-textarea>

      <v-list-item-content v-if="!editDescription" class="body-1 py-0">
        {{ grave.description }}
      </v-list-item-content>

      <v-btn
        v-if="!editDescription && editable"
        @click="editDescription = !editDescription"
        class="rounded-circle"
        x-small
        height="40px"
        color="blue"
        right
        absolute
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        v-if="editDescription && editable"
        @click="
          editTextData({ method: 'description', text: grave.description })
        "
        class="rounded-circle"
        x-small
        height="40px"
        color="green"
        right
        absolute
      >
        <v-icon>done</v-icon>
      </v-btn>
    </v-list-item>
    <v-list-item class="ml-5 mr-5" style="min-height: 20px">
      <v-list-item-subtitle>{{ $t("comment") }}</v-list-item-subtitle>
    </v-list-item>
    <v-list-item class="mx-5 pt-0">
      <v-textarea
        v-if="editComm && editable"
        class="body-1 mb-0"
        v-model="grave.comment"
        :hint="$t('comment')"
      ></v-textarea>
      <v-list-item-content v-if="!editComm" class="body-1 py-0">
        {{ grave.comment }}
      </v-list-item-content>

      <v-btn
        v-if="!editComm && editable"
        @click="editComm = !editComm"
        color="blue"
        class="rounded-circle"
        x-small
        height="40px"
        right
        absolute
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        v-if="editComm && editable"
        @click="editTextData({ method: 'comment', text: grave.comment })"
        class="rounded-circle"
        x-small
        height="40px"
        color="green"
        right
        absolute
      >
        <v-icon>done</v-icon>
      </v-btn>
    </v-list-item>
    <v-list-item class="ml-5 mr-5" style="min-height: 20px">
      <v-list-item-subtitle>{{ $t("special") }}</v-list-item-subtitle>
    </v-list-item>
    <v-list-item class="mx-5 pt-0">
      <v-textarea
        v-if="editInfo && editable"
        class="body-1 mb-0"
        v-model="grave.info"
        hint="Info"
      ></v-textarea>
      <v-list-item-content v-if="!editInfo" class="body-1 py-0">
        {{ grave.info }}
      </v-list-item-content>

      <v-btn
        v-if="!editInfo && editable"
        @click="editInfo = !editInfo"
        class="rounded-circle"
        x-small
        height="40px"
        color="blue"
        right
        absolute
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        v-if="editInfo && editable"
        @click="editTextData({ method: 'info', text: grave.info })"
        fab
        small
        color="green"
        right
        class
        absolute
      >
        <v-icon>done</v-icon>
      </v-btn>
    </v-list-item>
    <v-spacer class="mb-2"></v-spacer>
    <v-divider light></v-divider>
  </v-list>
</template>

<script>
export default {
  name: "GraveTextData",
  props: {
    grave: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editDescription: false,
      editComm: false,
      editInfo: false,
    };
  },
  methods: {
    async editTextData(data) {
      let formData = new FormData();
      switch (data.method) {
        case "description":
          formData.set("editDescription", true);
          formData.set("description", data.text);
          break;
        case "comment":
          formData.set("editComment", true);
          formData.set("comment", data.text);
          break;
        case "info":
          formData.set("editInformation", true);
          formData.set("info", data.text);
          break;
        default:
          break;
      }

      formData.set("id", this.grave.id);
      await this.$root.postData(formData);

      switch (data.method) {
        case "description":
          this.editDescription = !this.editDescription;
          break;
        case "comment":
          this.editComm = !this.editComm;
          break;
        case "info":
          this.editInfo = !this.editInfo;
          break;
        default:
          break;
      }

      this.$emit("graveTextDataEdited");
    },
  },
};
</script>
