import { defineStore } from "pinia";

import { UserRole } from "@utils/constants";

export const useUserStore = defineStore({
  id: "userStore",

  state: () => ({
    customer: {
      id: null,
      name: "",
    },
    user: {
      id: null,
      username: "",
      fullname: "",
      role: {
        id: null,
        name_de: "",
        name_en: "",
      },
    },
  }),
  getters: {
    isSuperAdmin() {
      return this.user.role.id === UserRole.SUPERADMIN;
    },
    isTeamLeader() {
      return this.user.role.id === UserRole.TEAMLEITER;
    },
    hasFullAccess() {
      return (
        this.user.role.id === UserRole.VERWALTUNG ||
        this.user.role.id === UserRole.GESCHÄFTSFÜHRER
      );
    },
    hasNormalAccess() {
      return this.user.role.id === UserRole.MITARBEITER;
    },
  },
  actions: {},
});
