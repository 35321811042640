<template>
  <v-card class="elevation-5">
    <v-card-title>
      <v-text-field
        v-model="searchFieldContent"
        prepend-icon="search"
        :label="$t('search')"
        single-line
        hide-details
        left
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="graveyards"
      :search="searchFieldContent"
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageAllText: $t('all'),
        'items-per-page-options': [10, 100, 200, 500, -1],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>
            {{ $t("graveyards") + ": " + customerName }}
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="newGraveyardDialog = true"
          >
            {{ $t("new_graveyard") }}
          </v-btn>
          <NewGraveyardDialog
            v-if="newGraveyardDialog"
            @createGraveyard="createGraveyard"
            @cancel="newGraveyardDialog = false"
          />
        </v-toolbar>
      </template>

      <template #item.name="{ item }">{{ item.name }}</template>
      <template #item.delete="{ item }">
        <v-btn
          fab
          small
          dark
          color="red"
          @click="
            graveyardToDelete = item.id;
            deleteGraveyardDialog = true;
          "
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>
      <template slot="no-results">
        {{ $t("no_results") }}
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_text") }}
      </template>
    </v-data-table>

    <v-layout row justify-center>
      <ConfirmDialog
        :text="$t('delete_question_graveyard')"
        :show="deleteGraveyardDialog"
        @confirm="
          deleteGraveyard();
          deleteGraveyardDialog = false;
        "
        @cancel="
          deleteGraveyardDialog = false;
          graveyardToDelete = '';
        "
      />
    </v-layout>
  </v-card>
</template>

<script>
import NewGraveyardDialog from "@components/customers/dialogs/NewGraveyardDialog.vue";
import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";
export default {
  name: "GraveyardsTable",
  components: {
    NewGraveyardDialog,
    ConfirmDialog,
  },
  props: {
    graveyards: Array,
    customerName: String,
    customerId: Number,
  },
  data() {
    return {
      searchFieldContent: "",
      deleteGraveyardDialog: false,
      newGraveyardDialog: false,
      graveyardToDelete: "",
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("name"), value: "name", sortable: true },
        { text: this.$t("delete"), value: "delete", sortable: false },
      ];
    },
  },
  methods: {
    async createGraveyard(graveyardName) {
      let formData = new FormData();
      formData.set("createGraveyard", true);
      formData.set("customer_id", this.customerId);
      formData.set("name", graveyardName);

      const response = await this.$root.postData(formData);

      if (response.error) {
        this.AppStateStore.createError("Customers", "graveyardName");
      }
      this.newGraveyardDialog = false;
      this.$emit("graveyardCreated");
    },
    async deleteGraveyard() {
      let formData = new FormData();
      formData.set("deleteGraveyard", true);
      formData.set("graveyard_id", this.graveyardToDelete);

      await this.$root.postData(formData);
      this.$emit("graveyardDeleted");
    },
  },
};
</script>
