<template>
  <v-app-bar
    v-if="AppBarStore.isAppBarVisible"
    :color="AppBarStore.getColor"
    app
    dark
  >
    <template
      v-if="
        !AppStateStore.isAppStateBackTo &&
        !AppStateStore.isAppStateBeforeLoggedIn
      "
    >
      <v-app-bar-nav-icon @click="DrawerStore.toggleDrawer" />
    </template>
    <template v-else-if="AppStateStore.isAppStateBackTo">
      <v-btn icon @click="AppStateStore.backToPreviousTab()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
    </template>

    <v-toolbar-title>
      {{ "Friedhofsapp " + AppBarStore.getAppBarTitle }}
      <div v-if="AppStateStore.isAppStateBackTo" class="subheading">
        {{ AppBarStore.getAppBarSubtitle }}
      </div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu v-if="!AppStateStore.isAppStateBackTo">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>

      <template v-if="AppStateStore.isAppStateBeforeLoggedIn">
        <v-list>
          <v-list-item
            @click="
              $router.push({
                name: 'login',
                query: { redirect: '/login' },
              })
            "
          >
            <v-list-item-title>{{ $t("login.login") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>

      <template v-else>
        <v-list>
          <template v-if="UserStore.hasFullAccess">
            <v-list-item @click="openDialog('TaskFormDialog')">
              <v-list-item-title>{{ $t("create_new_task") }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item @click="openDialog('SettingsDialog')">
            <v-list-item-title>
              {{ $t("menu_settings.settings") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>
              {{ $t("menu_settings.logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-menu>
    <SettingsDialog
      v-if="
        !AppStateStore.isAppStateBackTo &&
        !AppStateStore.isAppStateBeforeLoggedIn
      "
      :getDialog="getDialog"
      :closeDialog="closeDialog"
    ></SettingsDialog>
    <TaskFormDialog
      v-if="
        !AppStateStore.isAppStateBackTo &&
        !AppStateStore.isAppStateBeforeLoggedIn
      "
      :getDialog="getDialog"
      :closeDialog="closeDialog"
    ></TaskFormDialog>
  </v-app-bar>
</template>

<script>
import { useAppBarStore } from "@store/appBar.js";
import { useDrawerStore } from "@store/drawer.js";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";

import SettingsDialog from "@components/dialogs/SettingsDialog.vue";
import TaskFormDialog from "@components/dialogs/TaskFormDialog.vue";

export default {
  name: "TheAppBar",
  components: { SettingsDialog, TaskFormDialog },
  setup() {
    const AppBarStore = useAppBarStore();
    const DrawerStore = useDrawerStore();
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppBarStore, DrawerStore, AppStateStore, UserStore };
  },
  data() {
    return {
      dialogs: {
        settingsDialog: false,
        newTaskDialog: false,
      },
    };
  },
  computed: {},
  methods: {
    openDialog(dialogName) {
      switch (dialogName) {
        case "SettingsDialog":
          this.dialogs.settingsDialog = true;
          break;
        case "TaskFormDialog":
          this.dialogs.newTaskDialog = true;
          break;

        default:
          break;
      }
    },
    closeDialog(dialogName) {
      switch (dialogName) {
        case "SettingsDialog":
          this.dialogs.settingsDialog = false;
          break;
        case "TaskFormDialog":
          this.dialogs.newTaskDialog = false;
          break;
        default:
          break;
      }
    },
    getDialog(dialogName) {
      switch (dialogName) {
        case "SettingsDialog":
          return this.dialogs.settingsDialog;
        case "TaskFormDialog":
          return this.dialogs.newTaskDialog;
        default:
          break;
      }
    },

    logout() {
      window.location.reload();
    },
  },
};
</script>
