var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"pb-0"},[_c('v-container',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"fluid":""}},[_c('v-card-text',{staticClass:"grey--text headline text-xs-left"},[_vm._v(" "+_vm._s(_vm.$t("menu.groups.employees.manage"))+" ")]),_c('v-divider'),_c('v-card',{staticClass:"elevation-5 mx-5"},[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-icon":"search","label":_vm.$t('search'),"single-line":"","hide-details":"","left":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"items-per-page":20,"footer-props":{
          itemsPerPageText: '',
          itemsPerPageAllText: _vm.$t('all'),
          'items-per-page-options': [10, 20, 50, 100, -1],
        }},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_vm._v(_vm._s(item.id))]}},{key:"item.username",fn:function({ item }){return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.firstname",fn:function({ item }){return [_vm._v(" "+_vm._s(item.firstname)+" ")]}},{key:"item.lastname",fn:function({ item }){return [_vm._v(" "+_vm._s(item.lastname)+" ")]}},(_vm.$root.isLanguageGerman)?{key:"item.role",fn:function({ item }){return [_vm._v(" "+_vm._s(item.role_de)+" ")]}}:{key:"item.role",fn:function({ item }){return [_vm._v(" "+_vm._s(item.role_en)+" ")]}},{key:"item.edit",fn:function({ item }){return [_c('v-btn',{attrs:{"fab":"","small":"","dark":"","color":"blue"},on:{"click":function($event){_vm.userToEdit = {
                ...item,
                role: {
                  id: item.role_id,
                  name_de: item.role_de,
                  name_en: item.role_en,
                },
              };
              _vm.dialogs.editUser = true;}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.lock",fn:function({ item }){return [(_vm.isLocked(item))?_c('v-btn',{attrs:{"fab":"","small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.toggleUser(item.id)}}},[_c('v-icon',[_vm._v("lock")])],1):_c('v-btn',{attrs:{"fab":"","small":"","dark":"","color":"blue"},on:{"click":function($event){return _vm.toggleUser(item.id)}}},[_c('v-icon',[_vm._v("lock_open")])],1)]}},{key:"item.delete",fn:function({ item }){return [_c('v-btn',{attrs:{"fab":"","small":"","dark":"","color":"red"},on:{"click":function($event){_vm.userToDelete = item.id;
              _vm.dialogs.deleteUser = true;}}},[_c('v-icon',[_vm._v("delete")])],1)]}},{key:`footer.page-text`,fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" / "+_vm._s(items.itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("no_data_text"))+" ")]},proxy:true}],null,true)},[_c('template',{slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("no_results"))+" ")])],2)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[(_vm.dialogs.newUser)?_c('NewUserDialog',{on:{"createUser":_vm.createUser,"cancel":function($event){_vm.dialogs.newUser = false}}}):_vm._e()],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[(_vm.dialogs.editUser)?_c('UserEditDialog',{attrs:{"userToEdit":_vm.userToEdit},on:{"userEdited":_vm.refreshAndCloseEdit,"cancel":function($event){_vm.dialogs.editUser = false}}}):_vm._e()],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('ConfirmDialog',{attrs:{"text":_vm.$t('delete_question_user'),"show":_vm.dialogs.deleteUser},on:{"confirm":function($event){return _vm.deleteUser()},"cancel":function($event){_vm.dialogs.deleteUser = false;
          _vm.userToDelete = '';}}})],1),_c('v-card-text',{attrs:{"bottom":"","right":""}},[_c('v-fab-transition',[_c('v-btn',{staticStyle:{"bottom":"0"},attrs:{"color":"blue","dark":"","app":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":function($event){_vm.dialogs.newUser = true}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }