<template>
  <v-dialog :value="true" persistent max-width="400">
    <v-card text>
      <v-container grid-list-xl fluid>
        <v-layout wrap>
          <v-flex xs12 sm12>
            <v-select
              v-model="userId"
              :items="users"
              item-avatar
              label="User"
              item-text="fullname"
              item-value="id"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('cancel')">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!isNewTeamMemberValid()"
          @click="addTeamMember()"
        >
          {{ $t("create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";
export default {
  name: "NewTeamMemberDialog",
  props: {
    teamId: Number,
  },
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  data() {
    return {
      userId: "",
      users: [],
    };
  },

  methods: {
    isNewTeamMemberValid() {
      return this.userId;
    },
    async addTeamMember() {
      let formData = new FormData();
      formData.set("addTeamMember", true);
      formData.set("team_id", this.teamId);
      formData.set("user_id", this.userId);

      const response = await this.$root.postData(formData);

      if (response.error) {
        this.AppStateStore.createError("Teams", "teamUserDuplicate");
      }

      this.$emit("teamMemberAdded");
    },
    async getUsers() {
      let formData = new FormData();
      formData.set("getUsers", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.users = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
