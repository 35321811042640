var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.AppBarStore.isAppBarVisible)?_c('v-app-bar',{attrs:{"color":_vm.AppBarStore.getColor,"app":"","dark":""}},[(
      !_vm.AppStateStore.isAppStateBackTo &&
      !_vm.AppStateStore.isAppStateBeforeLoggedIn
    )?[_c('v-app-bar-nav-icon',{on:{"click":_vm.DrawerStore.toggleDrawer}})]:(_vm.AppStateStore.isAppStateBackTo)?[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.AppStateStore.backToPreviousTab()}}},[_c('v-icon',[_vm._v("arrow_back")])],1)]:_vm._e(),_c('v-toolbar-title',[_vm._v(" "+_vm._s("Friedhofsapp " + _vm.AppBarStore.getAppBarTitle)+" "),(_vm.AppStateStore.isAppStateBackTo)?_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.AppBarStore.getAppBarSubtitle)+" ")]):_vm._e()]),_c('v-spacer'),(!_vm.AppStateStore.isAppStateBackTo)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,1582921727)},[(_vm.AppStateStore.isAppStateBeforeLoggedIn)?[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
              name: 'login',
              query: { redirect: '/login' },
            })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("login.login")))])],1)],1)]:[_c('v-list',[(_vm.UserStore.hasFullAccess)?[_c('v-list-item',{on:{"click":function($event){return _vm.openDialog('TaskFormDialog')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("create_new_task")))])],1)]:_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openDialog('SettingsDialog')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("menu_settings.settings"))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("menu_settings.logout"))+" ")])],1)],2)]],2):_vm._e(),(
      !_vm.AppStateStore.isAppStateBackTo &&
      !_vm.AppStateStore.isAppStateBeforeLoggedIn
    )?_c('SettingsDialog',{attrs:{"getDialog":_vm.getDialog,"closeDialog":_vm.closeDialog}}):_vm._e(),(
      !_vm.AppStateStore.isAppStateBackTo &&
      !_vm.AppStateStore.isAppStateBeforeLoggedIn
    )?_c('TaskFormDialog',{attrs:{"getDialog":_vm.getDialog,"closeDialog":_vm.closeDialog}}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }