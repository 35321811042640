var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vl-map',{ref:"map",staticStyle:{"position":"absolute","background-color":"#868686"},attrs:{"data-projection":"EPSG:4326","move-tolerance":2},on:{"singleclick":_vm.mapClick}},[_c('vl-view',{attrs:{"zoom":_vm.zoom,"center":_vm.center,"rotation":_vm.rotation,"min-zoom":11,"max-zoom":21},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.center=$event},"update:rotation":function($event){_vm.rotation=$event}}}),_c('vl-layer-tile',{attrs:{"z-index":0}},[_c('vl-source-osm')],1),_c('vl-layer-tile',{attrs:{"z-index":1}},[_c('vl-source-xyz',{attrs:{"url":_vm.url}})],1),(_vm.gravesToDisplay.length)?_c('vl-layer-vector',{attrs:{"render-mode":"image","overlay":true}},[[_c('vl-source-vector',{attrs:{"features":_vm.gravesToDisplay}}),_c('vl-style-box',{attrs:{"z-index":1}},[_c('vl-style-circle',{attrs:{"radius":6}},[_c('vl-style-fill',{attrs:{"color":"#76FF03"}})],1)],1)]],2):_vm._e(),(
        !_vm.AppStateStore.getAppLoading && _vm.locationSnappedToFootPath.geometry
      )?_c('Route',{attrs:{"route":[
        _vm.mainRoute[0],
        [
          parseFloat(_vm.locationSnappedToFootPath.geometry.coordinates[0]),
          parseFloat(_vm.locationSnappedToFootPath.geometry.coordinates[1]),
        ],
      ],"routeColor":'#2979ff'}}):_vm._e(),(!_vm.AppStateStore.getAppLoading && _vm.mainRoute && _vm.navigating)?_c('Route',{attrs:{"route":_vm.mainRoute,"routeColor":'#2979ff'}}):_vm._e(),_c('MapFeature',{attrs:{"coordinates":_vm.getFeatureCoordinates(_vm.$root.location),"color":'#304fff',"featureRadius":10,"border":{ display: true, width: 2, color: 'white' }}}),(_vm.gravesToDisplay)?_c('v-autocomplete',{staticStyle:{"background-color":"white"},attrs:{"items":_vm.gravesToDisplay,"item-text":"person","filled":"","label":_vm.$t('search'),"hide-details":"","return-object":""},model:{value:(_vm.selectedGrave),callback:function ($$v) {_vm.selectedGrave=$$v},expression:"selectedGrave"}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary","ripple":"","disabled":!_vm.isNavigationReady,"block":""},on:{"click":function($event){return _vm.startInitPath()}}},[_vm._v(" "+_vm._s(_vm.$t("start_navigation"))+" ")]),(_vm.currentTab === 'map' && _vm.selectedGrave != null)?_c('BottomMenu',{attrs:{"marginBottom":'0px',"toggled":_vm.bottomMenuToggled,"grave":_vm.selectedGrave,"displayBottomMenu":_vm.currentTab === 'map' && _vm.selectedGrave != null},on:{"handleGraveReached":_vm.handleGraveReached,"toggle":_vm.toggleBottomMenu}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }