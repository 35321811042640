<template>
  <v-dialog
    v-model="displayDialogNav"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialogNav()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>

      <NavOnFoot :grave="grave" />
    </v-card>
  </v-dialog>
</template>

<script>
import NavOnFoot from "@components/navigation/NavOnFoot.vue";
export default {
  name: "DialogNav",
  props: {
    grave: Object,
    closeDialogNav: Function,
    displayDialogNav: Boolean,
  },
  components: { NavOnFoot },
  data() {
    return {};
  },
};
</script>
