<template>
  <!-- Openlayers map -->
  <div>
    <vl-map
      ref="map"
      data-projection="EPSG:4326"
      style="position: absolute; background-color: #868686"
      :move-tolerance="2"
      @singleclick="mapClick"
    >
      <!-- GPS syncronized view -->
      <vl-view
        :zoom.sync="mapProperties.zoom"
        :center.sync="mapProperties.center"
        :rotation.sync="mapProperties.rotation"
        :min-zoom="11"
        :max-zoom="22"
      ></vl-view>

      <!-- OSM raster layer-->
      <vl-layer-tile :z-index="0">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>
      <!--Orthofoto raster layer -->
      <vl-layer-tile :z-index="1">
        <vl-source-xyz :url="mapProperties.url"></vl-source-xyz>
      </vl-layer-tile>

      <!-- User on foot real location -->
      <MapFeature
        :coordinates="getFeatureCoordinates($root.location)"
        :color="'#304fff'"
        :featureRadius="10"
        :border="{ display: true, width: 2, color: 'white' }"
      />

      <div>
        <vl-overlay
          :positioning="switchPositioning"
          :offset="[10, 0]"
          :position="[
            parseFloat($root.location.geometry.coordinates[0]),
            parseFloat($root.location.geometry.coordinates[1]),
          ]"
        >
          <v-btn x-small @click="switchDisplayMode()">
            <v-icon v-if="!displayAll" x-medium>toggle_on</v-icon>
            <v-icon v-else x-medium>toggle_off</v-icon>
          </v-btn>
        </vl-overlay>
      </div>

      <MapFeatureCollection :features="gravesToDisplay" />
    </vl-map>
  </div>
</template>

<script>
import Geolocation from "ol/Geolocation.js";

import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { DashboardTab } from "@utils/constants";

import MapFeature from "@components/map/MapFeature.vue";
import MapFeatureCollection from "@components/map/MapFeatureCollection.vue";

import {
  toFeature,
  getFeatureCoordinates,
  closestFeature,
  getDistanceInMeters,
} from "@utils/utilFunctions";

export default {
  name: "Map",
  props: {
    lastMapProperties: Object,
  },
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  components: {
    MapFeature,
    MapFeatureCollection,
  },
  data() {
    return {
      DashboardTab: DashboardTab,
      mapProperties: {
        zoom: 17,
        center: [10.92623, 48.37976],
        rotation: 0,
        url: "/tiles/{z}/{x}/{y}.jpg",
      },
      geolocation: "",
      gravesToDisplay: [],
      overlay: true,
      switchPositioning: "bottom-left",
      displayAll: false,
    };
  },
  methods: {
    toFeature,
    getDistanceInMeters,
    getFeatureCoordinates,
    closestFeature,
    async switchDisplayMode() {
      if (!this.displayAll) await this.getMapGravesToDisplayAll();
      else await this.getMapGravesToDisplay();
      this.displayAll = !this.displayAll;
    },
    async getMapGravesToDisplay() {
      var formData = new FormData();
      formData.set("getMapGravesToDisplay", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set("user_id", this.UserStore.user.id);

      try {
        const response = await this.$root.postData(formData);
        this.gravesToDisplay = response;

        this.gravesToDisplay.forEach((grave) => {
          grave.geometry.coordinates[0] = parseFloat(
            grave.geometry.coordinates[0]
          );
          grave.geometry.coordinates[1] = parseFloat(
            grave.geometry.coordinates[1]
          );
        });
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async getMapGravesToDisplayAll() {
      var formData = new FormData();
      formData.set("getMapGravesToDisplayAll", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.gravesToDisplay = response;

        this.gravesToDisplay.forEach((grave) => {
          grave.geometry.coordinates[0] = parseFloat(
            grave.geometry.coordinates[0]
          );
          grave.geometry.coordinates[1] = parseFloat(
            grave.geometry.coordinates[1]
          );
        });
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    mapClick(event) {
      const clickpos = event.coordinate;
      const clicked = this.closestFeature(clickpos, this.gravesToDisplay);
      if (clicked) {
        this.$emit("graveSelected", {
          grave: {
            id: clicked.id,
            startTask: false,
            taskId: null,
          },
          mapState: {
            zoom: this.mapProperties.zoom,
            center: this.mapProperties.center,
            rotation: this.mapProperties.rotation,
          },
        });
      }
    },
    initGeolocation() {
      let self = this;

      this.geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      });
      // Location listener
      this.geolocation.on("change:position", function () {
        self.$root.location = self.toFeature(self.geolocation.getPosition());
      });
    },
  },
  async mounted() {
    this.initGeolocation();
    await this.getMapGravesToDisplay();
    if (this.lastMapProperties.active) {
      const { zoom, rotation, center } = this.lastMapProperties;
      this.mapProperties = { ...this.mapProperties, zoom, rotation, center };
    }

    this.AppStateStore.switchTabTo(DashboardTab.MAP);
  },
  async destroyed() {
    this.geolocation.setTracking(false);
  },
};
</script>
