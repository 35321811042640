<template>
  <v-container fluid class="mt-6 pa-0" id="gravePage">
    <v-layout row justify-center>
      <v-flex xs12>
        <GraveImage :key="Date.now()" :graveId="grave.id" />

        <span class="subheading ml-5">{{ $t("grave_info") }}</span>

        <GraveTextData :grave="grave" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import GraveImage from "@components/grave/GraveImage.vue";
import GraveTextData from "@components/grave/GraveTextData.vue";

export default {
  name: "GuestGrave",
  props: {
    grave: Object,
  },
  components: { GraveImage, GraveTextData },
};
</script>
