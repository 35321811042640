<template>
  <v-container fluid class="pt-6">
    <v-select
      v-model="navigationType"
      :items="navigationTypes"
      :item-text="
        (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
      "
      item-value="id"
      :placeholder="$t('selectType')"
      :label="$t('navigation_type')"
      :error="typeError"
    ></v-select>
    <v-select
      v-if="
        NavStateStore.getNavigationType === NavigationType.ONFOOT ||
        NavStateStore.getNavigationType === NavigationType.ONFOOTWITHLOAD
      "
      v-model="routePlanning"
      :items="routePlannings"
      :item-text="
        (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
      "
      return-object
      :label="$t('route_planning')"
    ></v-select>
    <v-slider
      v-model="radius"
      thumb-label="always"
      min="20"
      max="100"
      label="Radius:"
      thumb-size="20"
    ></v-slider>
    <h4 class="title grey--text pa-3">{{ $t("current_tasks") }}</h4>
    <v-divider />
    <v-list>
      <template v-for="(taskgroup, index) in taskgroups">
        <v-list-item :key="taskgroup.name_de" ripple>
          <v-list-item-action>
            <v-icon
              @click="
                $emit('tabToTasksWithToolbar', {
                  subtitle: $root.isLanguageGerman
                    ? taskgroup.name_de
                    : taskgroup.name_en,
                  graveyardId: taskgroup.graveyard_id,
                  taskTypeId: taskgroup.task_type_id,
                })
              "
              x-large
              color="black"
            >
              details
            </v-icon>
          </v-list-item-action>
          <v-list-item-content @click="startNav(taskgroup)">
            <v-list-item-title>
              {{
                $root.isLanguageGerman ? taskgroup.name_de : taskgroup.name_en
              }}
            </v-list-item-title>
            <template v-if="taskgroup.count">
              <v-list-item-subtitle>
                {{
                  taskgroup.count +
                  " " +
                  (taskgroup.count > 1 ? $t("graves") : $t("grave"))
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="
                  isTaskGroupActive(
                    taskgroup.task_type_id,
                    taskgroup.graveyard_id
                  )
                "
                class="green--text"
              >
                {{ $t("in_progress") }}
              </v-list-item-subtitle>
            </template>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon
              v-if="
                isTaskGroupActive(
                  taskgroup.task_type_id,
                  taskgroup.graveyard_id
                )
              "
              large
              color="red"
            >
              my_location
            </v-icon>
          </v-list-item-action>

          <v-list-item-action>
            <v-icon v-if="taskgroup.done" large color="green">done</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="index"></v-divider>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import { useAppBarStore } from "@store/appBar.js";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { useNavStateStore } from "@store/navState.js";
import {
  NavigationType,
  NavigationTypes,
  RoutePlannings,
} from "@utils/constants";

export default {
  name: "Taskgroups",
  props: {},
  setup() {
    const AppBarStore = useAppBarStore();
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    const NavStateStore = useNavStateStore();

    return { AppBarStore, AppStateStore, UserStore, NavStateStore };
  },
  data() {
    return {
      NavigationType: NavigationType,
      NavigationTypes: NavigationTypes,
      RoutePlannings: RoutePlannings,
      taskgroups: [],
      navigationTypes: NavigationTypes,
      routePlannings: RoutePlannings,
      typeError: false,
    };
  },
  computed: {
    radius: {
      get() {
        return this.NavStateStore.radius;
      },
      set(radius) {
        this.NavStateStore.setRadius(radius);
      },
    },
    routePlanning: {
      get() {
        return this.NavStateStore.routePlanning;
      },
      set(routePlanning) {
        this.NavStateStore.setRoutePlanning(routePlanning);
      },
    },

    navigationType: {
      get() {
        return this.NavStateStore.navigationType;
      },
      set(navigationType) {
        this.NavStateStore.setNavigationType(navigationType);
      },
    },
  },
  methods: {
    async getTaskGroups() {
      let formData = new FormData();
      formData.set("getTaskGroups", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set("user_id", this.UserStore.user.id);

      try {
        const response = await this.$root.postData(formData);
        this.taskgroups = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    isTaskGroupActive(taskType, graveyard) {
      return (
        this.NavStateStore.getActiveTask.taskType === taskType &&
        this.NavStateStore.getActiveTask.graveyardId === graveyard
      );
    },
    startNav(taskgroup) {
      this.NavStateStore.resetTaskgroup();
      if (this.NavStateStore.getNavigationType) {
        this.typeError = false;

        this.AppBarStore.setAppBarSubtitle(
          this.$root.isLanguageGerman ? taskgroup.name_de : taskgroup.name_en
        );
        this.$emit("setActiveToNavigation", {
          graveyardId: taskgroup.graveyard_id,
          taskTypeId: taskgroup.task_type_id,
        });
      } else {
        this.typeError = true;
      }
    },
  },
  mounted() {
    this.getTaskGroups();
  },
};
</script>
