<template>
  <v-data-table
    :id="id"
    v-show="show"
    :headers="headers"
    :items="completedTasks"
    :search="search"
    dense
    show-expand
    :items-per-page="itemsPerPage"
    :diable-pagination="disablePagination"
    :hide-default-footer="hideFooter"
    :footer-props="footerProps"
    :expanded="getExpanded"
  >
    <template #item.graveyard="{ item }">{{ item.graveyard }}</template>
    <template #item.grave_number="{ item }">
      {{ item.grave_number }}
    </template>
    <template #item.ord1="{ item }">{{ item.ord1 }}</template>
    <template #item.ord2="{ item }">{{ item.ord2 }}</template>
    <template #item.ord3="{ item }">{{ item.ord3 }}</template>
    <template #item.ord4="{ item }">{{ item.ord4 }}</template>
    <template #item.person="{ item }">
      {{ item.person }}
    </template>
    <template v-if="$root.isLanguageGerman" #item.task_type="{ item }">
      {{ item.task_type_de }}
    </template>
    <template v-else #item.task_type="{ item }">
      {{ item.task_type_en }}
    </template>
    <template #item.description="{ item }">
      {{ item.description }}
    </template>
    <template #item.info="{ item }">
      {{ item.info }}
    </template>
    <template #item.user="{ item }">{{ item.user }}</template>
    <template #item.completed="{ item }">
      {{ getFormattedDate(item.completed) }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" v-if="item.task_type_de == 'Pflanzen'">
        <div class="my-5">
          <template v-for="(item, index) in item.items">
            <div :key="item.id + item.name + item.quantity + index">
              {{ item.name }}
              :
              {{ item.quantity }}
              {{ item.description ? ", " + item.description : "" }}
            </div>
          </template>
        </div>
      </td>
    </template>
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} /
      {{ items.itemsLength }}
    </template>
    <template slot="no-results">
      {{ $t("no_results") }}
    </template>
    <template v-slot:no-data>
      {{ $t("no_data_text") }}
    </template>
  </v-data-table>
</template>

<script>
import { getFormattedDate } from "@utils/utilFunctions";
export default {
  name: "CompletedTasksTable",
  props: {
    id: {
      type: String,
      default: "",
    },
    completedTasks: Array,
    search: String,
    show: Boolean,
    ordHeaders: Boolean,
    itemsPerPage: {
      type: Number,
      default: -1,
    },
    itemsPerPageOptions: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    intervalStart: String,
    intervalEnd: String,
  },
  data() {
    return {
      expanded: [],
    };
  },
  computed: {
    getTaskTypeValue() {
      if (this.$root.isLanguageGerman) return "task_type_de";
      return "task_type_en";
    },
    getExpanded() {
      return this.disablePagination ? this.completedTasks : this.expanded;
    },
    footerProps() {
      if (this.itemsPerPageOptions)
        return {
          itemsPerPageText: "",
          itemsPerPageAllText: this.$t("all"),
          "items-per-page-options": [10, 100, 200, 500, -1],
        };
      else return {};
    },
    headers() {
      let headers = [
        { text: this.$t("graveyard"), value: "graveyard", sortable: true },
        {
          text: this.$t("grave_number"),
          value: "grave_number",
          sortable: true,
        },
        { text: this.$t("person"), value: "person" },
        {
          text: this.$t("task_type"),
          value: this.getTaskTypeValue,
          sortable: false,
        },
        { text: this.$t("description"), value: "description", sortable: true },
        { text: this.$t("special"), value: "info", sortable: true },
        { text: this.$t("employee"), value: "user", sortable: true },
        {
          text: this.$t("completed_on"),
          value: "completed",
          filter: (value) => {
            if (!this.intervalStart || !this.intervalEnd) return true;
            return this.$moment(value).isBetween(
              this.intervalStart,
              this.intervalEnd,
              "day",
              "[]"
            );
          },
          sortable: true,
        },
      ];

      const insertAt = 2;

      if (this.ordHeaders) {
        const ordHeaders = [
          { text: "Ord1", value: "ord1", sortable: true },
          { text: "Ord2", value: "ord2", sortable: true },
          { text: "Ord3", value: "ord3", sortable: true },
          { text: "Ord4", value: "ord4", sortable: true },
        ];

        headers.splice(insertAt, 0, ...ordHeaders);
      }
      return headers;
    },
  },
  methods: {
    getFormattedDate,
  },
};
</script>
