<template>
  <v-container grid-list-xl fluid>
    <v-layout wrap>
      <v-flex xs12 class="py-0">
        <v-menu
          v-model="datePickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="computedDateFormatted"
              :label="$t('date')"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="task.date"
            no-title
            @input="datePickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 class="py-0">
        <v-select
          v-model="task.task_type"
          :items="lists.task_types"
          :item-text="
            (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
          "
          item-value="id"
          return-object
          :label="$t('task_type')"
          required
          :no-data-text="$t('no_data_text')"
        ></v-select>
      </v-flex>

      <v-flex class="my-1 mx-4" v-if="task.task_type.id === TaskType.PFLANZEN">
        <ItemTable :items="task.items" @actionPerformed="addItem"></ItemTable>
      </v-flex>

      <v-flex xs12 class="py-0">
        <v-text-field
          type="number"
          v-model="task.duration"
          :rules="[rules.number]"
          :label="$t('duration')"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 class="py-0">
        <v-select
          v-model="task.selectedTeams"
          :items="lists.teams"
          item-text="name"
          :label="$t('team')"
          return-object
          chips
          multiple
          required
          :no-data-text="$t('no_data_text')"
        ></v-select>
      </v-flex>
      <template v-if="graveSelection">
        <v-flex xs12 class="py-0">
          <v-select
            v-model="task.grave_type"
            :items="lists.grave_types"
            :item-text="
              (item) => ($root.isLanguageGerman ? item.name_de : item.name_en)
            "
            return-object
            clearable
            :label="$t('grave_type')"
            required
            :no-data-text="$t('no_data_text')"
          ></v-select>
        </v-flex>
        <v-flex xs12 class="py-0">
          <v-select
            v-model="task.grave_selected"
            :items="filteredGraves"
            item-text="number"
            item-value="id"
            return-object
            :label="$t('grave')"
            required
            :no-data-text="$t('no_data_text')"
          ></v-select>
        </v-flex>
      </template>

      <v-textarea
        v-model="task.description"
        :hint="$t('task_description')"
      ></v-textarea>
    </v-layout>
  </v-container>
</template>

<script>
import ItemTable from "@components/tables/ItemTable.vue";
import { TaskType } from "@utils/constants";
import { getFormattedDate } from "@utils/utilFunctions";

export default {
  name: "TaskForm",
  props: {
    task: Object,
    graveSelection: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ItemTable,
  },
  data() {
    return {
      TaskType: TaskType,
      datePickerMenu: false,
      lists: {
        task_types: [],
        teams: [],
        grave_types: [],
        graves: [],
      },
      rules: {
        number: (value) =>
          /^(?:[1-9]\d*|0)$/.test(value) || "Only numbers, can't start with 0",
      },
    };
  },
  computed: {
    computedDateFormatted() {
      return getFormattedDate(this.task.date);
    },
    filteredGraves() {
      if (this.task.grave_type) {
        let self = this;
        return this.lists.graves.filter(
          (item) => item.type1_id == self.task.grave_type.id
        );
      }
      return this.lists.graves;
    },
  },
  methods: {
    getFormattedDate,
    addItem(eventData) {
      if (eventData.type === "addItemToTask")
        this.task.items.push(eventData.item);
    },
    async getLists() {
      const lists = await this.$root.getLists();

      this.lists.task_types = lists["task_types"];
      this.lists.teams = lists["teams"];
      this.lists.grave_types = lists["grave_types"];
      this.lists.graves = lists["graves"];
    },
  },
  async mounted() {
    await this.getLists();
  },
};
</script>
