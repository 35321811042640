<template>
  <v-container style="max-width: 500px">
    <v-card class="elevation-5">
      <v-list-item>
        <v-list-item-content
          :style="{ 'align-items': 'center', 'min-width': '80%' }"
        >
          <div class="title font-weight-medium">
            {{
              team.name +
              ": " +
              ($root.isLanguageGerman ? team.task_type_de : team.task_type_en)
            }}
          </div>
        </v-list-item-content>

        <v-spacer></v-spacer>

        <v-list-item-action>
          <v-btn fab small right @click="$emit('deleteTeamClicked', team.id)">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <template v-for="(user, userIndex) in team.users">
        <v-divider
          v-if="userIndex !== 0"
          :key="`${userIndex}-divider`"
        ></v-divider>

        <v-list-item :key="'user' + userIndex">
          <v-list-item-avatar>
            <v-img :src="getSrc('user.png')"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ user.firstname + " " + user.lastname }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="user.leader">
              {{ $t("teamleader") }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-spacer></v-spacer>
          <v-list-item-action>
            <v-btn
              fab
              dark
              small
              color="red"
              right
              @click="deleteTeamMember(user.id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <template>
        <v-divider></v-divider>

        <v-list-item>
          <v-spacer></v-spacer>
          <v-list-item-content :style="{ 'align-items': 'end' }">
            <div class="title font-weight-medium" style="align-self: right">
              {{ $t("add") }}
            </div>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn small fab dark color="blue" @click="openTeamMemberDialog">
              <v-icon dark v-text="`add`" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-card>

    <v-layout row justify-center>
      <NewTeamMemberDialog
        v-if="teamMemberDialog"
        @teamMemberAdded="teamMemberAdded"
        @cancel="closeTeamMemberDialog"
        :teamId="team.id"
      />
    </v-layout>
  </v-container>
</template>

<script>
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";
import NewTeamMemberDialog from "@components/teams/NewTeamMemberDialog.vue";
export default {
  name: "Team",
  components: {
    NewTeamMemberDialog,
  },
  props: {
    team: Object,
  },
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  data() {
    return {
      teamMemberDialog: false,
    };
  },

  methods: {
    getSrc(name) {
      let images = require.context("@assets/", false, /\.png$/);
      return images("./" + name);
    },
    openTeamMemberDialog() {
      this.teamMemberDialog = true;
    },
    closeTeamMemberDialog() {
      this.teamMemberDialog = false;
    },
    teamMemberAdded() {
      this.closeTeamMemberDialog();
      this.$emit("teamMemberAdded");
    },
    async deleteTeamMember(userId) {
      let formData = new FormData();
      formData.set("deleteTeamMember", true);
      formData.set("team_id", this.team.id);
      formData.set("user_id", userId);

      await this.$root.postData(formData);

      this.$emit("teamMemberDeleted");
    },
  },
  mounted() {},
};
</script>
