<template>
  <vl-map
    ref="map"
    data-projection="EPSG:4326"
    style="position: absolute; background-color: #868686"
    :move-tolerance="2"
  >
    <!-- GPS syncronized view -->
    <vl-view
      :zoom.sync="zoom"
      :center.sync="center"
      :rotation.sync="rotation"
      :min-zoom="1"
      :max-zoom="23"
    ></vl-view>

    <!-- OSM raster layer-->
    <vl-layer-tile :z-index="0">
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>
    <!--Orthofoto raster layer -->
    <vl-layer-tile :z-index="1">
      <vl-source-xyz :url="url"></vl-source-xyz>
    </vl-layer-tile>

    <!-- Foot route padding  -->
    <Route
      v-if="!AppStateStore.getAppLoading && locationSnappedToFootPath.geometry"
      :route="[
        mainRoute[0],
        [
          parseFloat(locationSnappedToFootPath.geometry.coordinates[0]),
          parseFloat(locationSnappedToFootPath.geometry.coordinates[1]),
        ],
      ]"
      :routeColor="'#2979ff'"
    />

    <!-- Path from the user to the closest point on the closest route -->
    <Route
      v-if="
        !AppStateStore.getAppLoading &&
        $root.location &&
        locationSnappedToFootPath.geometry
      "
      :route="[
        getFeatureCoordinates($root.location),
        [
          parseFloat(locationSnappedToFootPath.geometry.coordinates[0]),
          parseFloat(locationSnappedToFootPath.geometry.coordinates[1]),
        ],
      ]"
      :routeColor="'#2979ff'"
      :width="6"
      :lineDash="{ display: true, distances: [5, 10] }"
    />

    <!-- Route to the next grave -->

    <Route
      v-if="!AppStateStore.getAppLoading && mainRoute"
      :route="mainRoute"
      :routeColor="'#2979ff'"
    />

    <!-- User on foot real location -->
    <vl-layer-vector v-if="$root.location">
      <MapFeature
        :coordinates="getFeatureCoordinates($root.location)"
        :color="'#304fff'"
        :featureRadius="10"
        :border="{ display: true, width: 2, color: 'white' }"
      />
    </vl-layer-vector>

    <!-- Next grave -->
    <MapFeature
      v-if="grave"
      :coordinates="[parseFloat(this.grave.lon), parseFloat(this.grave.lat)]"
      :color="'#76FF03'"
    />

    <!--  Bottom Menu -->

    <BottomMenu
      :marginBottom="'56px'"
      @toggle="toggleBottomMenu"
      :toggled="bottomMenuToggled"
      :grave="grave"
      :displayBottomMenu="true"
    />
  </vl-map>
</template>

<script>
import Geolocation from "ol/Geolocation.js";
import { useAppStateStore } from "@store/appState.js";
import { useRouteStore } from "@store/route.js";
import BottomMenu from "@components/navigation/BottomMenu.vue";
import MapFeature from "@components/map/MapFeature.vue";
import Route from "@components/navigation/Route";

import {
  toFeature,
  getFeatureCoordinates,
  getDistance,
  getDistanceInMeters,
  getNearestPointOnLine,
  isEmpty,
} from "@utils/utilFunctions";

export default {
  name: "NavOnFoot",
  props: {
    grave: Object,
  },
  components: { BottomMenu, MapFeature, Route },
  setup() {
    const AppStateStore = useAppStateStore();
    const RouteStore = useRouteStore();
    return { AppStateStore, RouteStore };
  },
  data() {
    return {
      bottomMenuToggled: false,
      zoom: 22,
      center: [],
      rotation: 0,
      geolocation: "",
      url: "/tiles/{z}/{x}/{y}.jpg",
      locationOnPath: "",
      locationSnappedToFootPath: "",
      closestFootRouteSegment: "",
      mainRoute: "",
    };
  },
  methods: {
    toFeature,
    getFeatureCoordinates,
    getDistance,
    getDistanceInMeters,
    getNearestPointOnLine,
    isEmpty,
    toggleBottomMenu() {
      this.bottomMenuToggled = !this.bottomMenuToggled;
    },
    navigate() {
      this.updateFootPathToGrave();
    },
    updateFootPathToGrave() {
      this.mainRoute = this.RouteStore.getFootPathFinder.findPath(
        this.locationSnappedToFootPath,
        toFeature([this.grave.lon, this.grave.lat])
      ).path;
    },
    updateLocationSnappedToFootPath() {
      let closestPoint = this.toFeature(
        this.closestFootRouteSegment.geometry.coordinates[1]
      );

      if (this.closestFootRouteSegment.geometry.coordinates.length >= 2) {
        if (
          this.getDistanceInMeters(
            this.$root.location,
            this.toFeature(this.closestFootRouteSegment.geometry.coordinates[0])
          ) <
          this.getDistanceInMeters(
            this.$root.location,
            this.toFeature(this.closestFootRouteSegment.geometry.coordinates[1])
          )
        )
          closestPoint = this.toFeature(
            this.closestFootRouteSegment.geometry.coordinates[0]
          );
        else
          closestPoint = this.toFeature(
            this.closestFootRouteSegment.geometry.coordinates[1]
          );
      }

      this.locationSnappedToFootPath = JSON.parse(JSON.stringify(closestPoint));
    },
    updateLocationOnPath() {
      this.closestFootRouteSegment = JSON.parse(
        JSON.stringify(this.RouteStore.getFootRoute.features[0])
      );

      let minDistance = this.getDistance(
        this.$root.location,
        this.closestFootRouteSegment.geometry.coordinates[0]
      );

      this.RouteStore.getFootRoute.features.forEach((line) => {
        line.geometry.coordinates.forEach((lineSegmentCoord) => {
          let distance = this.getDistance(
            this.$root.location,
            lineSegmentCoord
          );
          if (distance < minDistance) {
            minDistance = distance;
            this.closestFootRouteSegment = JSON.parse(JSON.stringify(line));
          }
        });
      });

      this.locationOnPath = this.getNearestPointOnLine(
        this.closestFootRouteSegment,
        this.$root.location
      );
    },
    calculateLocationOnPath() {
      this.updateLocationOnPath();
      this.updateLocationSnappedToFootPath();
    },

    async startInitPath() {
      await this.InitPath();
    },

    InitPath() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.calculateLocationOnPath();
          this.navigate();
          resolve();
        }, 100);
      });
    },
    initGeolocation() {
      this.geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      });

      window.addEventListener(
        "deviceorientationabsolute",
        this.manageCompass,
        true
      );
    },
    manageCompass(event) {
      if (event.webkitCompassHeading) {
        this.rotation = this.degrees_to_radians(event.webkitCompassHeading);
      } else {
        this.rotation = this.degrees_to_radians(event.alpha);
      }
    },
    degrees_to_radians(degrees) {
      let pi = Math.PI;
      return degrees * (pi / 180);
    },
  },
  async mounted() {
    this.initGeolocation();
    this.center = getFeatureCoordinates(this.$root.location);
    this.InitPath();
  },
  async destroyed() {
    this.geolocation.setTracking(false);
  },
};
</script>
