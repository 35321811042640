<template>
  <v-card class="mx-auto" max-width="344" outlined shaped>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">{{ $t("current_user") }}</div>
        <v-list-item-title class="headline mb-1">
          {{ UserStore.user.fullname }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{
            $root.isLanguageGerman
              ? UserStore.user.role.name_de
              : UserStore.user.role.name_en
          }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-img :src="getSrc"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        v-if="userWorkStatus.start === null && !userWorkStatus.done_today"
        @click="updateWorkStatus('startWork')"
      >
        {{ $t("hours.startWork") }}
      </v-btn>

      <v-btn
        v-if="userWorkStatus.start !== null && !userWorkStatus.done_today"
        @click="updateWorkStatus('endWork')"
      >
        {{ $t("hours.endWork") }}
      </v-btn>

      <v-btn
        v-if="
          userWorkStatus.start !== null &&
          userWorkStatus.break_start === null &&
          !userWorkStatus.done_today
        "
        @click="updateWorkStatus('startBreak')"
      >
        {{ $t("hours.startBreak") }}
      </v-btn>
      <v-btn
        v-if="
          userWorkStatus.start !== null &&
          userWorkStatus.break_start !== null &&
          !userWorkStatus.done_today
        "
        @click="updateWorkStatus('endBreak')"
      >
        {{ $t("hours.endBreak") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
export default {
  name: "UserWorkStatus",
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  data() {
    return {
      userWorkStatus: "",
    };
  },
  computed: {
    getSrc() {
      let images = require.context("@assets/", false, /\.png$/);
      return images("./user.png");
    },
  },
  methods: {
    async getUserWorkStatus() {
      const formData = new FormData();
      formData.set("getUserWorkStatus", true);
      formData.set("user_id", this.UserStore.user.id);

      try {
        const response = await this.$root.postData(formData);
        this.userWorkStatus = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async updateWorkStatus(action) {
      const formData = new FormData();
      formData.set(action, true);
      formData.set("user_id", this.UserStore.user.id);

      await this.$root.postData(formData);
      this.$emit("workStatusUpdated");
      await this.getUserWorkStatus();
    },
  },
  mounted() {
    this.getUserWorkStatus();
  },
};
</script>
