<template>
  <div style="padding-top: 16px; background-color: #868686">
    <!-- Openlayers map -->
    <vl-map
      v-if="
        AppStateStore.getCurrentTab === DashboardTab.NAVIGATION &&
        NavStateStore.isNavActive
      "
      ref="map"
      data-projection="EPSG:4326"
      style="position: absolute; background-color: #868686"
      :move-tolerance="2"
    >
      <!-- GPS syncronized view -->
      <vl-view
        :zoom.sync="mapProperties.zoom"
        :center.sync="mapProperties.center"
        :rotation.sync="mapProperties.rotation"
        :min-zoom="11"
        :max-zoom="22"
      ></vl-view>

      <!-- Orthofoto raster layer-->

      <vl-layer-tile :z-index="1">
        <vl-source-xyz :url="mapProperties.url"></vl-source-xyz>
      </vl-layer-tile>
      <!-- Next grave  -->
      <MapFeature
        v-if="
          !isEmpty(nextGrave) && !NavStateStore.onFootToCarStop && mapLoaded
        "
        :coordinates="[
          parseFloat(nextGrave.geometry.coordinates[0]),
          parseFloat(nextGrave.geometry.coordinates[1]),
        ]"
        :color="'#76FF03'"
      />

      <!-- Graves in the current cluster  -->
      <vl-layer-vector
        v-if="mapLoaded && NavStateStore.getTaskGroupInRadius.length"
      >
        <MapFeature
          v-for="(grave, index) in NavStateStore.getTaskGroupInRadius"
          :id="grave.task.id"
          :key="grave.task.id"
          :coordinates="[
            parseFloat(grave.geometry.coordinates[0]),
            parseFloat(grave.geometry.coordinates[1]),
          ]"
          :color="'#FF9800'"
          :zIndex="9998"
          :text="{ display: true, index: String(index) }"
        />
      </vl-layer-vector>

      <!-- Car route to the next grave -->
      <Route
        v-if="
          mapLoaded && calculatedPaths.carPath && NavStateStore.inCarToCarStop
        "
        :route="calculatedPaths.carPath"
        :routeColor="routeColor"
      />

      <!-- Closest point to grave on car route   -->
      <vl-layer-vector v-if="mapLoaded && routeLocation.carStop">
        <MapFeature
          :coordinates="[
            parseFloat(routeLocation.carStop.geometry.coordinates[0]),
            parseFloat(routeLocation.carStop.geometry.coordinates[1]),
          ]"
          :color="'#ba000d'"
          :border="{ display: true, width: 2, color: 'white' }"
        />
      </vl-layer-vector>

      <!-- Route to the next grave or back to the car stop  -->
      <Route
        v-if="
          mapLoaded &&
          calculatedPaths.footPath &&
          NavStateStore.onFootOrBackToCar
        "
        :route="calculatedPaths.footPath"
        :routeColor="routeColor"
      />

      <!-- Path from the user to the closest point on the closest route -->

      <Route
        v-if="
          mapLoaded &&
          routeLocation.closestPointOnFootRoute &&
          calculatedPaths.footPath &&
          NavStateStore.onFootOrBackToCar
        "
        :route="[
          getFeatureCoordinates($root.location),
          [
            parseFloat(
              routeLocation.closestPointOnFootRoute.geometry.coordinates[0]
            ),
            parseFloat(
              routeLocation.closestPointOnFootRoute.geometry.coordinates[1]
            ),
          ],
        ]"
        :routeColor="routeColor"
        :width="6"
        :lineDash="{ display: true, distances: [5, 10] }"
      />

      <!-- Car route padding  -->

      <Route
        v-if="
          mapLoaded &&
          routeLocation.closestPointOnCarRoute &&
          calculatedPaths.carPath &&
          NavStateStore.inCarToCarStop
        "
        :route="[
          calculatedPaths.carPath[0],
          [
            parseFloat(
              routeLocation.closestPointOnCarRoute.geometry.coordinates[0]
            ),
            parseFloat(
              routeLocation.closestPointOnCarRoute.geometry.coordinates[1]
            ),
          ],
        ]"
        :routeColor="routeColor"
      />

      <!-- Foot route padding  -->

      <Route
        v-if="
          mapLoaded &&
          routeLocation.closestPointOnFootRoute.geometry &&
          calculatedPaths.footPath &&
          NavStateStore.onFootOrBackToCar
        "
        :route="[
          calculatedPaths.footPath[0],
          [
            parseFloat(
              routeLocation.closestPointOnFootRoute.geometry.coordinates[0]
            ),
            parseFloat(
              routeLocation.closestPointOnFootRoute.geometry.coordinates[1]
            ),
          ],
        ]"
        :routeColor="routeColor"
      />

      <!-- User on foot real location -->
      <vl-layer-vector v-if="mapLoaded && !NavStateStore.inCarToCarStop">
        <MapFeature
          :coordinates="getFeatureCoordinates($root.location)"
          :color="'#304fff'"
          :featureRadius="10"
          :border="{ display: true, width: 2, color: 'white' }"
        />
      </vl-layer-vector>

      <!-- Car User real location -->
      <vl-layer-vector
        v-if="
          mapLoaded &&
          NavStateStore.inCarToCarStop &&
          routeLocation.closestPointOnCarRoute.geometry
        "
      >
        <MapFeature
          :coordinates="
            getFeatureCoordinates(routeLocation.closestPointOnCarRoute)
          "
          :color="'#304fff'"
          :featureRadius="10"
          :border="{ display: true, width: 2, color: 'white' }"
        />
      </vl-layer-vector>

      <!-- Bottom Menu -->

      <BottomMenu
        v-if="!isEmpty(nextGrave) || NavStateStore.onFootToCarStop"
        :marginBottom="'56px'"
        @handleGraveReached="handleGraveReached"
        @toggle="toggleBottomMenu"
        @recalculateTaskGroup="recalculateTaskGroup"
        @recalculateTask="recalculateTask"
        :toggled="isBottomMenuToggled"
        :grave="nextGrave"
        :openGraveButton="true"
        :displayBottomMenu="
          !isEmpty(nextGrave) || NavStateStore.onFootToCarStop
        "
        :showOverrideButton="
          NavStateStore.onFootToCarStop || NavStateStore.inCarToCarStop
        "
        :isToggleDisabled="!NavStateStore.onFootNavType && withTeamleader"
        :showRecalculateButtonsCar="
          NavStateStore.inCarToCarStop || NavStateStore.withCarNavType
        "
        :showRecalculateButtonOnFoot="NavStateStore.onFootNavType"
      />

      <!-- ETC -->

      <v-bottom-sheet persistent v-model="location.searching">
        <v-sheet
          class="text-center"
          height="60px"
          style="z-index: 2; margin-bottom: 56px"
        >
          <v-progress-linear indeterminate color="blue"></v-progress-linear>
          <div class="py-3">
            {{ locationMessage }}
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <v-layout row justify-center>
        <v-dialog v-model="taskgroupDoneDialog" persistent max-width="400">
          <v-btn :disabled="!workStatus.workStarted" @click="endWork()">
            {{ $t("finish_work") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="
              workStatus.doneToday ||
              workStatus.onBreak ||
              !workStatus.workStarted
            "
            @click="startBreak()"
          >
            {{ $t("break") }}
          </v-btn>
          <v-btn @click="doneSelectOtherTaskgroup()">
            {{ $t("select_other") }}
          </v-btn>
        </v-dialog>
      </v-layout>
    </vl-map>
  </div>
</template>

<script>
import Geolocation from "ol/Geolocation.js";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { useNavStateStore } from "@store/navState.js";
import { useRouteStore } from "@store/route.js";

import MapFeature from "@components/map/MapFeature";
import Route from "@components/navigation/Route";
import BottomMenu from "@components/navigation/BottomMenu";
import { NavigationMode, DashboardTab, TaskType } from "@utils/constants";
import {
  toFeature,
  getFeatureCoordinates,
  getDistance,
  getDistanceInMeters,
  getDistanceInMetersOnPath,
  getNearestPointOnLine,
  isEmpty,
} from "@utils/utilFunctions";

const ORANGE = "#ff9e22";
const BLUE = "#2979ff";
const GREEN = "#90ee02";
const RED = "#e53935";

export default {
  name: "Navigation",
  components: {
    MapFeature,
    Route,
    BottomMenu,
  },
  props: {
    tabToGraveWithToolbar: Function,
    getTasks: Function,
  },
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    const NavStateStore = useNavStateStore();
    const RouteStore = useRouteStore();
    return { AppStateStore, UserStore, NavStateStore, RouteStore };
  },
  data() {
    return {
      NavigationMode: NavigationMode,
      DashboardTab: DashboardTab,
      TaskType: TaskType,

      routeColor: ORANGE,
      geolocation: "",
      taskgroupDoneDialog: false,
      bottomMenuToggled: false,
      nextGrave: {},
      previousGraveOrd: null,
      currentOrdGroup: [],
      workStatus: {
        doneToday: false,
        workStarted: false,
        onBreak: false,
      },
      mapProperties: {
        zoom: 17,
        center: [10.92623, 48.37976],
        rotation: 0,
        url: "/tiles/{z}/{x}/{y}.jpg",
      },
      location: {
        searching: true,
        found: false,
        change: false,
        searchCount: 2,
      },
      routeLocation: {
        closestPointOnFootRoute: [],
        closestPointOnCarRoute: [],
        carStop: null,
        prevStop: null,
      },
      closestFootRouteSegment: "",
      closestCarRouteSegment: "",
      calculatedPaths: {
        footPath: "",
        carPath: "",
      },
      navControl: {
        carStopReset: false,
        sameOrdOverride: false,
      },
    };
  },

  computed: {
    locationMessage() {
      if (this.location.found) return this.$t("location_found");
      return this.$t("searching");
    },
    mapLoaded() {
      return this.location.found && !this.AppStateStore.getAppLoading;
    },
    withTeamleader() {
      return this.NavStateStore.getActiveTask.taskType === TaskType.PFLANZEN;
    },
    isBottomMenuToggled() {
      if (this.isEmpty(this.nextGrave)) return false;
      return this.bottomMenuToggled;
    },
  },
  methods: {
    toFeature,
    getFeatureCoordinates,
    getDistance,
    getDistanceInMeters,
    getDistanceInMetersOnPath,
    getNearestPointOnLine,
    isEmpty,

    toggleBottomMenu() {
      this.bottomMenuToggled = !this.bottomMenuToggled;
    },
    async recalculateTaskGroup(override) {
      this.AppStateStore.activateAppLoading();
      this.NavStateStore.setNavigationMode(NavigationMode.CAR);
      this.navControl.sameOrdOverride = override;
      this.routeLocation.prevStop = null;
      this.getRouteColor();
      await this.getTasks({ updateNavState: true });
      this.initializeNavigation();
    },
    async recalculateTask() {
      this.navControl.sameOrdOverride = true;
      this.initializeNavigation();
    },
    async endWork() {
      let formData = new FormData();
      formData.set("endWork", true);
      formData.set("user_id", this.UserStore.user.id);

      await this.$root.postData(formData);

      this.taskgroupDoneDialog = false;
      this.NavStateStore.stopNavigation();
      this.AppStateStore.switchTabTo(DashboardTab.MAP);
    },
    async startBreak() {
      let formData = new FormData();
      formData.set("startBreak", true);
      formData.set("user_id", this.UserStore.user.id);

      await this.$root.postData(formData);
      this.taskgroupDoneDialog = false;
      this.NavStateStore.stopNavigation();
      this.AppStateStore.switchTabTo(DashboardTab.MAP);
    },
    doneSelectOtherTaskgroup() {
      this.NavStateStore.stopNavigation();
      this.taskgroupDoneDialog = false;
      this.NavStateStore.stopNavigation();
      this.AppStateStore.switchTabTo(DashboardTab.TASKGROUPS);
    },
    async navToNextGrave(id) {
      if (this.NavStateStore.onFootNavType) {
        await this.continueOnFootNavigation();
      } else {
        this.NavStateStore.removeFromTaskGroupInRadius(id);
        await this.continueCarNavigation();
      }
    },
    async continueOnFootNavigation() {
      await this.getTasks({
        updateNavState: true,
      });
      await this.initializeNavigation();
    },
    async continueCarNavigation() {
      if (!this.NavStateStore.areTasksInRadiusCompleted) {
        await this.navigateToNextGraveInRadius();
      } else {
        await this.processTaskCompletion();
      }
    },
    async navigateToNextGraveInRadius() {
      this.nextGrave = this.NavStateStore.getTaskGroupInRadius[0];
      this.NavStateStore.setNavigationMode(NavigationMode.FOOT);
      this.updatePath();
    },

    async processTaskCompletion() {
      await this.getTasks({
        updateNavState: true,
      });
      if (this.NavStateStore.isTaskGroupCompleted) {
        this.completeTaskGroup();
      } else {
        this.radiusCompletedReturnToCar();
      }
    },
    radiusCompletedReturnToCar() {
      this.nextGrave = {};
      this.bottomMenuToggled = false;
      this.AppStateStore.createMessage("tasksInRadiusDone");
      this.NavStateStore.setNavigationMode(NavigationMode.BACKTOCAR);
    },

    async mapClick(event) {
      let newLocation = event.coordinate;

      this.$root.location = this.toFeature(newLocation);
      this.processNavigation();
    },
    calculateLocationOnPath() {
      if (this.NavStateStore.inCarToCarStop) {
        this.updateClosestRouteSegment("car");
        this.updateClosestPointOnRouteSegment(
          this.closestCarRouteSegment,
          "car"
        );
      } else if (this.NavStateStore.onFootOrBackToCar) {
        this.updateClosestRouteSegment("foot");
        this.updateClosestPointOnRouteSegment(
          this.closestFootRouteSegment,
          "foot"
        );
      }
    },

    updateClosestRouteSegment(type) {
      const route =
        type === "car"
          ? this.RouteStore.getCarRoute
          : this.RouteStore.getFootRoute;
      let closestSegment = JSON.parse(JSON.stringify(route.features[0]));
      let minDistance = Number.MAX_VALUE;

      route.features.forEach((feature) => {
        feature.geometry.coordinates.forEach((coord) => {
          const distance = this.getDistance(this.$root.location, coord);
          if (distance < minDistance) {
            minDistance = distance;
            closestSegment = JSON.parse(JSON.stringify(feature));
          }
        });
      });

      if (type === "car") {
        this.closestCarRouteSegment = closestSegment;
      } else if (type === "foot") {
        this.closestFootRouteSegment = closestSegment;
      } else {
        console.error(`Invalid path type: ${type}`);
      }
    },
    getGraveGroupByCar() {
      this.findClosestGraveOnCarRoute();
      this.findCarStopLocation();
      this.getGravesInFirstGraveRadius();
    },
    updatePath() {
      if (this.NavStateStore.inCarToCarStop) {
        this.updateCarPathToGrave();
      }
      if (this.NavStateStore.onFootToNextGrave) {
        this.updateFootPathToGrave();
      }
      if (this.NavStateStore.onFootToCarStop) {
        this.updateFootPathToCarStop();
      }
      this.getRouteColor();
    },
    updateCarPathToGrave() {
      this.calculatedPaths.carPath = this.RouteStore.getCarPathFinder.findPath(
        this.routeLocation.closestPointOnCarRoute,
        this.routeLocation.carStop
      ).path;
    },
    updateFootPathToGrave() {
      this.calculatedPaths.footPath =
        this.RouteStore.getFootPathFinder.findPath(
          this.routeLocation.closestPointOnFootRoute,
          this.nextGrave
        ).path;
    },
    updateFootPathToCarStop() {
      this.calculatedPaths.footPath =
        this.RouteStore.getFootPathFinder.findPath(
          this.routeLocation.closestPointOnFootRoute,
          this.routeLocation.carStop
        ).path;
    },
    calculateDisplacedPoint(coords, dx, dy) {
      return [coords[0] + dx, coords[1] + dy];
    },

    selectClosestPointOnPath(grave, pathFinder) {
      /*
      The best car stop point is not always on the path from the user location to the grave,
      therefore the stop point is calculated by creating 8 points from the grave in 8 directions
      with a distance of 20 meters. The closest point on the foot path is then found for each point,
      so that a path can be calculated from it to the grave. 



         N
   NW    |    NE
         |
  W----grave----E
         |
   SW    |    SE
         S
      */

      //The distances of the 8 points in the 8 directions from the grave from which to calculate paths to the grave

      const directions = [
        [0, -0.0002],
        [0, 0.0002],
        [0.0002, 0],
        [-0.00002, 0],
        [0.00002, -0.0002],
        [-0.0002, -0.0002],
        [0.0002, 0.0002],
        [-0.0002, 0.0002],
      ];

      let closestPoint = null;
      let minDistance = Number.MAX_VALUE;

      const carPathCoordinates = this.RouteStore.getCarRoute.features.flatMap(
        (feature) => feature.geometry.coordinates
      );

      directions.forEach(([dx, dy]) => {
        //Calculate the location of the point based on the distance from the grave
        const displacedPoint = this.calculateDisplacedPoint(
          grave.geometry.coordinates,
          dx,
          dy
        );

        //Find the closest point to the calculated location on the car route
        const displacedPointOnPath = this.findClosestPointOnRoute(
          displacedPoint,
          this.RouteStore.getCarRoute
        );

        //Calculate the path from the point
        const path = pathFinder.findPath(
          grave,
          this.toFeature(displacedPointOnPath)
        ).path;

        /*Calculate the closest stopping point near the grave on the car route by starting from the grave (the start of the path) and
          iterating through each coordinate pair on the foot path to check if it matches a coordinate pair of the car route.
          If it matches, this coordinate pair will be the closest point on the car path (at least on this path) to the grave.
        */
        path.forEach((footPathCoord) => {
          const matchingCarPathCoord = carPathCoordinates.find(
            (carCoord) =>
              carCoord[0] === footPathCoord[0] &&
              carCoord[1] === footPathCoord[1]
          );

          if (matchingCarPathCoord) {
            const distance = this.getDistanceInMetersOnPath(
              this.RouteStore.getFootPathFinder,
              this.toFeature(matchingCarPathCoord),
              grave
            );
            if (distance < minDistance) {
              minDistance = distance;
              closestPoint = this.toFeature(matchingCarPathCoord);
            }
          }
        });
      });

      //Return the closest stopping point calculated from these 8 paths
      return { point: closestPoint, distance: minDistance };
    },

    async findCarStopLocation() {
      const result = this.selectClosestPointOnPath(
        this.nextGrave,
        this.RouteStore.getFootPathFinder
      );

      this.routeLocation.carStop = result.point;

      this.routeLocation.prevStop = this.routeLocation.carStop;

      this.navControl.carStopReset = false;
    },

    findClosestPointOnRoute(point, route) {
      let minDistance = Number.MAX_VALUE;
      let closestPoint = null;
      route.features.forEach((feature) => {
        feature.geometry.coordinates.forEach((coord) => {
          const distance = this.getDistance(
            this.toFeature(point),
            this.toFeature(coord)
          );
          if (distance < minDistance) {
            minDistance = distance;
            closestPoint = coord;
          }
        });
      });
      return closestPoint;
    },

    getClosestGraveFrom(graves, point) {
      return graves.reduce((closest, grave) => {
        try {
          const pathResult = this.RouteStore.getFootPathFinder.findPath(
            grave,
            point
          );
          if (!pathResult || !pathResult.path)
            throw new Error(
              "Path result is undefined or missing path property"
            );
          return pathResult.weight < (closest.weight || Number.MAX_VALUE)
            ? { grave, weight: pathResult.weight }
            : closest;
        } catch (error) {
          console.error(
            "An error occurred during the selecttion of the closest grave:",
            error
          );
          return closest;
        }
      }, {}).grave;
    },

    sameOrdLeft(ord) {
      return this.NavStateStore.getTaskGroup.features.some(
        (grave) => grave.ord1 === ord
      );
    },

    async findClosestGraveOnCarRoute() {
      const selectNextGrave = (nextGraveOptions) => {
        this.nextGrave = this.getClosestGraveFrom(
          nextGraveOptions.gravesToSelectFrom,
          this.routeLocation.closestPointOnCarRoute
        );

        if (nextGraveOptions.setCurrentOrdGroup)
          this.currentOrdGroup =
            this.NavStateStore.getOrdGroups[this.nextGrave.ord1];
        if (nextGraveOptions.setPreviousOrd)
          this.previousGraveOrd = this.nextGrave.ord1;
        if (nextGraveOptions.unsetOverrideAndSetCarStopReset) {
          this.navControl.sameOrdOverride = false;
          this.navControl.carStopReset = true;
        }
      };
      const nextGraveOptions = {
        gravesToSelectFrom: this.NavStateStore.getTaskGroup.features,
        setCurrentOrdGroup: true,
        setPreviousOrd: true,
        unsetOverrideAndSetCarStopReset: true,
      };

      const noNextGraveOrOverriddenOrRandom =
        !this.nextGrave ||
        !this.previousGraveOrd ||
        !this.NavStateStore.getOrdGroups.hasOwnProperty(
          this.previousGraveOrd
        ) ||
        this.navControl.sameOrdOverride;

      if (!noNextGraveOrOverriddenOrRandom) {
        try {
          const isThereSameOrd1 = this.sameOrdLeft(this.previousGraveOrd);
          this.currentOrdGroup =
            this.NavStateStore.getOrdGroups[this.previousGraveOrd];

          const allRandom = this.currentOrdGroup.neighbours.every(
            (grave) => grave.random === true
          );

          /*If there's still at least 1 task left in the current field, then it's the regular case:
          the employee is done with a task group in a radius and we are looking for another grave in the same field*/

          /*If there is no task left in the field, then we are looking for other graves in the neighbouring fields
          with the exception of when there are only randomly placed graves among the neighbouring fields.
           */

          if (!allRandom) {
            Object.assign(nextGraveOptions, {
              gravesToSelectFrom: isThereSameOrd1
                ? this.currentOrdGroup.features
                : this.currentOrdGroup.neighbours,
              setCurrentOrdGroup: false,
              setPreviousOrd: !isThereSameOrd1,
              unsetOverrideAndSetCarStopReset: false,
            });
          }
        } catch (error) {
          console.error("An error with the ordGroups occurred:", error);
        }
      }
      /*If there's no new grave set yet, or the right-most button was pressed to search for
       a new grave regardless of the currently set field,
      or the selected grave group is radom (seemingly randomly placed in the graveyard),
      or there are only random graves in the neightbouring fields
      then use the default options and find the next grave and grave group as if there were no previous stops yet*/

      selectNextGrave(nextGraveOptions);
    },

    async findClosestGraveOnFootRoute() {
      let minDistance = Number.MAX_VALUE;
      let closestGrave = null;

      const isThereSameOrd1 = this.NavStateStore.getTaskGroup.features.some(
        (grave) => this.nextGrave.ord1 === grave.ord1
      );

      if (isThereSameOrd1)
        this.currentOrdGroup =
          this.NavStateStore.getOrdGroups[this.nextGrave.ord1];
      else this.nextGrave = {};

      // Determine the set of graves to iterate over.
      const graves =
        this.navControl.sameOrdOverride ||
        this.isEmpty(this.nextGrave) ||
        this.nextGrave.random
          ? this.NavStateStore.getTaskGroup.features
          : this.currentOrdGroup.features;

      // Iterate through the graves to find the closest one.
      graves.forEach((grave) => {
        const pathResult = this.RouteStore.getFootPathFinder.findPath(
          grave,
          this.routeLocation.closestPointOnFootRoute
        );
        if (pathResult && pathResult.weight < minDistance) {
          minDistance = pathResult.weight;
          closestGrave = grave;
        }
      });

      // Update the navigation state if a closer grave is found.
      if (closestGrave) {
        this.nextGrave = closestGrave;
        this.currentOrdGroup =
          this.NavStateStore.getOrdGroups[closestGrave.ord1];
        this.navControl.sameOrdOverride = false;
      } else {
        console.error("No grave found");
      }
    },

    async findNextGraveBasedOnNumber(ascending) {
      if (ascending)
        this.nextGrave = this.NavStateStore.getTaskGroup.features[0];
      else
        this.nextGrave =
          this.NavStateStore.getTaskGroup.features[
            this.NavStateStore.getTaskGroup.features.length - 1
          ];
    },

    getGravesInFirstGraveRadius() {
      this.NavStateStore.resetTaskGroupInRadius();

      let minDist = Number.MAX_VALUE;
      let minGrave = { ...this.nextGrave };

      const distances = this.currentOrdGroup.neighbours.map((grave) => ({
        grave: { ...grave },
        distance: this.getDistanceInMetersOnPath(
          this.RouteStore.getFootPathFinder,
          grave,
          this.routeLocation.carStop
        ),
      }));

      distances.forEach(({ grave, distance }) => {
        if (distance < minDist) {
          minDist = distance;
          minGrave = grave;
        }

        if (distance <= this.NavStateStore.getRadius + 2.5) {
          this.NavStateStore.addTaskToTaskGroupInRadius(grave);
        }
      });

      if (!this.NavStateStore.getTaskGroupInRadius.length) {
        this.NavStateStore.addTaskToTaskGroupInRadius(minGrave);
      }

      this.NavStateStore.setTaskGroupInRadius(
        this.groupAndSortLocations(this.NavStateStore.getTaskGroupInRadius)
      );

      this.nextGrave = this.NavStateStore.getTaskGroupInRadius[0];
    },

    sortGroupBasedOnNumberAndDistance(group) {
      //Check if distance increases with ascending ords (field numbers) and decide sorting direction
      let increasingDistance = true;

      if (group.length > 1) {
        const firstDistance = this.getDistanceInMetersOnPath(
          this.RouteStore.getFootPathFinder,
          this.routeLocation.carStop,
          group[0]
        );
        const lastDistance = this.getDistanceInMetersOnPath(
          this.RouteStore.getFootPathFinder,
          this.routeLocation.carStop,
          group[group.length - 1]
        );
        increasingDistance = firstDistance <= lastDistance;
      }

      // Sort the group based on ords and the distance trend
      group.sort((a, b) => {
        const ordKeys = ["ord2", "ord3", "ord4"];
        for (const key of ordKeys) {
          if (a[key] < b[key]) return increasingDistance ? -1 : 1;
          if (a[key] > b[key]) return increasingDistance ? 1 : -1;
        }

        const distanceA = this.getDistanceInMetersOnPath(
          this.RouteStore.getFootPathFinder,
          this.routeLocation.carStop,
          a
        );
        const distanceB = this.getDistanceInMetersOnPath(
          this.RouteStore.getFootPathFinder,
          this.routeLocation.carStop,
          b
        );
        return increasingDistance
          ? distanceA - distanceB
          : distanceB - distanceA;
      });
    },

    groupAndSortLocations(locations) {
      //Group by ord1
      const groupsByOrd1 = locations.reduce((acc, location) => {
        if (!acc[location.ord1]) acc[location.ord1] = [];
        acc[location.ord1].push(location);
        return acc;
      }, {});

      //Convert groups to array and sort by centroid distance
      const sortedGroups = Object.entries(groupsByOrd1)
        .map(([ord1, group]) => {
          const averageDistance =
            group.reduce(
              (sum, loc) =>
                sum +
                this.getDistanceInMetersOnPath(
                  this.RouteStore.getFootPathFinder,
                  this.routeLocation.carStop,
                  loc
                ),
              0
            ) / group.length;
          return { ord1, group, averageDistance };
        })
        .sort((a, b) => a.averageDistance - b.averageDistance);

      //Sort within each group by ord2, ord3, ord4, and distance
      sortedGroups.forEach(({ group }) =>
        this.sortGroupBasedOnNumberAndDistance(
          group,
          this.routeLocation.carStop
        )
      );

      return sortedGroups.flatMap(({ group }) => group);
    },

    updateClosestPointOnRouteSegment(routeSegment, type) {
      if (!routeSegment || !routeSegment.geometry.coordinates.length) {
        console.error(`${type} segment is undefined or has no coordinates.`);
        return;
      }

      let minDistance = Number.MAX_VALUE;
      let closestPoint = null;

      routeSegment.geometry.coordinates.forEach((coordinate) => {
        const currentPoint = this.toFeature(coordinate);
        const distance = this.getDistanceInMeters(
          this.$root.location,
          currentPoint
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestPoint = currentPoint;
        }
      });

      if (closestPoint) {
        if (type === "car") {
          this.routeLocation.closestPointOnCarRoute = closestPoint;
        } else if (type === "foot") {
          this.routeLocation.closestPointOnFootRoute = closestPoint;
        } else {
          console.error(`Invalid path type: ${type}`);
        }
      } else {
        console.error(`No closest point found in the ${type} segment.`);
      }
    },

    isCarDestinationReached() {
      if (!this.NavStateStore.inCarToCarStop) {
        return false;
      }
      if (this.NavStateStore.getCarStopOverride) {
        this.NavStateStore.setCarStopOverride(false);
        return true;
      }
      let distanceFromCarStopPoint = this.getDistanceInMetersOnPath(
        this.RouteStore.getCarPathFinder,
        this.routeLocation.closestPointOnCarRoute,
        this.routeLocation.carStop
      );
      let realDistance = this.getDistanceInMeters(
        this.routeLocation.closestPointOnCarRoute,
        this.routeLocation.carStop
      );
      if (distanceFromCarStopPoint < 5 && realDistance < 3) return true;
      return false;
    },
    isGraveReached() {
      if (!this.NavStateStore.onFootToNextGrave) {
        return false;
      }

      let distanceFromGrave = this.getDistanceInMetersOnPath(
        this.RouteStore.getFootPathFinder,
        this.routeLocation.closestPointOnFootRoute,
        this.nextGrave
      );

      let realDistance = this.getDistanceInMeters(
        this.$root.location,
        this.nextGrave
      );

      if (distanceFromGrave < 5 && realDistance < 3 && !this.nextGrave.reached)
        return true;
      return false;
    },

    isCarStopLocationReached() {
      if (!this.NavStateStore.onFootToCarStop) {
        return false;
      }
      if (this.NavStateStore.getCarStopOverride) {
        this.NavStateStore.setCarStopOverride(false);
        return true;
      }

      let distanceFromGrave = this.getDistanceInMetersOnPath(
        this.RouteStore.getFootPathFinder,
        this.routeLocation.closestPointOnFootRoute,
        this.routeLocation.carStop
      );

      let realDistance = this.getDistanceInMeters(
        this.$root.location,
        this.routeLocation.carStop
      );

      if (distanceFromGrave < 5 && realDistance < 3) return true;
      return false;
    },

    getRouteColor() {
      if (this.NavStateStore.onFootOrBackToCar) {
        this.routeColor = ORANGE;
      } else {
        if (this.NavStateStore.onFootNavType) {
          this.routeColor = ORANGE;
        } else if (this.NavStateStore.withCarMK1703) {
          this.routeColor = GREEN;
        } else if (this.NavStateStore.withCarM26) {
          this.routeColor = BLUE;
        } else if (this.NavStateStore.withCarLKW) {
          this.routeColor = RED;
        }
      }
    },

    async initializeNavigation() {
      this.AppStateStore.activateAppLoading();
      this.AppStateStore.setAppProgressAndSleep(20, 50);
      this.location.change = true;

      if (this.NavStateStore.isTaskGroupCompleted) this.completeTaskGroup();
      else await this.startNavigation();

      await this.resetStateAndProgress();
    },

    async resetStateAndProgress() {
      this.AppStateStore.setAppProgressAndSleep(0, 50);
      this.AppStateStore.resetAppLoading();
      this.AppStateStore.resetServerLoading();

      this.location.change = false;
    },

    completeTaskGroup() {
      this.NavStateStore.setNavigationModeToNone();
      this.taskgroupDoneDialog = true;
    },

    async startNavigation() {
      this.calculateLocationOnPath();
      await this.AppStateStore.setAppProgressAndSleep(40, 50);
      if (this.NavStateStore.onFootNavType) {
        await this.initializeFootNavigation();
      } else if (
        this.NavStateStore.inCarToCarStop ||
        this.NavStateStore.withCarNavType
      ) {
        this.currentOrdGroup.length = 0;
        await this.initializeCarNavigation();
      }
    },

    async initializeFootNavigation() {
      this.NavStateStore.setNavigationMode(NavigationMode.FOOT);

      if (this.NavStateStore.isRoutePlanningShortestPath) {
        this.findClosestGraveOnFootRoute();
      } else {
        this.findNextGraveBasedOnNumber(
          !!this.NavStateStore.isRoutePlanningAscendingNumbers
        );
      }
      await this.AppStateStore.setAppProgressAndSleep(80, 50);
      this.updatePath();
      await this.AppStateStore.setAppProgressAndSleep(100, 50);
    },

    async initializeCarNavigation() {
      this.getGraveGroupByCar();
      await this.AppStateStore.setAppProgressAndSleep(80, 50);
      this.updatePath();
      await this.AppStateStore.setAppProgressAndSleep(100, 50);
    },

    initGeolocation() {
      this.geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      });

      window.addEventListener(
        "deviceorientationabsolute",
        this.manageCompass,
        true
      );
    },
    manageCompass(event) {
      if (event.webkitCompassHeading) {
        this.mapProperties.rotation = this.degrees_to_radians(
          event.webkitCompassHeading
        );
      } else {
        this.mapProperties.rotation = this.degrees_to_radians(event.alpha);
      }
    },
    degrees_to_radians(degrees) {
      let pi = Math.PI;
      return degrees * (pi / 180);
    },

    setGeolocationListener() {
      this.geolocation.on("change:position", async () => {
        if (this.AppStateStore.getCurrentTab !== DashboardTab.NAVIGATION)
          return;

        const newLocation = this.geolocation.getPosition();

        if (!newLocation) return;

        this.$root.location = this.toFeature(newLocation);
        this.mapProperties.center = this.getFeatureCoordinates(
          this.$root.location
        );

        if (!this.location.found) {
          if (this.location.searching && this.location.searchCount-- <= 0) {
            this.location.searching = false;
            this.AppStateStore.activateServerLoading();
            await this.initializeNavigation();
            this.location.found = true;
          }
          return;
        }

        if (!this.location.change && !this.AppStateStore.getServerLoading) {
          this.location.change = true;

          if (!this.NavStateStore.isNavigationStopped) {
            this.processNavigation();
          }

          this.location.change = false;
        }
      });
    },

    processNavigation() {
      this.calculateLocationOnPath();

      const navigationTypeOptions = {
        onFootNavType: () => {
          if (this.isGraveReached()) {
            this.handleGraveReached();
          } else {
            this.updatePath();
          }
        },
        withCarNavType: () => {
          if (this.isCarDestinationReached()) {
            this.handleCarDestinationReached();
          } else if (this.isGraveReached()) {
            this.handleGraveReached();
          } else if (this.isCarStopLocationReached()) {
            this.handleCarStopLocationReached();
          } else {
            this.updatePath();
          }
        },
      };

      navigationTypeOptions[
        this.NavStateStore.onFootNavType ? "onFootNavType" : "withCarNavType"
      ]();
    },

    async handleCarDestinationReached() {
      await this.getTasks({
        updateNavState: true,
      });
      if (this.withTeamleader) {
        this.NavStateStore.setNavigationMode(NavigationMode.NONE);
        this.AppStateStore.switchTabTo(DashboardTab.TASKS);
      } else {
        this.NavStateStore.setNavigationMode(NavigationMode.FOOT);
      }
    },

    handleGraveReached() {
      this.NavStateStore.setNavigationMode(NavigationMode.NONE);
      this.nextGrave.reached = true;
      this.$emit("graveReachedStartTask", {
        grave: {
          id: this.nextGrave.id,
          startTask: true,
          taskId: this.nextGrave.task.id,
        },
        mapState: {
          zoom: this.mapProperties.zoom,
          center: this.mapProperties.center,
          rotation: this.mapProperties.rotation,
        },
      });
    },

    async handleCarStopLocationReached() {
      this.NavStateStore.setNavigationMode(NavigationMode.CAR);
      await this.initializeNavigation();
    },

    async checkWorkStarted() {
      let formData = new FormData();
      formData.set("checkWorkStarted", true);
      formData.set("user_id", this.UserStore.user.id);

      try {
        const response = await this.$root.postData(formData);
        this.workStatus.doneToday = !!+response.done_today;
        this.workStatus.workStarted = !!+response.started;
        this.workStatus.onBreak = !!+response.on_break;
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
  },
  async mounted() {
    this.initGeolocation();
    this.setGeolocationListener();
    this.getRouteColor();
    this.checkWorkStarted();
  },

  async destroyed() {
    this.geolocation.setTracking(false);
  },
};
</script>
