var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-6",attrs:{"fluid":""}},[_c('v-select',{attrs:{"items":_vm.navigationTypes,"item-text":(item) => (_vm.$root.isLanguageGerman ? item.name_de : item.name_en),"item-value":"id","placeholder":_vm.$t('selectType'),"label":_vm.$t('navigation_type'),"error":_vm.typeError},model:{value:(_vm.navigationType),callback:function ($$v) {_vm.navigationType=$$v},expression:"navigationType"}}),(
      _vm.NavStateStore.getNavigationType === _vm.NavigationType.ONFOOT ||
      _vm.NavStateStore.getNavigationType === _vm.NavigationType.ONFOOTWITHLOAD
    )?_c('v-select',{attrs:{"items":_vm.routePlannings,"item-text":(item) => (_vm.$root.isLanguageGerman ? item.name_de : item.name_en),"return-object":"","label":_vm.$t('route_planning')},model:{value:(_vm.routePlanning),callback:function ($$v) {_vm.routePlanning=$$v},expression:"routePlanning"}}):_vm._e(),_c('v-slider',{attrs:{"thumb-label":"always","min":"20","max":"100","label":"Radius:","thumb-size":"20"},model:{value:(_vm.radius),callback:function ($$v) {_vm.radius=$$v},expression:"radius"}}),_c('h4',{staticClass:"title grey--text pa-3"},[_vm._v(_vm._s(_vm.$t("current_tasks")))]),_c('v-divider'),_c('v-list',[_vm._l((_vm.taskgroups),function(taskgroup,index){return [_c('v-list-item',{key:taskgroup.name_de,attrs:{"ripple":""}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"x-large":"","color":"black"},on:{"click":function($event){return _vm.$emit('tabToTasksWithToolbar', {
                subtitle: _vm.$root.isLanguageGerman
                  ? taskgroup.name_de
                  : taskgroup.name_en,
                graveyardId: taskgroup.graveyard_id,
                taskTypeId: taskgroup.task_type_id,
              })}}},[_vm._v(" details ")])],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.startNav(taskgroup)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$root.isLanguageGerman ? taskgroup.name_de : taskgroup.name_en)+" ")]),(taskgroup.count)?[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(taskgroup.count + " " + (taskgroup.count > 1 ? _vm.$t("graves") : _vm.$t("grave")))+" ")]),(
                _vm.isTaskGroupActive(
                  taskgroup.task_type_id,
                  taskgroup.graveyard_id
                )
              )?_c('v-list-item-subtitle',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.$t("in_progress"))+" ")]):_vm._e()]:_vm._e()],2),_c('v-list-item-action',[(
              _vm.isTaskGroupActive(
                taskgroup.task_type_id,
                taskgroup.graveyard_id
              )
            )?_c('v-icon',{attrs:{"large":"","color":"red"}},[_vm._v(" my_location ")]):_vm._e()],1),_c('v-list-item-action',[(taskgroup.done)?_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v("done")]):_vm._e()],1)],1),_c('v-divider',{key:index})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }