import { defineStore } from "pinia";
import { useAppBarStore } from "@store/appBar";
import { useNavStateStore } from "@store/navState.js";
import i18n from "../i18n";

/*Possible tabs: 
    Dashboard: map, taskgroups, task,  navigation, task, grave
    Guest: map, grave
    GraveAssignment: map, newContract
*/

export const useAppStateStore = defineStore({
  id: "appStateStore",
  state: () => ({
    routeCurrent: "",
    routeFrom: "",
    backTo: false,
    backToDisabled: false,
    currentTab: "",
    previousTab: "",
    messages: {
      errors: {
        ItemTable: {
          sameTaskType: i18n.t("error.dialog_taskType"),
          wrongInput: i18n.t("wrong_input"),
          alreadyInItemList: i18n.t("item_duplicate"),
        },
        Backup: {
          failed: i18n.t("error.backup"),
        },
        Customers: {
          customerName: i18n.t("error.duplicate_customer"),
          graveyardName: i18n.t("error.duplicate_graveyard"),
          userName: i18n.t("error.username"),
        },
        Export: {
          noImages: i18n.t("error.noImages"),
        },
        Teams: {
          nameTaken: i18n.t("dialog_teamname_taken"),
          teamUserDuplicate: i18n.t("dialog_team_user_duplicate"),
        },
        Global: {
          timeout: i18n.t("timeout"),
          location: i18n.t("locationError"),
        },
      },
      backupSuccessful: i18n.t("backup_created"),
      tasksInRadiusDone: i18n.t("radius_finished"),
    },
    dialogs: {
      errorDialog: false,
      messageDialog: false,
    },
    messageType: "",
    message: "",
    objectType: "",
    appLoading: false,
    appProgress: 0,
    serverLoading: false,
  }),
  getters: {
    isAppStateBeforeLoggedIn() {
      return (
        this.routeCurrent.name === "index" ||
        this.routeCurrent.name === "login" ||
        this.routeCurrent.name === "guest"
      );
    },
    isAppStateLoginOrGuest() {
      return (
        this.routeCurrent.name === "login" || this.routeCurrent.name === "guest"
      );
    },
    isAppStateBackTo() {
      return this.backTo;
    },
    getCurrentTab() {
      return this.currentTab;
    },
    getPreviousTab() {
      return this.previousTab;
    },
    getBackToDisabled() {
      return this.backToDisabled;
    },
    getErrorDialog() {
      return this.dialogs.errorDialog;
    },
    getMessageDialog() {
      return this.dialogs.messageDialog;
    },
    getMessage() {
      return this.message;
    },
    getMessages() {
      return this.messages;
    },
    getMessageType() {
      return this.messageType;
    },
    findMessage() {
      return this.messages[this.messageType];
    },
    findErrorMessage() {
      return this.messages.errors[this.objectType][this.messageType];
    },
    getAppLoading() {
      return this.appLoading;
    },
    getAppProgress() {
      return this.appProgress;
    },
    getServerLoading() {
      return this.serverLoading;
    },
  },
  actions: {
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    activateAppLoading() {
      this.appLoading = true;
    },
    setAppProgress(progress) {
      this.appProgress = progress;
    },
    async setAppProgressAndSleep(progress, delay) {
      this.setAppProgress(progress);
      await this.sleep(delay);
    },
    resetAppLoading() {
      this.appLoading = false;
      this.appProgress = 0;
    },
    activateServerLoading() {
      this.serverLoading = true;
    },
    resetServerLoading() {
      this.serverLoading = false;
    },
    createMessage(messageType) {
      this.messageType = messageType;
      this.message = this.findMessage;
      this.dialogs.messageDialog = true;
    },
    removeMessage() {
      this.messageType = "";
      this.message = "";
      this.dialogs.messageDialog = false;
    },
    createError(objectType, messageType) {
      this.messageType = messageType;
      this.objectType = objectType;
      this.message = this.findErrorMessage;
      this.dialogs.errorDialog = true;
    },
    createErrorFromParam(message) {
      this.message = message;
      this.dialogs.errorDialog = true;
    },
    removeError() {
      this.message = "";
      this.objectType = "";
      this.messageType = "";
      this.dialogs.errorDialog = false;
    },
    toggleBackToDisabled() {
      this.backToDisabled = !this.backToDisabled;
    },
    setBackToDisabled(value) {
      this.backToDisabled = value;
    },
    switchTabTo(newTab) {
      if (this.getBackToDisabled || this.currentTab === newTab) return;
      this.previousTab = this.currentTab;
      this.currentTab = newTab;
    },
    setPreviousTab(prevTab) {
      this.previousTab = prevTab;
    },
    setCurrentTab(currentTab) {
      this.currentTab = currentTab;
    },
    setCurrentTabAndBackTo(newTab) {
      this.switchTabTo(newTab);
      this.backTo = true;
    },
    resetAll() {
      const appBarStore = useAppBarStore();
      const navStateStore = useNavStateStore();
      appBarStore.setAppBarTitle("");
      appBarStore.setAppBarSubtitle("");
      navStateStore.stopNavigation();
      this.switchTabTo("");
      this.setPreviousTab("");
      this.backTo = false;
      this.backToDisabled = false;
      this.removeError();
      this.resetAppLoading();
      this.resetServerLoading();
    },
    backToPreviousTab() {
      if (this.getBackToDisabled) return;
      const appBarStore = useAppBarStore();
      appBarStore.setAppBarTitle("");
      appBarStore.setAppBarSubtitle("");
      this.switchTabTo(this.previousTab);
      this.setPreviousTab("");
      this.backTo = false;
      this.backToDisabled = false;
    },
  },
});
