<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("menu.groups.tasks.incomplete") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card class="elevation-5">
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            :label="$t('search')"
            single-line
            hide-details
            left
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tasks"
          :search="search"
          item-key="id"
          return-object
          v-model="selected"
          dense
          :hide-default-footer="true"
          disable-pagination
          show-expand
          :footer-props="{
            itemsPerPageText: '',
          }"
        >
          <template #item.graveyard="{ item }">
            {{ item.graveyard_name }}
          </template>
          <template #item.grave_number="{ item }">
            {{ item.grave_number }}
          </template>
          <template #item.person="{ item }">
            {{ item.person }}
          </template>
          <template v-if="$root.isLanguageGerman" #item.task_type="{ item }">
            {{ item.task_type_de }}
          </template>
          <template v-else #item.task_type="{ item }">
            {{ item.task_type_en }}
          </template>
          <template #item.description="{ item }">
            {{ item.description }}
          </template>
          <template #item.teams="{ item }">
            {{ makeStringFromArray(item.teams) }}
          </template>

          <template #item.user="{ item }">{{ item.user }}</template>
          <template #item.start="{ item }">{{ item.start }}</template>
          <template #item.duration="{ item }">{{ item.duration }}</template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              v-if="item.task_type_id === TaskType.PFLANZEN"
            >
              <div class="my-5">
                <template v-for="(item, index) in item.items">
                  <div :key="item.id + item.name + item.quantity + index">
                    {{ item.name }}
                    :
                    {{ item.quantity }}
                  </div>
                </template>
              </div>
            </td>
          </template>
          <template slot="no-results">
            {{ $t("no_results") }}
          </template>
          <template v-slot:no-data>
            {{ $t("no_data_text") }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { TaskType } from "@utils/constants";
import { makeStringFromArray } from "@utils/utilFunctions";

export default {
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data() {
    return {
      TaskType: TaskType,
      tasks: [],
      selected: [],
      search: "",
    };
  },
  methods: {
    makeStringFromArray,
    async getIncompleteTasks() {
      let formData = new FormData();
      formData.set("getIncompleteTasks", true);
      formData.set("customer_id", this.UserStore.customer.id);
      try {
        const response = await this.$root.postData(formData);
        this.tasks = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },

  computed: {
    getTaskTypeValue() {
      if (this.$root.isLanguageGerman) return "task_type_de";
      return "task_type_en";
    },
    getIntervalValue() {
      if (this.$root.isLanguageGerman) return "interval_de";
      return "interval_en";
    },
    headers() {
      return [
        { text: this.$t("graveyard"), value: "graveyard_name", sortable: true },
        { text: this.$t("grave"), value: "grave_number", sortable: true },
        { text: this.$t("person"), value: "person", sortable: true },
        {
          text: this.$t("task_type"),
          value: this.getTaskTypeValue,
          sortable: true,
        },

        { text: this.$t("description"), value: "description", sortable: true },
        {
          text: this.$t("menu.groups.teams.header"),
          value: "teams",
          sortable: true,
        },
        {
          text: this.$t("employee"),
          value: "user",
          sortable: true,
        },
        {
          text: this.$t("start"),
          value: "start",
          sortable: true,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getIncompleteTasks);
    this.getIncompleteTasks();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getIncompleteTasks);
  },
};
</script>
