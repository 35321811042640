import messages from "../locale/locales.ts";

export const DashboardTab = Object.freeze({
  MAP: "map",
  TASKGROUPS: "taskgroups",
  TASKS: "tasks",
  NAVIGATION: "navigation",
  GRAVE: "grave",
});

export const TaskType = Object.freeze({
  PFLEGE: 1,
  GIESSEN: 2,
  PFLANZEN: 3,
  SONDERAUFGABE: 4,
});

export const UserRole = Object.freeze({
  SUPERADMIN: 1,
  GESCHÄFTSFÜHRER: 2,
  VERWALTUNG: 3,
  TEAMLEITER: 4,
  MITARBEITER: 5,
});

export const NavigationType = Object.freeze({
  WITHCARMK1703: 1,
  ONFOOT: 2,
  ONFOOTWITHLOAD: 3,
  WITHCARM26: 4,
  WITHCARLKW: 5,
});

export const NavigationTypes = Object.freeze([
  {
    id: 1,
    name_de: messages.de.navigationTypes.mk1703,
    name_en: messages.en.navigationTypes.mk1703,
  },
  {
    id: 2,
    name_de: messages.de.navigationTypes.onFoot,
    name_en: messages.en.navigationTypes.onFoot,
  },
  {
    id: 3,
    name_de: messages.de.navigationTypes.onFootWithLoad,
    name_en: messages.en.navigationTypes.onFootWithLoad,
  },
  {
    id: 4,
    name_de: messages.de.navigationTypes.m26,
    name_en: messages.en.navigationTypes.m26,
  },
  {
    id: 5,
    name_de: messages.de.navigationTypes.lkw,
    name_en: messages.en.navigationTypes.lkw,
  },
]);

export const RoutePlannings = Object.freeze([
  {
    id: 1,
    name_de: messages.de.routePlannings.shortest,
    name_en: messages.en.routePlannings.shortest,
  },
  {
    id: 2,
    name_de: messages.de.routePlannings.ascending,
    name_en: messages.en.routePlannings.ascending,
  },
  {
    id: 3,
    name_de: messages.de.routePlannings.descending,
    name_en: messages.en.routePlannings.descending,
  },
]);

export const NavigationMode = Object.freeze({
  NONE: "none",
  FOOT: "foot",
  CAR: "car",
  BACKTOCAR: "backToCar",
});

export const ImportTypes = Object.freeze([
  {
    id: 1,
    name_de: messages.de.importTypes.pflege,
    name_en: messages.en.importTypes.pflege,
  },
  {
    id: 2,
    name_de: messages.de.importTypes.giessen,
    name_en: messages.en.importTypes.giessen,
  },
  {
    id: 3,
    name_de: messages.de.importTypes.pflanzen,
    name_en: messages.en.importTypes.pflanzen,
  },
  {
    id: 4,
    name_de: messages.de.importTypes.activeGrave,
    name_en: messages.en.importTypes.activeGrave,
  },
  {
    id: 5,
    name_de: messages.de.importTypes.inactiveGrave,
    name_en: messages.en.importTypes.inactiveGrave,
  },
  {
    id: 6,
    name_de: messages.de.importTypes.gravesToDelete,
    name_en: messages.en.importTypes.gravesToDelete,
  },
  {
    id: 7,
    name_de: messages.de.importTypes.unassignedGraves,
    name_en: messages.en.importTypes.unassignedGraves,
  },
]);

export const ExportTypes = Object.freeze([
  {
    id: 1,
    name_de: messages.de.exportTypes.pflegeGiessen,
    name_en: messages.en.exportTypes.pflegeGiessen,
  },
  {
    id: 2,
    name_de: messages.de.exportTypes.pflanzen,
    name_en: messages.en.exportTypes.pflanzen,
  },
]);

export const PRECISION = 1e-12;
