export default {
  en: {
    routePlannings: {
      shortest: "Shortest route",
      ascending: "Ascending numbers",
      descending: "Descending numbers",
    },
    navigationTypes: {
      mk1703: "Watering vehicle Schmitz Minikipper MK 1703",
      onFoot: "On foot (with hose)",
      onFootWithLoad: "On foot with load / wheelbarrow",
      m26: "Watering vehicle Multicar M26",
      lkw: "Vehicle Unimog / Truck",
    },
    locationError:
      "Location can't be found. Please make sure you enabled sharing your location in the browser settings",
    timeout: "Timeout",
    importTypes: {
      pflege: "Plant care list",
      giessen: "Watering list",
      pflanzen: "Planting list",
      activeGrave: "Grave list - active graves",
      inactiveGrave: "Grave list - inactive graves",
      gravesToDelete: "Grave list - graves to delete",
      unassignedGraves: "Grave list - unassigned graves",
    },
    exportTypes: {
      pflegeGiessen: "Plant care,  Watering",
      pflanzen: "Planting",
    },
    addItemToList: "Add an item to the list",
    radius_finished:
      "The tasks in the radius are done. Navigate back to the vehicle",
    item_duplicate: "This item is already in the item list",
    wrong_input: "Wrong input",
    selectType: "Please select the navigation type",
    add_new_item: "Add item",
    all: "All",
    new_item: "New item",
    no_data_text: "No data available",
    quantity: "Quantity",
    backup_created: "The backup was successfully created",
    import_type: "Import Type",
    export_type: "Export Type",
    input_gravedata: "Input grave data",
    from_list: "Select grave from list",
    restore: "Restore",
    grave_logec_id: "Logec Grave ID",
    task_logec_id: "Logec Task ID",
    from: "From",
    until: "Until",
    route_planning: "Route planning",
    navigation_type: "Navigation type",
    active: "Active",
    backup: "Backup",
    contracts: "Contracts",
    dialog_task_done: "This task has already been completed",
    dialog_ok: "ok",
    nav_next: "Navigate to the next task",
    address: "Adress",
    completed_task_approval: "Approval (Completed tasks)",
    special_task_approval: "Approval (New tasks)",
    approve: "Approve",
    refresh: "Refresh",
    users: "Users",
    new_user: "New User",
    graveyards: "Graveyards",
    new_graveyard: "New Graveyard",
    new_customer: "New Customer",
    task_approval: "Approval (New tasks)",
    name: "Name",
    customers: "Customers",
    no_connection: "No internet connection",
    confirm_tasks: "Confirm tasks",
    responsible_employee: "Responsible employee",
    searching: "Searching for user location...",
    location_found: "User Location Found!",
    finish_work: "Finish work",
    break: "Take a break",
    select_other: "Select other tasks",
    customer: "Customer",
    description: "Description",
    start_navigation: "Start navigation",
    first_on: "First due",
    base_path: "Base path for the images (e.g., path/to/image/)",
    download_images: "Download images",
    set_path: "Set Path",
    incomplete_task_data: "Incomplete task data",
    comment: "Comment",
    special: "Special info",
    csv_click_to_select: "Click here to select a .csv file",
    import: "Import",
    assign_number: "Assign number",
    unassign_number: "Unassign number!",
    team: "Team",
    grave_type: "Grave type",
    create: "Create",
    create_new_task: "Create new task",
    edit_task: "Edit task",
    edit_selected_tasks: "Edit selected tasks",
    person: "Person",
    poi: "Famous person",
    number: "Number",
    update_person: "Update Person",
    update_logec_id: "Update Logec ID",
    update_quantity: "Edit item quantity",
    type: "Type",
    guest: "Guest Login",
    start: "Start",
    end: "End",
    time_spent_on_breaks: "Time spent on breaks",
    current_user: "Current User",
    export: "Export",
    tasktype: "Task type",
    working_hours: "Working hours",
    role: "Role",
    confirm: "Confirm",
    duration: "Duration (minutes)",
    database_id: "Database ID",
    grave_number_: "Grave number",
    deceased: "Deceased",
    assign: "Assign",
    username: "Username",
    lastname: "Last name",
    firstname: "First name",
    authorization: "Authorization",
    actions: "Actions",
    edit: "Edit",
    lock: "Lock",
    delete: "Delete",
    teamleader: "Team leader",
    task_type: "Task type",
    team_name: "Team name",
    cancel: "Cancel",
    yes: "Yes",
    add: "Add",
    update: "Update",
    delete_question_user: "Delete this user?",
    delete_question_team: "Delete this team?",
    delete_question_customer: "Delete this customer?",
    delete_question_graveyard: "Delete this graveyard?",
    create_question_grave: "Should a new grave be created at this position?",
    date: "Date",
    employee: "Employee",
    completed_on: "Completed on",
    search: "Search",
    selection: "Selection",
    next: "Next",
    last: "Last completed",
    graveyard: "Graveyard",
    schedule: "Task schedule",
    interval: "Interval",
    task_select: "Select a task",
    close: "Close",
    task: "Task",
    grave_details: "Grave details",
    done: "done?",
    save: "Save",
    create_task: "Create task",
    grave_info: "Grave info",
    task_description: "Task description",
    grave_number: "Grave number: ",
    task_details: "Task details",
    in_progress: "In progress",
    grave: "Grave",
    graves: "Graves",
    current_tasks: "Current Tasks",
    language: "Language",
    no_results: "No results",
    error: {
      import: "The following graves could not be found:",
      backup: "Backup error",
      login_username: "the username must contain 1 slash",
      password_rules: "Min. 8 characters",
      password_rules_complex:
        "Min. 8 characters, 2 capital letters and 1 special character",
      number_rules: "Only numbers, must not start with 0",
      username_rule:
        "The username must not contain spaces and special characters",
      graveyard_rule: "The graveyard name must not contain spaces",
      team_rule: "The team name must have at least 2 characters",
      customer_rule: "The customer name must not contain spaces",
      enter_password: "Enter a password",
      enter_user: "Enter a user",
      characters_5: "At least 5 characters",
      characters_3: "At least 3 characters",
      wrong_password: "Wrong password",
      login_unsuccessful: "Your login attempt was not successful.",
      account_locked: "Your account has been locked.",
      customer_locked: "This customer has been locked.",
      dialog_teamname_taken: "There's already a team with this name",
      dialog_team_user_duplicate: "This user was already added to the team",
      username: "Username already taken",
      duplicate_customer: "Customer name already taken",
      duplicate_graveyard: "Graveyard name already taken",
      dialog_taskType: "This task type is already assigned to this grave",
      noImages: "There are no images for the selected graves",
    },
    dashboard: {
      map: "Map",
      taskgroups: "Tasks",
      navigation: "Navigation",
    },
    login: {
      header: "Please log in",
      user: "Customer/User",
      password: "Password",
      login: "Log In",
    },
    hours: {
      startWork: "Start Work",
      endWork: "End Work",
      startBreak: "Start Break",
      endBreak: "Stop Break",
    },
    menu: {
      active: "Only active tasks",
      overview: "Overview",
      sync: "Database is up-to-date",
      groups: {
        tasks: {
          header: "Tasks",
          schedule: "Task scheduling",
          newTasks: "New Tasks",
          incomplete: "Unfinished tasks",
          completed: "Completed tasks",
        },
        teams: {
          header: "Teams",
          organize: "Organize teams",
        },
        employees: {
          header: "Employees",
          manage: "Employee management",
          delete_question: "Delete this user?",
        },
        working_hours: {
          header: "Working hours",
          working_hours: "Working hours",
        },
        map: {
          header: "Management",
          assign: "Grave assignment",
          add: "Add graves",
        },
      },
    },
    menu_settings: {
      settings: "Settings",
      logout: "Logout",
    },
  },
  de: {
    routePlannings: {
      shortest: "Kürzester Weg",
      ascending: "Aufsteigender Grabnummer",
      descending: "Absteigender Grabnummer",
    },
    navigationTypes: {
      mk1703: "Gießfahrzeug Schmitz Minikipper MK 1703",
      onFoot: "Zu Fuß (mit Schlauch)",
      onFootWithLoad: "Zu Fuß mit Traglast / Schubkarre",
      m26: "Gießfahrzeug Multicar M26",
      lkw: "Fahrzeug Unimog / LKW",
    },
    locationError:
      "Der Standort kann nicht gefunden werden. Bitte überprüfen Sie, ob Sie die Freigabe Ihres Standorts in den Browsereinstellungen aktiviert haben.",
    timeout: "Timeout",
    importTypes: {
      pflege: "Pflegeliste",
      giessen: "Gießliste",
      pflanzen: "Anpflanzungsliste",
      activeGrave: "Grabstaettenliste2  - aktive Gräber",
      inactiveGrave: "Grabstaettenliste2 - inaktive Gräber",
      gravesToDelete: "Grabstaettenliste2 - zu löschende Gräber",
      unassignedGraves: "Grabstaettenliste2 - nicht zugewiesene Gräber",
    },
    exportTypes: {
      pflegeGiessen: "Pflege, Gießen",
      pflanzen: "Pflanzen",
    },
    addItemToList: "Einen Artikel zur Liste hinzufügen",
    radius_finished:
      " Die Aufgaben im Radius sind erledigt. Navigieren Sie zurück zum Fahrzeug",
    item_duplicate: "Dieser Artikel befindet sich bereits in der Artikelliste",
    wrong_input: "Falsche Eingabe",
    selectType: "Bitte wählen Sie den Navigationstyp aus",
    add_new_item: "Artikel hinzufügen",
    all: "Alle",
    new_item: "Neuer Artikel",
    no_data_text: "Keine Daten verfügbar",
    quantity: "Menge",
    backup_created: "Das Backup wurde erfolgreich erstellt",
    import_type: "Import Typ",
    export_type: "Export Typ",
    input_gravedata: "Grabdaten eingeben",
    from_list: "Grab aus Liste auswählen",
    restore: "Wiederherstellen",
    grave_logec_id: "Logec Grab ID",
    task_logec_id: "Logec Aufgabe ID",
    from: "Von",
    until: "Bis",
    route_planning: "Routenplanung",
    navigation_type: "Navigationstyp",
    active: "Aktiv",
    backup: "Backup",
    contracts: "Aufträge",
    dialog_task_done: "Diese Aufgabe ist bereits abgeschlossen",
    dialog_ok: "ok",
    nav_next: "Navigieren Sie zur nächsten Aufgabe",
    address: "Adresse",
    completed_task_approval: "Genehmigung (Erledigte Aufgaben)",
    special_task_approval: "Genehmigung (neue Aufgaben)",
    approve: "Genehmigen",
    refresh: "Aktualisieren",
    users: "Benutzer",
    new_user: "Neue Benutzer",
    graveyards: "Friedhöfe",
    new_graveyard: "neuer Friedhof",
    new_customer: "Neukunde",
    task_approval: "Genehmigung (neue Aufgaben)",
    name: "Name",
    customers: "Kunden",
    no_connection: "Keine Internet-Verbindung",
    confirm_tasks: "Aufgabe bestätigen",
    responsible_employee: "Zuständiger Mitarbeiter",
    searching: "Suche nach Benutzerstandort...",
    location_found: "Benutzerstandort gefunden!",
    finish_work: "Arbeit beenden",
    break: "Pause einlegen",
    select_other: "Weitere Aufgaben auswählen",
    customer: "Kunde",
    descripion: "Beschreibung",
    start_navigation: "Navigation starten",
    first_on: "Erstmals fällig",
    base_path: "Basispfad für die Bilder (z.B. Pfad/zu/Bild/)",
    download_images: "Bilder herunterladen",
    set_path: "Pfad setzen",
    incomplete_task_data: "Unvollständige Aufgaben-Daten",
    comment: "Kommentar",
    special: "Besondere Informationen",
    csv_click_to_select: "Klicken Sie hier, um eine .csv-Datei auszuwählen",
    import: "Importieren",
    assign_number: "Grabnummer zuweisen",
    unassign_number: "Zuordnung löschen!",
    team: "Team",
    grave_type: "Grab-Typ",
    create: "Anlegen",
    create_new_task: "Neue Aufgabe erstellen",
    edit_task: "Aufgabe bearbeiten",
    edit_selected_tasks: "Ausgewählte Aufgaben bearbeiten",
    person: "Person",
    poi: "Berühmte Person",
    number: "Nummer",
    update_person: "Person aktualisieren",
    update_logec_id: "Logec ID aktualisieren",
    update_quantity: "Artikelmenge bearbeiten",
    type: "Typ",
    guest: "Gast-Anmeldung",
    start: "Beginn",
    end: "Ende",
    time_spent_on_breaks: "Pausenzeit",
    current_user: "Aktueller Benutzer",
    export: "Bericht exportieren",
    tasktype: "Aufgabentyp",
    role: "Rolle",
    confirm: "Bestätigen",
    duration: "Dauer (in Minuten)",
    database_id: "Datenbank-ID",
    grave_number_: "Grabnummer",
    deceased: "Verstorbener",
    assign: "Verknüpfen",
    username: "Benutzername",
    lastname: "Name",
    firstname: "Vorname",
    authorization: "Berechtigung",
    actions: "Aktionen",
    edit: "Bearbeiten",
    lock: "Sperre",
    delete: "Löschen",
    teamleader: "Teamleiter",
    task_type: "Aufgabentyp",
    team_name: "Teamname",
    cancel: "Abbrechen",
    yes: "Ja",
    add: "Hinzufügen",
    update: "Aktualisieren",
    delete_question_user: "Diesen Benutzer löschen?",
    delete_question_team: "Dieses Team löschen?",
    delete_question_customer: "Diesen Kunden löschen?",
    delete_question_graveyard: "Diesen Friedhof löschen?",
    create_question_grave:
      "Soll an dieser Position ein neues Grab angelegt werden?",
    date: "Datum",
    employee: "Mitarbeiter",
    completed_on: "Durchgeführt / bestätigt",
    search: "Suchen",
    selection: "Auswahl",
    next: "Nächste Ausführung",
    last: "Letzte Ausführung",
    description: "Beschreibung",
    graveyard: "Friedhof",
    interval: "Ausführung",
    task_select: "Wählen Sie eine Aufgabe aus",
    close: "Schließen",
    task: "Aufgabe",
    grave_details: "Grabdetail",
    done: "erledigt?",
    save: "Speichern",
    create_task: "Aufgabe erfassen",
    grave_info: "Grabinfo",
    task_description: "Aufgabenbeschreibung",
    grave_number: "Grabnummer: ",
    task_details: "Aufgabendetail",
    in_progress: "In Arbeit",
    grave: "Grab",
    graves: "Gräber",
    current_tasks: "Aktuelle Aufgaben",
    language: "Sprache",
    no_results: "Keine Suchergebnisse",
    error: {
      import: "Die folgenden Gräber konnten nicht gefunden werden:",
      backup: "Backup-Fehler",
      login_username: "Der Benutzername muss 1 Schrägstrich enthalten",
      password_rules: "Min. 8 Zeichen",
      password_rules_complex:
        "Min. 8 Zeichen, 2 Großbuchstaben und 1 Sonderzeichen",
      number_rules: "Nur Zahlen, darf nicht mit 0 beginnen",
      username_rule:
        "Der Benutzername darf keine Leerzeichen und Sonderzeichen enthalten",
      graveyard_rule: "Der Friedhofsname darf keine Leerzeichen enthalten",
      team_rule: "Der Teamname muss aus mindestens 2 Zeichen bestehen",
      customer_rule: "Der Kundenname darf keine Leerzeichen enthalten",
      enter_password: "Passwort eingeben",
      enter_user: "Benutzer eingeben",
      characters_5: "Mindestens 5 Zeichen",
      characters_3: "Mindestens 3 Zeichen",
      wrong_password: "Falsches Passwort",
      login_unsuccessful: "Ihr Login-Versuch war nicht erfolgreich.",
      account_locked: "Ihr Konto ist gesperrt.",
      customer_locked: "Dieser Kunde ist gesperrt worden.",
      dialog_teamname_taken: "Es gibt bereits ein Team mit diesem Namen",
      dialog_team_user_duplicate:
        "Dieser Benutzer wurde bereits zum Team hinzugefügt",
      username: "Benutzername bereits vergeben",
      duplicate_customer: "Kundenname bereits vergeben",
      duplicate_graveyard: "Friedhofsname bereits vergeben",
      dialog_taskType: "Dieser Aufgabentyp ist diesem Grab bereits zugewiesen",
      noImages: "Es gibt keine Bilder für die ausgewählten Gräber",
    },
    dashboard: {
      map: "Karte",
      taskgroups: "Aufgaben",
      navigation: "Navigation",
    },
    login: {
      header: "Bitte melden Sie sich an",
      user: "Kunde/Benutzer",
      password: "Passwort",
      login: "Anmelden",
    },
    hours: {
      startWork: "Arbeit beginnen",
      endWork: "Arbeit beenden",
      startBreak: "Start Pause",
      endBreak: "Stopp-Pause",
    },
    menu: {
      active: "Nur aktive Aufgaben",
      overview: "Übersicht",
      sync: "Datenbank ist aktuell",
      groups: {
        tasks: {
          header: "Aufgaben",
          schedule: "Aufgabenplanung",
          newTasks: "Neue Aufgaben",
          incomplete: "Unerledigte Aufgaben",
          completed: "Erledigte Aufgaben",
        },
        teams: {
          header: "Teams",
          organize: "Teams einteilen",
        },
        employees: {
          header: "Mitarbeiter",
          manage: "Mitarbeiterverwaltung",
          delete_question: "Diesen Benutzer löschen?",
        },
        working_hours: {
          header: "Arbeitsstunden",
          working_hours: "Arbeitsstunden",
        },
        map: {
          header: "Verwaltung",
          assign: "Grabzuordnung",
          add: "Gräber hinzufügen",
        },
      },
    },
    menu_settings: {
      settings: "Einstellungen",
      logout: "Abmelden",
    },
  },
};
