<template>
  <v-container fluid class="pa-0" style="background-color: #ffffff">
    <v-flex xs12>
      <GraveImage :graveId="grave.id" :key="Date.now()" :canUpload="true" />

      <span class="subheading ml-5">{{ $t("grave_info") }}</span>
      <v-card flat>
        <v-container grid-list-xl fluid>
          <v-layout wrap>
            <v-flex xs12 class="py-0 mx-5">
              <v-text-field
                class="pt-5"
                v-model="grave.ord1"
                label="Ord1"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="py-0 mx-5">
              <v-text-field
                class="pt-5"
                v-model="grave.ord2"
                label="Ord2"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="py-0 mx-5">
              <v-text-field
                class="pt-5"
                v-model="grave.ord3"
                label="Ord3"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="py-0 mx-5">
              <v-text-field
                class="pt-5"
                v-model="grave.ord4"
                label="Ord4"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

      <v-divider light></v-divider>
      <v-spacer class="mt-2"></v-spacer>

      <span class="subheading ml-5">{{ $t("customer") }}</span>
      <v-card flat>
        <v-container grid-list-xl fluid>
          <v-layout wrap>
            <v-flex xs12 class="py-0 mx-5">
              <v-text-field
                class="pt-5"
                v-model="grave.firstname"
                :label="$t('firstname')"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="py-0 mx-5">
              <v-text-field
                class="pt-5"
                v-model="grave.lastname"
                :label="$t('lastname')"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="py-0 mx-5">
              <v-text-field
                class="pt-5"
                v-model="grave.address"
                :label="$t('address')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

      <v-divider light></v-divider>
      <v-spacer class="mt-2"></v-spacer>

      <span class="subheading ml-5">{{ $t("description") }}</span>

      <v-card flat>
        <v-container grid-list-xl fluid>
          <v-layout wrap>
            <v-flex xs12 class="py-0 mx-5">
              <v-textarea
                class="body-1 mb-4"
                v-model="grave.description"
                :hint="$t('description')"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-flex>
    <v-btn
      bottom
      block
      color="green"
      class="white--text"
      fixed
      :disabled="!readyToSubmit"
      @click="addContract()"
      style="bottom: 0px"
      app
    >
      {{ $t("create") }}
    </v-btn>
  </v-container>
</template>

<script>
import GraveImage from "@components/grave/GraveImage.vue";

export default {
  name: "NewContract",
  props: {
    grave: Object,
  },
  components: { GraveImage },
  data() {
    return {};
  },
  computed: {
    readyToSubmit() {
      return ["ord1", "firstname", "lastname", "address"].every(
        (prop) => this.grave[prop]
      );
    },
  },
  methods: {
    async addContract() {
      let formData = new FormData();
      formData.set("addContract", true);
      formData.set("grave_id", this.grave.id);
      formData.set("ord1", this.grave.ord1);
      formData.set("ord2", this.grave.ord2);
      formData.set("ord3", this.grave.ord3);
      formData.set("ord4", this.grave.ord4);
      formData.set("firstname", this.grave.firstname);
      formData.set("lastname", this.grave.lastname);
      formData.set("address", this.grave.address);
      if (!this.grave.description) this.grave.description = "";
      formData.set("description", this.grave.description);
      await this.$root.postData(formData);
      this.$emit("contractAdded");
    },
  },
  mounted() {},
};
</script>
