<template>
  <v-card class="elevation-5 mx-5 mb-5">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      return-object
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageAllText: $t('all'),
        'items-per-page-options': [10, 100, 200, 500, -1],
      }"
      dense
    >
      <template #item.Friedhof="{ item }">{{ item.Friedhof }}</template>
      <template #item.Grab="{ item }">
        {{ item.Grab }}
      </template>
      <template #item.grave_logec_id="{ item }">
        {{ item.grave_logec_id }}
      </template>
      <template #item.Ord="{ item }">{{ item.Ord }}</template>
      <template #item.sArtikelNr="{ item }">
        {{ item.sArtikelNr }}
      </template>
      <template #item.Artikel="{ item }">{{ item.Artikel }}</template>
      <template #item.fMenge="{ item }">
        {{ item.fMenge }}
      </template>
      <template #item.Bem="{ item }">
        {{ item.Bem }}
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>
      <template slot="no-results">
        {{ $t("no_results") }}
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_text") }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "PflanzenTable",
  props: {
    items: Array,
  },
  computed: {
    headers() {
      return [
        { text: "Friedhof", value: "Friedhof", sortable: true },
        { text: "Grab", value: "Grab", sortable: true },
        { text: "G_NR", value: "grave_logec_id", sortable: true },
        { text: "Ord1", value: "ord1", sortable: true },
        { text: "Ord2", value: "ord2", sortable: true },
        { text: "Ord3", value: "ord3", sortable: true },
        { text: "Ord4", value: "ord4", sortable: true },
        { text: "sArtikelNr", value: "sArtikelNr", sortable: true },
        { text: "Artikel", value: "Artikel", sortable: true },
        { text: "fMenge", value: "fMenge", sortable: true },
        { text: "Bem", value: "Bem", sortable: true },
      ];
    },
  },
};
</script>
