import moment from "moment";
import distance from "@turf/distance";
import nearestPointOnLine from "@turf/nearest-point-on-line";

const turf = { distance: distance, nearestPointOnLine: nearestPointOnLine };

export function allValuesAssigned(object) {
  return (
    object &&
    Object.values(object).every(
      (value) => value !== undefined && value !== null && value !== ""
    )
  );
}

export function getFormattedDate(date) {
  if (date != null && date != "") return moment(date).format("DD/MM/YYYY");
  else return "";
}

export function makeStringFromArray(array) {
  let parsedTeams = JSON.parse(array);

  if (parsedTeams[0].id != null) {
    let formattedTeams = "";

    parsedTeams.forEach((team) => {
      formattedTeams = formattedTeams + team.name + ", ";
    });

    return formattedTeams.substring(0, formattedTeams.length - 2);
  } else return "";
}

export function toFeature(coords) {
  const feature = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: coords,
    },
  };
  return feature;
}

export function getFeatureCoordinates(feature) {
  return feature.geometry.coordinates;
}

export function closestFeature(position, features) {
  let minDistance = Number.MAX_VALUE;
  let closestFeature = null;

  features.forEach((feature) => {
    const clickDistance = this.getDistanceInMeters(position, [
      feature.geometry.coordinates[0],
      feature.geometry.coordinates[1],
    ]);
    if (clickDistance < minDistance) {
      minDistance = clickDistance;
      closestFeature = feature;
    }
  });

  return minDistance < 3 ? closestFeature : false;
}

export function getNearestPointOnLine(feature_1, feature_2) {
  return turf.nearestPointOnLine(feature_1, feature_2);
}

export function getDistance(feature_1, feature_2) {
  return turf.distance(feature_1, feature_2);
}

export function getDistanceInMeters(feature_1, feature_2) {
  return turf.distance(feature_1, feature_2) * 1000;
}

export function getDistanceInMetersOnPath(pathfinder, feature_1, feature_2) {
  let pathResult = pathfinder.findPath(feature_1, feature_2);

  if (pathResult === null) {
    return Number.MAX_VALUE;
  } else {
    return pathResult.weight * 1000;
  }
}

export function getImage(id) {
  const baseUrl = process.env.VUE_APP_PROXY_TARGET;
  return `${baseUrl}/graveImage.php?id=${id}`;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
