<template>
  <v-card class="elevation-5">
    <v-card-title>
      <v-text-field
        v-model="searchFieldContent"
        prepend-icon="search"
        :label="$t('search')"
        single-line
        hide-details
        left
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="customers"
      :search="searchFieldContent"
      @click:row="selectCustomer"
      :item-class="itemRowBackground"
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageAllText: $t('all'),
        'items-per-page-options': [10, 100, 200, 500, -1],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ $t("customers") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="newCustomerDialog = true"
          >
            {{ $t("new_customer") }}
          </v-btn>
          <NewCustomerDialog
            v-if="newCustomerDialog"
            @createCustomer="createCustomer"
            @cancel="newCustomerDialog = false"
          />
        </v-toolbar>
      </template>

      <template #item.name="{ item }">{{ item.name }}</template>

      <template #item.delete="{ item }">
        <v-btn
          fab
          small
          dark
          :color="isLocked(item) ? 'red' : 'blue'"
          @click="changeCustomerStatus(item.id)"
        >
          <v-icon>{{ isLocked(item) ? "lock" : "lock_open" }}</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>
      <template slot="no-results">
        {{ $t("no_results") }}
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_text") }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { useAppStateStore } from "@store/appState.js";
import NewCustomerDialog from "@components/customers/dialogs/NewCustomerDialog.vue";
export default {
  name: "CustomersTable",
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
  components: {
    NewCustomerDialog,
  },
  props: {
    customers: Array,
  },
  data() {
    return {
      searchFieldContent: "",
      newCustomerDialog: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("name"), value: "name", sortable: true },
        { text: this.$t("lock"), value: "delete", sortable: false },
      ];
    },
  },
  methods: {
    isLocked(item) {
      return !item.active;
    },
    itemRowBackground(item) {
      return !!item.active ? "" : "blue-grey lighten-4";
    },
    async selectCustomer(customer, related) {
      this.$emit("customerSelected", customer);
    },
    async createCustomer(customerName) {
      let formData = new FormData();
      formData.set("createCustomer", true);
      formData.set("name", customerName);

      try {
        const response = await this.$root.postData(formData);

        if (response.error) {
          this.AppStateStore.createError("Customers", "customerName");
        }
        this.newCustomerDialog = false;
        this.$emit("customerCreated");
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async changeCustomerStatus(customer_id) {
      let formData = new FormData();
      formData.set("changeCustomerStatus", true);
      formData.set("customer_id", customer_id);

      await this.$root.postData(formData);
      this.$emit("customerStatusChanged");
    },
  },
};
</script>
