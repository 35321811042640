<template>
  <v-container fluid fill-height style="background-color: #2196f3">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4 class="text-xs-center">
        <v-img
          :src="require('@assets/logo.png')"
          max-width="100"
          max-height="100"
          aspect-ratio="1"
          class="ma-auto"
        ></v-img>
        <h5 class="headline white--text text-center pa-3 mt-1">
          {{ $t("login.header") }}
        </h5>
        <v-alert :value="error" type="error">{{ errorMessage }}</v-alert>
        <v-card class="pa-2">
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="username"
                required
                prepend-icon="person"
                name="login"
                :label="$t('login.user')"
                type="text"
                @keypress.enter="login"
              ></v-text-field>
              <v-text-field
                v-model="password"
                required
                id="password"
                prepend-icon="lock"
                name="password"
                :label="$t('login.password')"
                type="password"
                @keypress.enter="tryLogin(username, password)"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="tryLogin(username, password)">
              {{ $t("login.login") }}
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-3"></v-divider>
          <div class="body-2 grey--text text-uppercase pt-2">
            &copy; 2019 Gärtnerei Herbert Wörner GmbH
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
export default {
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data: () => ({
    username: "",
    password: "",
    error: false,
    errorMessage: false,
    valid: true,
  }),
  methods: {
    async login(response) {
      let count = (this.username.match(/\//g) || []).length;

      if (count !== 1) {
        this.error = true;
        this.errorMessage = this.$t("error.login_username");
      } else if (response.valid_username) {
        if (!response.active) {
          this.error = true;
          this.errorMessage = this.$t("error.customer_locked");
        } else if (response.locked) {
          this.error = true;
          this.errorMessage = this.$t("error.account_locked");
        } else if (response.valid_password) {
          this.error = false;

          this.UserStore.$patch({
            customer: {
              id: response.customer_id,
              name: response.customer_name,
            },
            user: {
              id: response.user_id,
              username: response.username,
              fullname: response.fullname,
              role: {
                id: response.role_id,
                name_de: response.role_de,
                name_en: response.role_en,
              },
            },
          });

          this.$router.push({
            name: "dashboard",
            query: { redirect: "/dashboard" },
          });
        } else {
          this.error = true;
          this.errorMessage = this.$t("error.wrong_password");
        }
      } else {
        this.error = true;
        this.errorMessage = this.$t("error.login_unsuccessful");
      }
    },

    async tryLogin(username, password) {
      let formData = new FormData();

      formData.set("login", true);
      formData.set("loginSecurePARAM", true);
      formData.set("username", username);
      formData.set("password", password);

      try {
        const response = await this.$root.postData(formData);
        this.login(response);
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
};
</script>
