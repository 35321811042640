import Vue from "vue";
import Router from "vue-router";
import Home from "@views/Home.vue";
import Login from "@views/Login.vue";
import Dashboard from "@views/Dashboard.vue";
import Schedule from "@views/Schedule.vue";
import Completed from "@views/Completed.vue";
import Teams from "@views/Teams.vue";
import Employees from "@views/Employees.vue";
import GraveAssignment from "@views/GraveAssignment.vue";
import NewGraves from "@views/NewGraves.vue";
import Contracts from "@views/Contracts.vue";
import WorkingHours from "@views/WorkingHours.vue";
import Guest from "@views/Guest.vue";
import LogecExport from "@views/LogecExport.vue";
import LogecImport from "@views/LogecImport.vue";
import Graves from "@views/Graves.vue";
import Customers from "@views/Customers.vue";
import TaskApproval from "@views/TaskApproval.vue";
import CompletedTaskApproval from "@views/CompletedTaskApproval.vue";
import Backup from "@views/Backup.vue";
import FieldManager from "@views/FieldManager.vue";
import IncompleteTasks from "@views/IncompleteTasks.vue";
import NewTasks from "@views/NewTasks.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "index",
      component: Home,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/workingHours",
      name: "workingHours",
      meta: { requiresAuth: true },
      component: WorkingHours,
    },
    {
      path: "/dashboard",
      name: "dashboard",
      meta: { requiresAuth: true },
      component: Dashboard,
    },
    {
      path: "/schedule",
      name: "schedule",
      meta: { requiresAuth: true },
      component: Schedule,
    },
    {
      path: "/completed",
      name: "completed",
      meta: { requiresAuth: true },
      component: Completed,
    },
    {
      path: "/teams",
      name: "teams",
      meta: { requiresAuth: true },
      component: Teams,
    },
    {
      path: "/employees",
      name: "employees",
      meta: { requiresAuth: true },
      component: Employees,
    },
    {
      path: "/logecExport",
      name: "logecExport",
      meta: { requiresAuth: true },
      component: LogecExport,
    },
    {
      path: "/logecImport",
      name: "logecImport",
      meta: { requiresAuth: true },
      component: LogecImport,
    },
    {
      path: "/graves",
      name: "graves",
      meta: { requiresAuth: true },
      component: Graves,
    },
    {
      path: "/customers",
      name: "customers",
      meta: { requiresAuth: true },
      component: Customers,
    },
    {
      path: "/taskApproval",
      name: "taskApproval",
      meta: { requiresAuth: true },
      component: TaskApproval,
    },
    {
      path: "/completedTaskApproval",
      name: "completedTaskApproval",
      meta: { requiresAuth: true },
      component: CompletedTaskApproval,
    },
    {
      path: "/guest",
      name: "guest",
      meta: { requiresAuth: false },
      component: Guest,
    },
    {
      path: "/graveAssignment",
      name: "graveAssignment",
      meta: { requiresAuth: true },
      component: GraveAssignment,
    },
    {
      path: "/newGraves",
      name: "newGraves",
      meta: { requiresAuth: true },
      component: NewGraves,
    },
    {
      path: "/backup",
      name: "backup",
      meta: { requiresAuth: true },
      component: Backup,
    },
    {
      path: "/contracts",
      name: "contracts",
      meta: { requiresAuth: true },
      component: Contracts,
    },
    {
      path: "/fieldManager",
      name: "fieldManager",
      meta: { requiresAuth: true },
      component: FieldManager,
    },
    {
      path: "/incompleteTasks",
      name: "incompleteTasks",
      meta: { requiresAuth: true },
      component: IncompleteTasks,
    },
    {
      path: "/newTasks",
      name: "newTasks",
      meta: { requiresAuth: true },
      component: NewTasks,
    },
  ],
});
