<template>
  <div>
    <!-- Openlayers map -->

    <vl-map
      ref="map"
      data-projection="EPSG:4326"
      style="position: absolute; background-color: #868686"
      :move-tolerance="2"
      @singleclick="mapClick"
    >
      <!-- GPS syncronized view -->
      <vl-view
        :zoom.sync="zoom"
        :center.sync="center"
        :rotation.sync="rotation"
        :min-zoom="11"
        :max-zoom="21"
      ></vl-view>

      <!-- OSM raster layer-->
      <vl-layer-tile :z-index="0">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>
      <!--Orthofoto raster layer -->
      <vl-layer-tile :z-index="1">
        <vl-source-xyz :url="url"></vl-source-xyz>
      </vl-layer-tile>

      <!-- Graves -->
      <vl-layer-vector
        v-if="gravesToDisplay.length"
        render-mode="image"
        :overlay="true"
      >
        <template>
          <vl-source-vector :features="gravesToDisplay"></vl-source-vector>
          <vl-style-box :z-index="1">
            <vl-style-circle :radius="6">
              <vl-style-fill color="#76FF03"></vl-style-fill>
            </vl-style-circle>
          </vl-style-box>
        </template>
      </vl-layer-vector>

      <!-- Foot route padding  -->
      <Route
        v-if="
          !AppStateStore.getAppLoading && locationSnappedToFootPath.geometry
        "
        :route="[
          mainRoute[0],
          [
            parseFloat(locationSnappedToFootPath.geometry.coordinates[0]),
            parseFloat(locationSnappedToFootPath.geometry.coordinates[1]),
          ],
        ]"
        :routeColor="'#2979ff'"
      />

      <!-- Route to the next grave -->
      <Route
        v-if="!AppStateStore.getAppLoading && mainRoute && navigating"
        :route="mainRoute"
        :routeColor="'#2979ff'"
      />

      <!-- User on foot real location -->
      <MapFeature
        :coordinates="getFeatureCoordinates($root.location)"
        :color="'#304fff'"
        :featureRadius="10"
        :border="{ display: true, width: 2, color: 'white' }"
      />

      <!-- Search bar on top-->
      <v-autocomplete
        v-if="gravesToDisplay"
        v-model="selectedGrave"
        :items="gravesToDisplay"
        item-text="person"
        filled
        :label="$t('search')"
        hide-details
        return-object
        style="background-color: white"
      ></v-autocomplete>
      <v-btn
        color="primary"
        ripple
        :disabled="!isNavigationReady"
        block
        @click="startInitPath()"
      >
        {{ $t("start_navigation") }}
      </v-btn>

      <!-- Bottom Menu -->
      <BottomMenu
        v-if="currentTab === 'map' && selectedGrave != null"
        :marginBottom="'0px'"
        @handleGraveReached="handleGraveReached"
        @toggle="toggleBottomMenu"
        :toggled="bottomMenuToggled"
        :grave="selectedGrave"
        :displayBottomMenu="currentTab === 'map' && selectedGrave != null"
      />
    </vl-map>
  </div>
</template>

<script>
import { EventBus } from "@events/EventBus";
import Geolocation from "ol/Geolocation.js";
import { useAppStateStore } from "@store/appState.js";
import { useRouteStore } from "@store/route.js";
import {
  toFeature,
  getFeatureCoordinates,
  closestFeature,
  getDistance,
  getDistanceInMeters,
  getDistanceInMetersOnPath,
  getNearestPointOnLine,
} from "@utils/utilFunctions";
import BottomMenu from "@components/navigation/BottomMenu.vue";
import MapFeature from "@components/map/MapFeature";
import Route from "@components/navigation/Route";
export default {
  name: "GuestMap",
  props: {
    tabToGraveWithToolbar: Function,
    currentTab: String,
  },
  components: { BottomMenu, MapFeature, Route },
  setup() {
    const AppStateStore = useAppStateStore();
    const RouteStore = useRouteStore();
    return { AppStateStore, RouteStore };
  },
  data() {
    return {
      bottomMenuToggled: false,
      zoom: 17,
      center: [10.92623, 48.37976],
      rotation: 0,
      geolocation: "",
      url: "/tiles/{z}/{x}/{y}.jpg",
      gravesToDisplay: [],
      selectedGrave: null,
      navigating: false,
      mainRoute: "",
      locationOnPath: "",
      locationSnappedToFootPath: "",
      closestFootRouteSegment: "",
    };
  },
  computed: {
    isNavigationReady() {
      return this.selectedGrave;
    },
  },
  methods: {
    toFeature,
    getFeatureCoordinates,
    closestFeature,
    getDistance,
    getDistanceInMeters,
    getDistanceInMetersOnPath,
    getNearestPointOnLine,
    mapClick(event) {
      const clickpos = event.coordinate;
      const clicked = this.closestFeature(clickpos, this.gravesToDisplay);
      if (clicked) {
        this.selectedGrave = clicked;
        this.bottomMenuToggled = true;
      }
    },
    toggleBottomMenu() {
      this.bottomMenuToggled = !this.bottomMenuToggled;
    },
    navigate() {
      this.updateFootPathToGrave();
    },
    updateFootPathToGrave() {
      this.mainRoute = this.RouteStore.getFootPathFinder.findPath(
        this.locationSnappedToFootPath,
        this.selectedGrave
      ).path;
    },
    updateLocationSnappedToFootPath() {
      let closestPoint = this.toFeature(
        this.closestFootRouteSegment.geometry.coordinates[1]
      );

      if (this.closestFootRouteSegment.geometry.coordinates.length >= 2) {
        if (
          this.getDistanceInMeters(
            this.$root.location,
            this.toFeature(this.closestFootRouteSegment.geometry.coordinates[0])
          ) <
          this.getDistanceInMeters(
            this.$root.location,
            this.toFeature(this.closestFootRouteSegment.geometry.coordinates[1])
          )
        )
          closestPoint = this.toFeature(
            this.closestFootRouteSegment.geometry.coordinates[0]
          );
        else
          closestPoint = this.toFeature(
            this.closestFootRouteSegment.geometry.coordinates[1]
          );
      }

      this.locationSnappedToFootPath = JSON.parse(JSON.stringify(closestPoint));
    },
    updateLocationOnPath() {
      this.closestFootRouteSegment = JSON.parse(
        JSON.stringify(this.RouteStore.getFootRoute.features[0])
      );

      let minDistance = this.getDistance(
        this.$root.location,
        this.closestFootRouteSegment.geometry.coordinates[0]
      );

      this.RouteStore.getFootRoute.features.forEach((line) => {
        line.geometry.coordinates.forEach((lineSegmentCoord) => {
          let distance = this.getDistance(
            this.$root.location,
            lineSegmentCoord
          );
          if (distance < minDistance) {
            minDistance = distance;
            this.closestFootRouteSegment = JSON.parse(JSON.stringify(line));
          }
        });
      });

      this.locationOnPath = this.getNearestPointOnLine(
        this.closestFootRouteSegment,
        this.$root.location
      );
    },
    calculateLocationOnPath() {
      this.updateLocationOnPath();
      this.updateLocationSnappedToFootPath();
    },
    isGraveReached() {
      let distanceFromGrave = this.getDistanceInMetersOnPath(
        this.RouteStore.getFootPathFinder,
        this.locationSnappedToFootPath,
        this.selectedGrave
      );

      let realDistance = this.getDistanceInMeters(
        this.$root.location,
        this.selectedGrave
      );

      if (distanceFromGrave < 5 && realDistance < 3) return true;
      return false;
    },
    async getGuestGraves() {
      let formData = new FormData();
      formData.set("getGuestGraves", true);

      try {
        const response = await this.$root.postData(formData);
        this.gravesToDisplay = response;

        this.gravesToDisplay.forEach((grave) => {
          grave.geometry.coordinates[0] = parseFloat(
            grave.geometry.coordinates[0]
          );
          grave.geometry.coordinates[1] = parseFloat(
            grave.geometry.coordinates[1]
          );
        });
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },

    async startInitPath() {
      await this.InitPath();
      this.bottomMenuToggled = true;
    },

    InitPath() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.AppStateStore.activateServerLoading();
          this.navigating = true;
          this.calculateLocationOnPath();
          this.navigate();
          this.AppStateStore.resetServerLoading();
          resolve();
        }, 100);
      });
    },
    initGeolocation() {
      let self = this;
      this.geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      });

      window.addEventListener(
        "deviceorientationabsolute",
        this.manageCompass,
        true
      );
    },
    manageCompass(event) {
      if (event.webkitCompassHeading) {
        this.rotation = this.degrees_to_radians(event.webkitCompassHeading);
      } else {
        this.rotation = this.degrees_to_radians(event.alpha);
      }
    },
    degrees_to_radians(degrees) {
      let pi = Math.PI;
      return degrees * (pi / 180);
    },
    handleGraveReached() {
      this.navigating = false;
      this.bottomMenuToggled = false;
      this.selectedGrave.reached = true;
      this.tabToGraveWithToolbar(this.selectedGrave.id);
    },
    setGeolocationListener() {
      this.geolocation.on("change:position", async () => {
        if (this.AppStateStore.getAppLoading) {
          return;
        }

        const newLocation = this.geolocation.getPosition();
        const newLocationFeature = this.toFeature(newLocation);

        if (this.$root.location !== newLocationFeature) {
          try {
            this.AppStateStore.activateAppLoading();
            this.$root.location = newLocationFeature;
            this.center = this.getFeatureCoordinates(newLocationFeature);

            if (this.navigating) {
              this.calculateLocationOnPath();

              if (this.isGraveReached()) {
                this.handleGraveReached();
              } else {
                this.navigate();
              }
            }
          } catch (error) {
            console.error("Error processing geolocation change:", error);
          } finally {
            this.AppStateStore.resetAppLoading();
          }
        }
      });
    },
    toGrave() {
      this.navigating = false;
      this.bottomMenuToggled = false;
      this.selectedGrave.reached = true;
      this.tabToGraveWithToolbar(this.selectedGrave.id);
    },
    async initMap() {
      this.initGeolocation();
      this.setGeolocationListener();
      await this.getGuestGraves();
    },
  },
  async mounted() {
    EventBus.$on("refreshComponent", this.initMap);
    this.initMap();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.initMap);
  },
  async destroyed() {
    this.geolocation.setTracking(false);
  },
};
</script>
