<template>
  <v-main style="padding-top: 68px; background-color: #868686">
    <!-- Openlayers map -->
    <vl-map
      options
      ref="map"
      data-projection="EPSG:4326"
      style="position: relative; background-color: #868686"
      :move-tolerance="2"
      @singleclick="singleClick"
    >
      <!-- GPS syncronized view -->
      <vl-view
        :zoom.sync="mapProperties.zoom"
        :center.sync="mapProperties.center"
        :rotation.sync="mapProperties.rotation"
        :min-zoom="1"
        :max-zoom="23"
      ></vl-view>

      <!-- OSM raster layer
      <vl-layer-tile :z-index="0">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>-->
      <!--Orthofoto raster layer -->
      <vl-layer-tile>
        <vl-source-xyz :url="mapProperties.url"></vl-source-xyz>
      </vl-layer-tile>

      <!-- User on foot real location -->
      <MapFeature
        :coordinates="getFeatureCoordinates($root.location)"
        :color="'#304fff'"
        :featureRadius="10"
        :border="{ display: true, width: 2, color: 'white' }"
      />

      <MapFeatureCollection
        :features="graves.new"
        :icon="{ display: true, path: 'goal_unassigned.svg' }"
      />

      <MapFeatureCollection
        :features="graves.assigned"
        :icon="{ display: true, path: 'goal.svg' }"
      />

      <v-layout row justify-center>
        <v-dialog v-model="dialogs.assign" max-width="400">
          <v-card>
            <v-flex xs12 class="py-0">
              <v-select
                v-model="unassignedGraveData"
                :items="unassignedGraveList"
                item-text="number"
                return-object
                :label="$t('grave_number_')"
                required
              ></v-select>
            </v-flex>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                :disabled="!unassignedGraveData.number"
                color="blue"
                style="width: 100%"
                @click="assignNumber(newGrave)"
              >
                {{ $t("assign_number") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog v-model="dialogs.graveAction" max-width="400">
          <template>
            <v-card>
              <v-card-actions>
                <v-btn
                  color="blue"
                  style="width: 100%"
                  @click="switchToAssignNumber()"
                >
                  {{ $t("from_list") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
          <template>
            <v-card>
              <v-card-actions>
                <v-btn
                  color="blue"
                  style="width: 100%"
                  @click="deleteGrave(newGrave.grave.id)"
                >
                  {{ "Delete grave" }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-layout>
    </vl-map>

    <v-layout row justify-center>
      <v-dialog v-model="dialogs.confirmCreate" persistent max-width="400">
        <v-card text>
          <v-card-title class="justify-center title">
            {{ $t("create_question_grave") }}
          </v-card-title>
          <v-card-actions>
            <v-btn text @click="dialogs.confirmCreate = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                createGrave(newGrave.position);
                dialogs.confirmCreate = false;
              "
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import Geolocation from "ol/Geolocation.js";
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";

import MapFeature from "@components/map/MapFeature.vue";
import MapFeatureCollection from "@components/map/MapFeatureCollection.vue";

import {
  toFeature,
  getFeatureCoordinates,
  closestFeature,
  getDistanceInMeters,
} from "@utils/utilFunctions";

export default {
  name: "NewGraves",
  setup() {
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { UserStore, AppStateStore };
  },
  components: { MapFeature, MapFeatureCollection },
  data() {
    return {
      geolocation: "",
      mapProperties: {
        zoom: 17,
        center: [10.92623, 48.37976],
        rotation: 0,
        url: "/tiles/{z}/{x}/{y}.jpg",
      },
      graves: {
        new: [],
        assigned: [],
      },
      dialogs: {
        confirmCreate: false,
        graveAction: false,
        assign: false,
      },
      newGrave: "",
      unassignedGraveList: [],
      unassignedGraveData: "",
      refreshTimer: 0,
    };
  },
  methods: {
    toFeature,
    getFeatureCoordinates,
    closestFeature,
    getDistanceInMeters,
    async assignNumber(grave) {
      let formData = new FormData();
      formData.set("assignNumberNewGrave", true);
      formData.set("id", this.unassignedGraveData.id);
      formData.set("grave_id", grave.id);
      formData.set("ord1", this.unassignedGraveData.ord1);
      formData.set("ord2", this.unassignedGraveData.ord2);
      formData.set("ord3", this.unassignedGraveData.ord3);
      formData.set("ord4", this.unassignedGraveData.ord4);
      formData.set("person", this.unassignedGraveData.person);
      formData.set("logec_id", this.unassignedGraveData.logec_id);

      await this.$root.postData(formData);
      this.getNewGravesToDisplay();
      this.unassignedGraveData = "";
      this.getUnassignedGraveNumbers();
      this.dialogs.assign = false;
    },
    async unassignNumber(id) {
      let formData = new FormData();
      formData.set("unassignNumber", true);
      formData.set("id", id);

      await this.$root.postData(formData);
      this.getNewGravesToDisplay();
    },

    async getNewGravesToDisplay() {
      const formData = new FormData();
      formData.set("getMapAdminGravesToDisplay", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set(
        "location",
        JSON.stringify(this.getFeatureCoordinates(this.$root.location))
      );

      try {
        const response = await this.$root.postData(formData);

        const graveTypes = ["new", "new_done"];

        graveTypes.forEach((type) => {
          this.graves[type] = response[type];
          this.graves[type].forEach((grave) => {
            grave.geometry.coordinates =
              grave.geometry.coordinates.map(parseFloat);
          });
        });
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    closestFeatureFromAll(position) {
      let closestGrave = null;
      let minDistance = Number.MAX_VALUE;
      let closestType = "";

      const graveCollections = [
        { graves: this.graves.new, type: "new" },
        { graves: this.graves.assigned, type: "assigned" },
      ];

      graveCollections.forEach(({ graves, type }) => {
        const closestGraveInCollection = closestFeature.call(
          this,
          position,
          graves
        );
        if (closestGraveInCollection) {
          const clickDistance = this.getDistanceInMeters(position, [
            closestGraveInCollection.geometry.coordinates[0],
            closestGraveInCollection.geometry.coordinates[1],
          ]);
          if (clickDistance < minDistance) {
            minDistance = clickDistance;
            closestGrave = closestGraveInCollection;
            closestType = type;
          }
        }
      });

      return minDistance < 3
        ? {
            clicked: true,
            grave: closestGrave,
            type: closestType,
            position: position,
          }
        : {
            clicked: false,
            position: position,
          };
    },

    switchToAssignNumber() {
      this.dialogs.graveAction = false;
      this.dialogs.assign = true;
    },

    async singleClick(event) {
      const clickpos = event.coordinate;

      this.newGrave = this.closestFeatureFromAll(clickpos);

      if (!this.newGrave.clicked) this.dialogs.confirmCreate = true;
      else {
        switch (this.newGrave.type) {
          case "new":
            this.dialogs.graveAction = true;
            break;
          case "assigned":
            this.dialogs.graveAction = true;
            break;
          default:
            break;
        }
      }
    },

    async createGrave(coordinates) {
      let formData = new FormData();
      formData.set("createGrave", true);
      formData.set("graveyard_id", this.$root.graveyard_id);
      formData.set("lon", coordinates[0]);
      formData.set("lat", coordinates[1]);

      await this.$root.postData(formData);
      this.getNewGravesToDisplay();
    },

    async deleteGrave(id) {
      let formData = new FormData();
      formData.set("deleteGrave", true);
      formData.set("id", id);

      await this.$root.postData(formData);
      this.dialogs.graveAction = false;
      this.getNewGravesToDisplay();
    },

    async getUnassignedGraveNumbers() {
      let formData = new FormData();
      formData.set("getUnassignedGraveNumbers", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set("graveyard_id", this.$root.graveyard_id);

      try {
        const response = await this.$root.postData(formData);
        this.unassignedGraveList = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },

    async initGeolocation() {
      this.geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      });

      this.geolocation.on("change:position", async () => {
        this.$root.location = this.$root.toFeature(
          this.geolocation.getPosition()
        );
        this.mapProperties.center = this.getFeatureCoordinates(
          this.$root.location
        );

        if (
          !this.AppStateStore.getServerLoading &&
          !this.dialogs.confirmCreate &&
          this.refreshTimer <= 0
        ) {
          await this.getNewGravesToDisplay();

          this.refreshTimer = 10;
        } else if (this.refreshTimer > 0) {
          this.refreshTimer--;
        }
      });
    },
  },

  mounted() {
    EventBus.$on("refreshComponent", this.initGeolocation);
    this.initGeolocation();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.initGeolocation);
  },
  async destroyed() {
    this.geolocation.setTracking(false);
  },
};
</script>
