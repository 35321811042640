<template>
  <v-card class="elevation-5">
    <v-data-table
      :headers="headers"
      :items="tasks"
      item-key="id"
      return-object
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageAllText: $t('all'),
        'items-per-page-options': [10, 100, 200, 500, -1],
      }"
      dense
    >
      <template #item.graveyard="{ item }">{{ item.graveyard }}</template>
      <template #item.ord1="{ item }">{{ item.ord1 }}</template>
      <template #item.ord2="{ item }">{{ item.ord2 }}</template>
      <template #item.ord3="{ item }">{{ item.ord3 }}</template>
      <template #item.ord4="{ item }">{{ item.ord4 }}</template>
      <template #item.grave_logec_id="{ item }">
        {{ item.grave_logec_id }}
      </template>
      <template #item.description="{ item }">
        {{ item.description }}
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>
      <template slot="no-results">
        {{ $t("no_results") }}
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_text") }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "PflegeGiessenTable",
  props: {
    tasks: Array,
  },
  computed: {
    headers() {
      return [
        { text: this.$t("graveyard"), value: "graveyard", sortable: true },
        { text: "Ord1", value: "ord1", sortable: true },
        { text: "Ord2", value: "ord2", sortable: true },
        { text: "Ord3", value: "ord3", sortable: true },
        { text: "Ord4", value: "ord4", sortable: true },
        {
          text: "G_NR",
          value: "grave_logec_id",
          sortable: true,
        },
        { text: this.$t("description"), value: "description", sortable: true },
      ];
    },
  },
};
</script>
