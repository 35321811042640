<template>
  <v-dialog :value="show" persistent max-width="600">
    <v-card text>
      <v-card-title class="justify-center text-center text-wrap">
        {{ $t("error.import") }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="invalidGraves"
        item-key="id"
        return-object
        :footer-props="{
          itemsPerPageText: '',
        }"
      >
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} /
          {{ items.itemsLength }}
        </template>
        <template slot="no-results">
          {{ $t("no_results") }}
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_text") }}
        </template>
      </v-data-table>
      <v-btn color="info" block @click="$emit('close')">
        {{ "OK" }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImportErrorDialog",
  props: {
    show: Boolean,
    invalidGraves: Array,
  },
  computed: {
    headers() {
      return [
        { text: "Ord1", value: "ord1", sortable: true },
        { text: "Ord2", value: "ord2", sortable: true },
        { text: "Ord3", value: "ord3", sortable: true },
        { text: "Ord4", value: "ord4", sortable: true },
      ];
    },
  },
};
</script>
