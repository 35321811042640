<template>
  <v-container fluid class="pt-6">
    <DialogNav
      v-if="dialogNavGrave && displayDialogNav"
      :grave="dialogNavGrave"
      :getGrave="getGrave"
      :displayDialogNav="displayDialogNav"
      :closeDialogNav="closeDialogNav"
    />

    <template>
      <v-virtual-scroll
        bench="1"
        :items="getTaskgroup"
        :item-height="getScrollItemHeight"
        height="600"
        width="100%"
        class="mt-5 px-0"
        id="scroller"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item :key="item.id + index">
            <v-row justify="center" align="center" class="fill-height">
              <v-col cols="1">
                <v-btn
                  @click="loadDialogNav(item)"
                  class="mr-3"
                  small
                  fab
                  color="info"
                  :loading="item.loading"
                >
                  <v-icon>mdi-navigation</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" class="pr-0 text-center d-flex align-center">
                <span>{{ item.person }}</span>
              </v-col>
              <v-col cols="3" class="px-0 text-center d-flex align-center">
                <span>{{ item.grave_number }}</span>
              </v-col>
              <v-col cols="2" class="px-0 pb-0 pt-5">
                <v-select
                  dense
                  v-if="item.start == null"
                  v-model="item.user"
                  :items="users"
                  label="User"
                  item-text="name"
                  return-object
                ></v-select>
                <v-text-field
                  dense
                  v-else
                  v-model="item.user_name"
                  filled
                  disabled
                ></v-text-field>
              </v-col>

              <v-col
                cols="2"
                class="pl-6 pr-0 text-center d-flex align-center"
                @click="timestamp(item)"
              >
                <v-btn
                  text
                  icon
                  v-if="UserStore.hasFullAccess || UserStore.isTeamLeader"
                  color="blue"
                  v-bind:class="{
                    'green--text': getTaskIconColor(item),
                  }"
                >
                  <v-icon>{{ getTaskIcon(item) }}</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12">
                <div class="text-left">{{ item.description }}</div>
              </v-col>

              <v-col
                cols="12"
                v-if="taskList[0].task_type_id === TaskType.PFLANZEN"
              >
                <ItemTable
                  :items="item.items"
                  :taskId="item.id"
                  @actionPerformed="performItemAction"
                ></ItemTable>
              </v-col>
            </v-row>
          </v-list-item>
          <slot name="after">
            <v-divider :key="index" class="mb-5"></v-divider>
          </slot>
        </template>
      </v-virtual-scroll>
    </template>

    <template>
      <v-card-text>
        <v-btn
          v-if="UserStore.hasFullAccess || UserStore.isTeamLeader"
          bottom
          block
          app
          :disabled="isApprovalDisabled"
          color="info"
          @click="
            NavStateStore.isNavActive
              ? completeTasksInRadius()
              : completeTaskgroup()
          "
        >
          {{ $t("confirm_tasks") }}
        </v-btn>
      </v-card-text>
    </template>
  </v-container>
</template>

<style lang="scss" scoped>
.v-data-table::v-deep th {
  font-size: 10px !important;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}

.smallerItems * {
  padding-top: 0;
  padding-bottom: 0;
}

.v-list-item {
  padding-left: 0;
  padding-right: 0;
}

/*
.v-data-table::v-deep {
  caption, thead {
    background-color: #2196f3;
  }
}
*/
</style>

<script>
import DialogNav from "@components/DialogNav.vue";
import ItemTable from "@components/tables/ItemTable.vue";
import { useAppBarStore } from "@store/appBar.js";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
import { useNavStateStore } from "@store/navState.js";
import { TaskType } from "@utils/constants";

export default {
  components: {
    DialogNav,
    ItemTable,
  },
  name: "Tasks",
  props: {
    taskList: {
      type: Array,
      required: true,
    },
    enableTaskListApproval: Boolean,
    getTasks: Function,
  },
  setup() {
    const AppBarStore = useAppBarStore();
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    const NavStateStore = useNavStateStore();

    return { AppBarStore, AppStateStore, UserStore, NavStateStore };
  },
  data() {
    return {
      TaskType: TaskType,
      users: [],
      icon: "timer",
      displayDialogNav: false,
      dialogNavGrave: "",
      isTaskgroupInRadiusApprovable: false,
    };
  },
  computed: {
    getTaskgroup() {
      if (this.NavStateStore.isNavActive)
        return this.NavStateStore.getTaskGroupInRadius.map(
          (object) => object.task
        );
      return this.taskList;
    },
    isApprovalDisabled() {
      if (this.NavStateStore.isNavActive)
        return (
          !this.isTaskgroupInRadiusApprovable ||
          (!this.UserStore.hasFullAccess && !this.UserStore.isTeamLeader)
        );
      else
        return (
          !this.enableTaskListApproval ||
          (!this.UserStore.hasFullAccess && !this.UserStore.isTeamLeader)
        );
    },

    getScrollItemHeight() {
      if (
        (this.NavStateStore.isNavActive &&
          this.NavStateStore.getActiveTask.taskType === TaskType.PFLANZEN) ||
        (!this.NavStateStore.isNavActive &&
          this.taskList[0].task_type_id === TaskType.PFLANZEN)
      ) {
        return 375;
      } else return 100;
    },
  },
  methods: {
    async getGrave(id) {
      let formData = new FormData();
      formData.set("getGrave", true);
      formData.set("id", id);

      try {
        const response = await this.$root.postData(formData);
        this.dialogNavGrave = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },

    async performItemAction(eventData) {
      const formData = new FormData();

      switch (eventData.type) {
        case "addItemToTask":
          formData.set("addItemToTask", true);
          formData.set("task_id", eventData.task_id);
          break;
        case "setItemQuantity":
          formData.set("setItemQuantity", true);
          break;
        case "setItemDescription":
          formData.set("setItemDescription", true);
          break;
        default:
          throw new Error("Invalid action type");
      }

      formData.set("item", JSON.stringify(eventData.item));

      await this.$root.postData(formData);

      if (this.NavStateStore.isNavActive)
        this.getTasks({ refreshGravesInRadius: true });
      else
        this.getTasks({
          graveyardId: this.taskList[0].graveyard_id,
          taskTypeId: this.taskList[0].task_type_id,
        });
    },

    closeDialogNav() {
      this.displayDialogNav = false;
    },

    loadDialogNav(grave) {
      this.dialogNavGrave = grave;
      this.displayDialogNav = true;
    },

    checkTaskgroupRadiusStatus() {
      if (this.NavStateStore.isNavActive) {
        this.isTaskgroupInRadiusApprovable =
          this.NavStateStore.getTaskGroupInRadius.every(
            (grave) => grave.task.end !== null
          );
      }
    },

    resetScroller() {
      if (document.getElementById("scroller"))
        document.getElementById("scroller").scrollTop = 0;
    },
    async completeTasksInRadius() {
      const add = this.NavStateStore.getTaskGroupInRadius.map(
        (grave) => grave.task
      );

      let formData = new FormData();
      formData.set("addTaskCompleted", true);
      formData.set("customer", this.UserStore.customer.name);
      formData.set("tasks", JSON.stringify(add));

      await this.$root.postData(formData);

      this.isTaskgroupInRadiusApprovable = false;
      this.resetScroller();

      this.$emit("tasksInRadiusDone");
    },
    async completeTaskgroup() {
      let add = [...this.taskList];

      let formData = new FormData();
      formData.set("addTaskCompleted", true);
      formData.set("customer", this.UserStore.customer.name);
      formData.set("tasks", JSON.stringify(add));

      await this.$root.postData(formData);

      this.AppStateStore.backToPreviousTab();
    },
    getTaskIconColor(task) {
      if (task.end != null) return true;
      else return false;
    },
    getTaskIcon(task) {
      if (task.start == null) return "timer";
      else if (task.end == null) return "done";
      else return "done_outline";
    },
    async timestamp(task) {
      if (task.start === null) {
        if (task.user) {
          let formData = new FormData();
          formData.set("setTaskStart", true);
          formData.set("task_id", task.id);
          formData.set("user_id", task.user.id);

          await this.postTaskData(formData);
        }
      } else if (task.end == null) {
        let formData = new FormData();
        formData.set("setTaskEnd", true);
        formData.set("task_id", task.id);

        await this.postTaskData(formData);
      }

      this.checkTaskgroupRadiusStatus();
    },
    async getLists() {
      const lists = await this.$root.getLists();

      this.users = lists["users"];
    },
    async postTaskData(formData) {
      await this.$root.postData(formData);

      if (this.NavStateStore.isNavActive)
        await this.getTasks({
          refreshGravesInRadius: true,
        });
      else
        await this.getTasks({
          graveyardId: this.taskList[0].graveyard_id,
          taskTypeId: this.taskList[0].task_type_id,
        });

      this.checkTaskgroupRadiusStatus();
    },
  },
  async mounted() {
    await this.getLists();
    this.checkTaskgroupRadiusStatus();
    this.resetScroller();
  },
};
</script>
