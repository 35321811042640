<template>
  <v-main style="padding-top: 68px; background-color: #868686">
    <template v-if="AppStateStore.getCurrentTab === 'newContract'">
      <NewContract
        :grave="currentGrave"
        @contractAdded="contractAdded"
      ></NewContract>
    </template>

    <template v-if="AppStateStore.getCurrentTab === 'map'">
      <!-- Openlayers map -->
      <vl-map
        ref="map"
        data-projection="EPSG:4326"
        style="position: relative; background-color: #868686"
        :move-tolerance="2"
        @singleclick="singleClick"
      >
        <!-- GPS syncronized view -->
        <vl-view
          :zoom.sync="mapProperties.zoom"
          :center.sync="mapProperties.center"
          :rotation.sync="mapProperties.rotation"
          :min-zoom="1"
          :max-zoom="23"
        ></vl-view>

        <!-- OSM raster layer
      <vl-layer-tile :z-index="0">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>-->
        <!--Orthofoto raster layer -->
        <vl-layer-tile>
          <vl-source-xyz :url="mapProperties.url"></vl-source-xyz>
        </vl-layer-tile>

        <!-- User on foot real location -->
        <MapFeature
          :coordinates="getFeatureCoordinates($root.location)"
          :color="'#304fff'"
          :featureRadius="10"
          :border="{ display: true, width: 2, color: 'white' }"
        />

        <MapFeatureCollection
          :features="graves.valid_inactive"
          :icon="{ display: true, path: 'goal_noNumber.svg' }"
        />

        <MapFeatureCollection
          :features="graves.unassigned_inactive"
          :icon="{
            display: true,
            path: 'goal_unassigned.svg',
          }"
        />

        <MapFeatureCollection
          :features="graves.valid_active"
          :icon="{ display: true, path: 'goal.svg' }"
        />

        <MapFeatureCollection
          :features="graves.unassigned_active"
          :icon="{ display: true, path: 'goal_noPerson.svg' }"
        />

        <v-layout row justify-center>
          <v-dialog v-model="dialogs.unassign" max-width="400">
            <template
              v-if="
                currentGrave.status == 'valid_active' ||
                currentGrave.status == 'valid_inactive'
              "
            >
              <v-card>
                <v-card-title class="headline justify-center">
                  {{ currentGrave.number }}
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="red"
                    style="width: 100%"
                    @click="unassignNumber(currentGrave.id)"
                  >
                    {{ $t("unassign_number") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-layout>

        <v-layout row justify-center>
          <v-dialog v-model="dialogs.assign" max-width="400">
            <v-card>
              <v-flex xs12 class="py-0">
                <v-select
                  v-model="unassignedGraveData"
                  :items="unassignedGraveList"
                  item-text="number"
                  return-object
                  :label="$t('grave_number_')"
                  required
                  :no-data-text="$t('no_data_text')"
                ></v-select>
              </v-flex>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  :disabled="!unassignedGraveData.number"
                  color="blue"
                  style="width: 100%"
                  @click="assignNumber(currentGrave)"
                >
                  {{ $t("assign_number") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <v-layout row justify-center>
          <v-dialog v-model="dialogs.assigmentMethod" max-width="400">
            <template>
              <v-card>
                <v-card-actions>
                  <v-btn
                    color="blue"
                    style="width: 100%"
                    @click="switchToAssignNumber()"
                  >
                    {{ $t("from_list") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
            <template>
              <v-card>
                <v-card-actions>
                  <v-btn
                    color="blue"
                    style="width: 100%"
                    @click="switchToInputData()"
                  >
                    {{ $t("input_gravedata") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-layout>
      </vl-map>
    </template>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import NewContract from "@components/graveAssignment/NewContract.vue";
import Geolocation from "ol/Geolocation.js";
import MapFeature from "@components/map/MapFeature.vue";
import MapFeatureCollection from "@components/map/MapFeatureCollection.vue";

import { useDrawerStore } from "@store/drawer.js";
import { useUserStore } from "@store/user.js";
import { useAppStateStore } from "@store/appState.js";
import {
  toFeature,
  getFeatureCoordinates,
  closestFeature,
  getDistanceInMeters,
} from "@utils/utilFunctions";

export default {
  name: "GraveAssignment",
  components: {
    NewContract,
    MapFeature,
    MapFeatureCollection,
  },
  setup() {
    const DrawerStore = useDrawerStore();
    const UserStore = useUserStore();
    const AppStateStore = useAppStateStore();
    return { DrawerStore, UserStore, AppStateStore };
  },
  data() {
    return {
      geolocation: "",
      mapProperties: {
        zoom: 17,
        center: [10.92623, 48.37976],
        rotation: 0,
        url: "/tiles/{z}/{x}/{y}.jpg",
      },
      graves: {
        valid_active: [],
        unassigned_active: [],
        valid_inactive: [],
        unassigned_inactive: [],
      },
      dialogs: {
        dialog: false,
        assigmentMethod: false,
        assign: false,
        unassign: false,
      },
      currentGrave: "",
      unassignedGraveList: [],
      unassignedGraveData: "",
      refreshTimer: 0,
    };
  },
  methods: {
    toFeature,
    getDistanceInMeters,
    getFeatureCoordinates,
    closestFeature,
    async contractAdded() {
      await this.getMapAdminGravesToDisplay();
      this.AppStateStore.backToPreviousTab();
    },
    async assignNumber(grave) {
      let formData = new FormData();
      formData.set("assignNumber", true);
      formData.set("id", this.unassignedGraveData.id);
      formData.set("grave_id", grave.id);
      formData.set("ord1", this.unassignedGraveData.ord1);
      formData.set("ord2", this.unassignedGraveData.ord2);
      formData.set("ord3", this.unassignedGraveData.ord3);
      formData.set("ord4", this.unassignedGraveData.ord4);
      formData.set("person", this.unassignedGraveData.person);
      formData.set("logec_id", this.unassignedGraveData.logec_id);

      await this.$root.postData(formData);
      this.getMapAdminGravesToDisplay();
      this.unassignedGraveData = "";
      this.getUnassignedGraveNumbers();
      this.dialogs.assign = false;
    },
    async unassignNumber(id) {
      let formData = new FormData();
      formData.set("unassignNumber", true);
      formData.set("id", id);

      await this.$root.postData(formData);
      this.getMapAdminGravesToDisplay();
      this.dialogs.unassign = false;
    },

    async getMapAdminGravesToDisplay() {
      const formData = new FormData();
      formData.set("getMapAdminGravesToDisplay", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set(
        "location",
        JSON.stringify(this.getFeatureCoordinates(this.$root.location))
      );

      try {
        const response = await this.$root.postData(formData);

        const graveTypes = [
          "valid_active",
          "unassigned_active",
          "valid_inactive",
          "unassigned_inactive",
        ];

        graveTypes.forEach((type) => {
          this.graves[type] = response[type];
          this.graves[type].forEach((grave) => {
            grave.geometry.coordinates =
              grave.geometry.coordinates.map(parseFloat);
          });
        });
      } catch (error) {
        console.error("Failed to get map admin graves to display:", error);
      }
    },

    closestFeatureFromAll(position) {
      let closestGrave = null;
      let closestType = "";
      let minDistance = Number.MAX_VALUE;

      const graveCollections = [
        { graves: this.graves.valid_active, type: "valid_active" },
        {
          graves: this.graves.unassigned_active,
          type: "unassigned_active",
        },
        { graves: this.graves.valid_inactive, type: "valid_inactive" },
        {
          graves: this.graves.unassigned_inactive,
          type: "unassigned_inactive",
        },
      ];

      graveCollections.forEach(({ graves, type }) => {
        const closestGraveInCollection = closestFeature.call(
          this,
          position,
          graves
        );
        if (closestGraveInCollection) {
          const clickDistance = this.getDistanceInMeters(position, [
            closestGraveInCollection.geometry.coordinates[0],
            closestGraveInCollection.geometry.coordinates[1],
          ]);
          if (clickDistance < minDistance) {
            minDistance = clickDistance;
            closestGrave = closestGraveInCollection;
            closestType = type;
          }
        }
      });

      return minDistance < 3
        ? {
            clicked: true,
            grave: closestGrave,
            type: closestType,
            position: position,
          }
        : {
            clicked: false,
            position: position,
          };
    },

    switchToAssignNumber() {
      this.dialogs.assigmentMethod = false;
      this.dialogs.assign = true;
    },

    switchToInputData() {
      this.dialogs.assigmentMethod = false;
      this.DrawerStore.toggleOffDrawer();
      this.AppStateStore.setCurrentTabAndBackTo("newContract");
    },

    singleClick(event) {
      const clickpos = event.coordinate;
      const result = this.closestFeatureFromAll(clickpos);

      if (result.clicked) {
        this.currentGrave = result.grave;

        switch (result.type) {
          case "valid_active":
            this.dialogs.unassign = true;
            break;
          case "unassigned_active":
            this.dialogs.assigmentMethod = true;
            break;
          case "valid_inactive":
            this.dialogs.unassign = true;
            break;
          case "unassigned_inactive":
            this.dialogs.assigmentMethod = true;
            break;

          default:
            break;
        }
      }
    },

    async getUnassignedGraveNumbers() {
      let formData = new FormData();
      formData.set("getUnassignedGraveNumbers", true);
      formData.set("customer_id", this.UserStore.customer.id);
      formData.set("graveyard_id", this.$root.graveyard_id);

      try {
        const response = await this.$root.postData(formData);
        this.unassignedGraveList = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },

    async initGeolocation() {
      this.geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      });

      this.geolocation.on("change:position", async () => {
        this.$root.location = this.toFeature(this.geolocation.getPosition());
        this.mapProperties.center = this.getFeatureCoordinates(
          this.$root.location
        );

        const shouldUpdateGraves =
          !this.AppStateStore.getServerLoading &&
          !this.dialogs.unassign &&
          this.AppStateStore.getCurrentTab === "map" &&
          this.refreshTimer <= 0;

        if (shouldUpdateGraves) {
          try {
            await this.getMapAdminGravesToDisplay();
          } catch (error) {
            console.error("Failed to get map admin graves:", error);
          } finally {
            this.refreshTimer = 10;
          }
        } else if (this.refreshTimer > 0) {
          this.refreshTimer--;
        }
      });
    },
    async initMap() {
      this.initGeolocation();
      await this.getUnassignedGraveNumbers();
      this.AppStateStore.setCurrentTab("map");
    },
  },
  async mounted() {
    EventBus.$on("refreshComponent", this.initMap);
    this.initMap();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.initMap);
  },
  async destroyed() {
    this.geolocation.setTracking(false);
  },
};
</script>
