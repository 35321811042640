<template>
  <v-dialog :value="AppStateStore.getErrorDialog" persistent max-width="600">
    <v-card text>
      <v-card-title class="justify-center text-center text-wrap">
        {{ AppStateStore.getMessage }}
      </v-card-title>
      <v-card-actions class="pa-0 ma-0">
        <v-btn color="primary" block tile @click="clicked()">
          {{ $t("dialog_ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
export default {
  name: "ErrorDialog",
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
  methods: {
    clicked() {
      if (
        this.AppStateStore.getMessage ===
        this.AppStateStore.getMessages.errors.Global.timeout
      ) {
        this.AppStateStore.resetAll();
        EventBus.$emit("refreshComponent");
      } else this.AppStateStore.removeError();
    },
  },
};
</script>
