<template>
  <v-container fluid class="pa-0 ma-0" v-if="grave">
    <v-row v-if="buttonEnabled" class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        <v-btn
          height="54px"
          width="100%"
          @click="$emit('graveReached')"
          color="green"
          dark
          tile
        >
          <v-icon light>launch</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mx-0 py-4">
      <v-col cols="7">
        <v-img
          class="thumbnail mx-1"
          :src="getGraveImage"
          contain
          style="background: white"
        ></v-img>
      </v-col>

      <v-col cols="5" class="px-0 mx-0">
        <v-list
          three-line
          height="100%"
          class="pa-0 ma-0"
          style="display: flex"
        >
          <v-list-item class="subheading px-0 mx-0 text-left">
            <v-list-item-content>
              <v-list-item-subtitle style="margin-bottom: 4px" class="caption">
                <strong>
                  {{ $t("grave_number") }}
                </strong>
                <br />
                {{ getGraveNumber }}
              </v-list-item-subtitle>
              <v-divider style="width: 100%" />
              <v-list-item-subtitle
                style="margin-top: 4px; overflow: initial"
                class="caption text-left"
              >
                <strong>
                  {{ $t("customer") + ":" }}
                </strong>
                <br />
                {{ grave.person }}
              </v-list-item-subtitle>
              <v-divider style="width: 100%" />
              <v-list-item-subtitle
                style="margin-top: 4px; overflow: initial"
                class="caption text-left"
              >
                <strong>
                  {{ $t("description") + ":" }}
                </strong>
                <br />
                {{ getGraveDescription }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getImage } from "@utils/utilFunctions";

export default {
  name: "GraveContent",
  props: { grave: Object, buttonEnabled: { type: Boolean, default: false } },
  computed: {
    getGraveImage() {
      return this.getImage(this.grave.id);
    },
    getGraveNumber() {
      if ("number" in this.grave) return this.grave.number;
      return this.grave.grave_number;
    },
    getGraveDescription() {
      if ("description" in this.grave) return this.grave.description;
      return this.grave.grave_description;
    },
  },
  methods: {
    getImage,
  },
};
</script>
