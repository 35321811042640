import { defineStore } from "pinia";
import { NavigationType, NavigationMode } from "@utils/constants";
import { allValuesAssigned } from "@utils/utilFunctions";

export const useNavStateStore = defineStore({
  id: "navStateStore",
  state: () => ({
    navigationType: "",
    routePlanning: {
      id: 1,
      name_de: "Kürzester Weg",
      name_en: "Shortest route",
    },
    activeTask: {
      taskType: "",
      graveyardId: "",
    },
    radius: 20,
    navigationMode: NavigationMode.NONE,
    previousNavigationMode: NavigationMode.NONE,
    taskGroup: [],
    taskGroupInRadius: [],
    ordGroups: [],
    carStopOverride: false,
  }),
  getters: {
    getTaskGroup() {
      return this.taskGroup;
    },
    getTaskGroupInRadius() {
      return this.taskGroupInRadius;
    },
    areTasksInRadiusCompleted() {
      return this.taskGroupInRadius.length === 0;
    },
    isNavigationStopped() {
      return this.navigationMode === NavigationMode.NONE;
    },
    onFootToNextGrave() {
      return this.navigationMode === NavigationMode.FOOT;
    },
    onFootToCarStop() {
      return this.navigationMode === NavigationMode.BACKTOCAR;
    },
    inCarToCarStop() {
      return this.navigationMode === NavigationMode.CAR;
    },
    onFootOrBackToCar() {
      return (
        this.navigationMode === NavigationMode.FOOT ||
        this.navigationMode === NavigationMode.BACKTOCAR
      );
    },
    withCarMK1703() {
      return this.navigationType === NavigationType.WITHCARMK1703;
    },
    withCarM26() {
      return this.navigationType === NavigationType.WITHCARM26;
    },
    withCarLKW() {
      return this.navigationType === NavigationType.WITHCARLKW;
    },
    onFootNavType() {
      return (
        this.navigationType === NavigationType.ONFOOT ||
        this.navigationType === NavigationType.ONFOOTWITHLOAD
      );
    },
    withCarNavType() {
      return this.withCarLKW || this.withCarMK1703 || this.withCarM26;
    },
    getNavigationMode() {
      return this.navigationMode;
    },
    getNavigationType() {
      return this.navigationType;
    },
    getRoutePlanning() {
      return this.routePlanning;
    },
    isRoutePlanningShortestPath() {
      return this.routePlanning.id === 1;
    },
    isRoutePlanningAscendingNumbers() {
      return this.routePlanning.id === 2;
    },
    getRadius() {
      return this.radius;
    },
    getActiveTask() {
      return this.activeTask;
    },
    getTaskGroup() {
      return this.taskGroup;
    },
    getOrdGroups() {
      return this.ordGroups;
    },
    isNavActive() {
      return allValuesAssigned(this.activeTask);
    },
    isTaskGroupCompleted() {
      return this.taskGroup.features.length === 0;
    },
    getCarStopOverride() {
      return this.carStopOverride;
    },
  },
  actions: {
    setNavigationMode(navigationMode) {
      this.previousNavigationMode = this.navigationMode;
      this.navigationMode = navigationMode;
    },
    setNavigationModeToNone() {
      this.navigationMode = NavigationMode.NONE;
    },
    setNavigationType(navigationType) {
      this.navigationType = navigationType;
    },
    setRoutePlanning(routePlanning) {
      this.routePlanning = routePlanning;
    },
    backToPreviousNavigationMode() {
      this.navigationMode = this.previousNavigationMode;
    },
    setRadius(radius) {
      this.radius = radius;
    },
    setTaskGroup(taskgroup) {
      this.taskGroup = taskgroup;

      if (this.taskGroup.features.length) {
        this.activeTask = {
          taskType: taskgroup.features[0].task.task_type_id,
          graveyardId: taskgroup.features[0].task.graveyard_id,
        };

        this.findNavigationMode();
      }
    },
    setOrdGroups(ordGroups) {
      this.ordGroups = ordGroups;
    },
    setTaskGroupInRadius(taskGroupInRadius) {
      this.taskGroupInRadius = taskGroupInRadius;
    },
    addTaskToTaskGroupInRadius(task) {
      this.taskGroupInRadius.push(task);
    },
    removeFromTaskGroupInRadius(id) {
      const index = this.taskGroupInRadius.findIndex(
        (grave) => grave.id === id
      );

      this.taskGroupInRadius.splice(index, 1);
    },
    sortTaskGroupInRadius(sorter) {
      this.taskGroupInRadius.sort(sorter);
    },
    resetTaskGroupInRadius() {
      this.taskGroupInRadius.length = 0;
    },
    setActiveTask(activeTask) {
      this.activeTask = activeTask;
    },
    resetTaskgroup() {
      this.navigationMode = NavigationMode.NONE;
      this.previousNavigationMode = NavigationMode.NONE;
      this.taskGroup.length = 0;
      this.taskGroupInRadius.length = 0;
      this.activeTask = { taskType: "", graveyardId: "" };
    },
    stopNavigation() {
      this.navigationType = "";
      this.routePlanning = {
        id: 1,
        name_de: "Kürzester Weg",
        name_en: "Shortest route",
      };
      this.radius = 20;

      this.resetTaskgroup();
    },
    findNavigationMode() {
      if (this.withCarNavType) this.navigationMode = NavigationMode.CAR;
      else if (this.onFootNavType) this.navigationMode = NavigationMode.FOOT;
    },
    setCarStopOverride(value) {
      this.carStopOverride = value;
    },
  },
});
