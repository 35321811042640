var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"16px","background-color":"#868686"}},[(
      _vm.AppStateStore.getCurrentTab === _vm.DashboardTab.NAVIGATION &&
      _vm.NavStateStore.isNavActive
    )?_c('vl-map',{ref:"map",staticStyle:{"position":"absolute","background-color":"#868686"},attrs:{"data-projection":"EPSG:4326","move-tolerance":2}},[_c('vl-view',{attrs:{"zoom":_vm.mapProperties.zoom,"center":_vm.mapProperties.center,"rotation":_vm.mapProperties.rotation,"min-zoom":11,"max-zoom":22},on:{"update:zoom":function($event){return _vm.$set(_vm.mapProperties, "zoom", $event)},"update:center":function($event){return _vm.$set(_vm.mapProperties, "center", $event)},"update:rotation":function($event){return _vm.$set(_vm.mapProperties, "rotation", $event)}}}),_c('vl-layer-tile',{attrs:{"z-index":1}},[_c('vl-source-xyz',{attrs:{"url":_vm.mapProperties.url}})],1),(
        !_vm.isEmpty(_vm.nextGrave) && !_vm.NavStateStore.onFootToCarStop && _vm.mapLoaded
      )?_c('MapFeature',{attrs:{"coordinates":[
        parseFloat(_vm.nextGrave.geometry.coordinates[0]),
        parseFloat(_vm.nextGrave.geometry.coordinates[1]),
      ],"color":'#76FF03'}}):_vm._e(),(_vm.mapLoaded && _vm.NavStateStore.getTaskGroupInRadius.length)?_c('vl-layer-vector',_vm._l((_vm.NavStateStore.getTaskGroupInRadius),function(grave,index){return _c('MapFeature',{key:grave.task.id,attrs:{"id":grave.task.id,"coordinates":[
          parseFloat(grave.geometry.coordinates[0]),
          parseFloat(grave.geometry.coordinates[1]),
        ],"color":'#FF9800',"zIndex":9998,"text":{ display: true, index: String(index) }}})}),1):_vm._e(),(
        _vm.mapLoaded && _vm.calculatedPaths.carPath && _vm.NavStateStore.inCarToCarStop
      )?_c('Route',{attrs:{"route":_vm.calculatedPaths.carPath,"routeColor":_vm.routeColor}}):_vm._e(),(_vm.mapLoaded && _vm.routeLocation.carStop)?_c('vl-layer-vector',[_c('MapFeature',{attrs:{"coordinates":[
          parseFloat(_vm.routeLocation.carStop.geometry.coordinates[0]),
          parseFloat(_vm.routeLocation.carStop.geometry.coordinates[1]),
        ],"color":'#ba000d',"border":{ display: true, width: 2, color: 'white' }}})],1):_vm._e(),(
        _vm.mapLoaded &&
        _vm.calculatedPaths.footPath &&
        _vm.NavStateStore.onFootOrBackToCar
      )?_c('Route',{attrs:{"route":_vm.calculatedPaths.footPath,"routeColor":_vm.routeColor}}):_vm._e(),(
        _vm.mapLoaded &&
        _vm.routeLocation.closestPointOnFootRoute &&
        _vm.calculatedPaths.footPath &&
        _vm.NavStateStore.onFootOrBackToCar
      )?_c('Route',{attrs:{"route":[
        _vm.getFeatureCoordinates(_vm.$root.location),
        [
          parseFloat(
            _vm.routeLocation.closestPointOnFootRoute.geometry.coordinates[0]
          ),
          parseFloat(
            _vm.routeLocation.closestPointOnFootRoute.geometry.coordinates[1]
          ),
        ],
      ],"routeColor":_vm.routeColor,"width":6,"lineDash":{ display: true, distances: [5, 10] }}}):_vm._e(),(
        _vm.mapLoaded &&
        _vm.routeLocation.closestPointOnCarRoute &&
        _vm.calculatedPaths.carPath &&
        _vm.NavStateStore.inCarToCarStop
      )?_c('Route',{attrs:{"route":[
        _vm.calculatedPaths.carPath[0],
        [
          parseFloat(
            _vm.routeLocation.closestPointOnCarRoute.geometry.coordinates[0]
          ),
          parseFloat(
            _vm.routeLocation.closestPointOnCarRoute.geometry.coordinates[1]
          ),
        ],
      ],"routeColor":_vm.routeColor}}):_vm._e(),(
        _vm.mapLoaded &&
        _vm.routeLocation.closestPointOnFootRoute.geometry &&
        _vm.calculatedPaths.footPath &&
        _vm.NavStateStore.onFootOrBackToCar
      )?_c('Route',{attrs:{"route":[
        _vm.calculatedPaths.footPath[0],
        [
          parseFloat(
            _vm.routeLocation.closestPointOnFootRoute.geometry.coordinates[0]
          ),
          parseFloat(
            _vm.routeLocation.closestPointOnFootRoute.geometry.coordinates[1]
          ),
        ],
      ],"routeColor":_vm.routeColor}}):_vm._e(),(_vm.mapLoaded && !_vm.NavStateStore.inCarToCarStop)?_c('vl-layer-vector',[_c('MapFeature',{attrs:{"coordinates":_vm.getFeatureCoordinates(_vm.$root.location),"color":'#304fff',"featureRadius":10,"border":{ display: true, width: 2, color: 'white' }}})],1):_vm._e(),(
        _vm.mapLoaded &&
        _vm.NavStateStore.inCarToCarStop &&
        _vm.routeLocation.closestPointOnCarRoute.geometry
      )?_c('vl-layer-vector',[_c('MapFeature',{attrs:{"coordinates":_vm.getFeatureCoordinates(_vm.routeLocation.closestPointOnCarRoute),"color":'#304fff',"featureRadius":10,"border":{ display: true, width: 2, color: 'white' }}})],1):_vm._e(),(!_vm.isEmpty(_vm.nextGrave) || _vm.NavStateStore.onFootToCarStop)?_c('BottomMenu',{attrs:{"marginBottom":'56px',"toggled":_vm.isBottomMenuToggled,"grave":_vm.nextGrave,"openGraveButton":true,"displayBottomMenu":!_vm.isEmpty(_vm.nextGrave) || _vm.NavStateStore.onFootToCarStop,"showOverrideButton":_vm.NavStateStore.onFootToCarStop || _vm.NavStateStore.inCarToCarStop,"isToggleDisabled":!_vm.NavStateStore.onFootNavType && _vm.withTeamleader,"showRecalculateButtonsCar":_vm.NavStateStore.inCarToCarStop || _vm.NavStateStore.withCarNavType,"showRecalculateButtonOnFoot":_vm.NavStateStore.onFootNavType},on:{"handleGraveReached":_vm.handleGraveReached,"toggle":_vm.toggleBottomMenu,"recalculateTaskGroup":_vm.recalculateTaskGroup,"recalculateTask":_vm.recalculateTask}}):_vm._e(),_c('v-bottom-sheet',{attrs:{"persistent":""},model:{value:(_vm.location.searching),callback:function ($$v) {_vm.$set(_vm.location, "searching", $$v)},expression:"location.searching"}},[_c('v-sheet',{staticClass:"text-center",staticStyle:{"z-index":"2","margin-bottom":"56px"},attrs:{"height":"60px"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"blue"}}),_c('div',{staticClass:"py-3"},[_vm._v(" "+_vm._s(_vm.locationMessage)+" ")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.taskgroupDoneDialog),callback:function ($$v) {_vm.taskgroupDoneDialog=$$v},expression:"taskgroupDoneDialog"}},[_c('v-btn',{attrs:{"disabled":!_vm.workStatus.workStarted},on:{"click":function($event){return _vm.endWork()}}},[_vm._v(" "+_vm._s(_vm.$t("finish_work"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.workStatus.doneToday ||
            _vm.workStatus.onBreak ||
            !_vm.workStatus.workStarted},on:{"click":function($event){return _vm.startBreak()}}},[_vm._v(" "+_vm._s(_vm.$t("break"))+" ")]),_c('v-btn',{on:{"click":function($event){return _vm.doneSelectOtherTaskgroup()}}},[_vm._v(" "+_vm._s(_vm.$t("select_other"))+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }