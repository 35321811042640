<template>
  <v-dialog
    :value="getDialog($options._componentTag)"
    persistent
    max-width="400"
  >
    <template v-slot:activator="{ on_test }"></template>
    <v-card>
      <v-card-text class="text-xs-left font-weight-bold pl-0 pt-0">
        {{ $t("create_new_task") }}
      </v-card-text>
      <TaskForm :task="task" :graveSelection="mode !== 'editMultiple'" />
      <v-card-actions>
        <v-btn
          text
          @click="
            clearTaskDialog();
            closeDialog($options._componentTag);
          "
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" :disabled="valid()" @click="addTask()">
          {{ $t("create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TaskForm from "@components/forms/TaskForm.vue";
import { TaskType } from "@utils/constants";

export default {
  name: "TaskFormDialog",
  props: {
    getDialog: Function,
    closeDialog: Function,
    mode: {
      type: String,
      default: "create", // 'create', 'editSingle', 'editMultiple'
    },
    taskDataForEdit: {
      type: [Object, Array],
      default: () => {},
    },
  },
  components: {
    TaskForm,
  },
  data() {
    return {
      TaskType: TaskType,
      task: {
        date: new Date().toISOString().substr(0, 10),
        task_type: "",
        items: [],
        duration: 1,
        selectedTeams: [],
        grave_type: "",
        grave_selected: "",
        description: "",
      },
    };
  },
  methods: {
    valid() {
      const isNonEmptyString = (value) => value !== "";
      const isDescriptionRequired =
        this.task.task_type &&
        (this.task.task_type.id === TaskType.PFLANZEN ||
          this.task.task_type.id === TaskType.SONDERAUFGABE);

      const areCommonFieldsFilled =
        isNonEmptyString(this.computedDateFormatted) &&
        isNonEmptyString(this.task.task_type) &&
        isNonEmptyString(this.task.duration);

      let additionalConditions = true;

      if (this.mode === "editMultiple") {
        additionalConditions = isDescriptionRequired
          ? isNonEmptyString(this.task.description)
          : true;
      } else {
        additionalConditions =
          isNonEmptyString(this.task.grave_selected) &&
          (isDescriptionRequired
            ? isNonEmptyString(this.task.description)
            : true);
      }

      return !(areCommonFieldsFilled && additionalConditions);
    },
    clearTaskDialog() {
      Object.assign(this.task, {
        date: new Date().toISOString().substr(0, 10),
        task_type: "",
        items: [],
        duration: 1,
        selectedTeams: [],
        grave_type: "",
        grave_selected: "",
        description: "",
      });
    },

    async addTask() {
      let formData = new FormData();

      switch (this.mode) {
        case "create":
          formData.set("addTask", true);

          formData.set(
            "approved",
            this.task.task_type.id === TaskType.SONDERAUFGABE ? 0 : 1
          );
          break;
        case "editSingle":
          formData.set("editTask", true);
          formData.set("task_id", this.task.id);
          break;
        case "editMultiple":
          formData.set("editTasks", true);
          formData.set("tasks", JSON.stringify(this.taskDataForEdit));
          break;
        default:
          break;
      }

      const add = this.task.selectedTeams.map((team) => team.id);

      formData.set("first_on", this.task.date);
      formData.set("interval_id", 1); //Function removed
      formData.set("task_type_id", this.task.task_type.id);
      formData.set("items", JSON.stringify(this.task.items));
      formData.set("teams", JSON.stringify(add));
      formData.set("graveyard_id", this.task.grave_selected.graveyard_id);
      formData.set("grave_id", this.task.grave_selected.id);
      formData.set("description", this.task.description);
      formData.set("duration_default", this.task.duration);

      await this.$root.postData(formData);
      this.clearTaskDialog();
      this.closeDialog(this.$options._componentTag);
    },
  },
  mounted() {
    if (this.mode === "editSingle") this.task = this.taskDataForEdit;
  },
};
</script>
