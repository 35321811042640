<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ $t("completed_task_approval") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card class="elevation-5">
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            :label="$t('search')"
            single-line
            hide-details
            left
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tasks"
          :search="search"
          item-key="id"
          return-object
          show-select
          v-model="selectedTasks"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageText: '',
            itemsPerPageAllText: $t('all'),
            'items-per-page-options': [10, 100, 200, 500, -1],
          }"
          dense
          show-expand
        >
          <template #item.graveyard="{ item }">
            {{ item.graveyard_name }}
          </template>
          <template #item.grave_number="{ item }">
            {{ item.grave_number }}
          </template>
          <template #item.person="{ item }">
            {{ item.person }}
          </template>
          <template v-if="$root.isLanguageGerman" #item.task_type="{ item }">
            {{ item.task_type_de }}
          </template>
          <template v-else #item.task_type="{ item }">
            {{ item.task_type_en }}
          </template>
          <template #item.description="{ item }">
            {{ item.description }}
          </template>

          <template #item.user_name="{ item }">
            {{ item.user_name }}
          </template>

          <template #item.end="{ item }">
            {{ item.end }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              v-if="item.task_type_name_de == 'Pflanzen'"
            >
              <div class="my-5">
                <template v-for="(item, index) in item.items">
                  <div :key="item.item_id + item.name + item.quantity + index">
                    {{ item.name }}
                    :
                    {{ item.quantity }}
                    {{ item.description ? ", " + item.description : "" }}
                  </div>
                </template>
              </div>
            </td>
          </template>
          <template slot="no-results">
            {{ $t("no_results") }}
          </template>
          <template v-slot:no-data>
            {{ $t("no_data_text") }}
          </template>
        </v-data-table>
      </v-card>
      <v-btn
        class="mt-5"
        style="width: 100%"
        color="info"
        @click="approveTasks()"
      >
        {{ $t("approve") }}
      </v-btn>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";
export default {
  setup() {
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { AppStateStore, UserStore };
  },
  data() {
    return {
      tasks: [],
      selectedTasks: [],
      search: "",
    };
  },
  methods: {
    async getTasksToApprove() {
      let formData = new FormData();
      formData.set("getCompletedTasksToApprove", true);
      formData.set("customer_id", this.UserStore.customer.id);

      try {
        const response = await this.$root.postData(formData);
        this.tasks = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    async approveTasks() {
      let formData = new FormData();
      formData.set("addTaskCompleted", true);
      formData.set("customer", this.UserStore.customer.name);
      formData.set("tasks", JSON.stringify(this.selectedTasks));

      await this.$root.postData(formData);
      this.getTasksToApprove();
    },
  },
  computed: {
    getTaskTypeValue() {
      if (this.$root.isLanguageGerman) return "task_type_name_de";
      return "task_type_name_en";
    },
    headers() {
      return [
        { text: this.$t("graveyard"), value: "graveyard_name", sortable: true },
        { text: this.$t("grave"), value: "grave_number", sortable: true },
        { text: this.$t("person"), value: "person", sortable: true },
        {
          text: this.$t("task_type"),
          value: this.getTaskTypeValue,
          sortable: true,
        },
        { text: this.$t("description"), value: "description", sortable: true },
        { text: this.$t("employee"), value: "user_name", sortable: true },
        {
          text: this.$t("end"),
          value: "end",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getTasksToApprove);
    this.getTasksToApprove();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getTasksToApprove);
  },
};
</script>
