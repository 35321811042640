<template>
  <vl-feature>
    <vl-geom-multi-line-string
      :coordinates="[route]"
    ></vl-geom-multi-line-string>
    <vl-style-box>
      <vl-style-stroke
        :color="routeColor"
        :width="10"
        :lineDash="lineDash.display ? [5, 10] : []"
      ></vl-style-stroke>
    </vl-style-box>
  </vl-feature>
</template>

<script>
export default {
  name: "Route",
  props: {
    route: Array,
    routeColor: String,
    width: {
      type: Number,
      default: 10,
    },
    lineDash: {
      type: Object,
      default: () => {
        return { display: false, distances: [5, 10] };
      },
    },
  },
};
</script>
