<template>
  <v-dialog :value="show" persistent max-width="400">
    <v-card text>
      <v-card-title class="justify-center title">
        {{ text }}
      </v-card-title>
      <v-card-actions>
        <v-btn text @click="$emit('cancel')">
          {{ $t("cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="$emit('confirm')">
          {{ $t("yes") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    text: String,
    show: Boolean,
  },
};
</script>
