<template>
  <v-navigation-drawer
    v-model="DrawerStore.toggled"
    app
    width="300"
    stateless
    v-if="!AppStateStore.isAppStateBeforeLoggedIn"
  >
    <v-app-bar color="blue darken-3" absolute dark>
      <img v-bind:src="require('@assets/logo.png')" height="36" />
      <v-toolbar-title></v-toolbar-title>
    </v-app-bar>

    <v-list>
      <v-list-item class="mt-3"></v-list-item>

      <v-list-item color="primary" :to="{ name: 'dashboard' }">
        <v-list-item-action>
          <v-icon>dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content @click="DrawerStore.toggleOffDrawer">
          <v-list-item-title>{{ $t("menu.overview") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-if="UserStore.hasFullAccess">
        <v-subheader>{{ $t("menu.groups.tasks.header") }}</v-subheader>
        <template v-if="UserStore.hasFullAccess">
          <v-list-item color="primary" :to="{ name: 'schedule' }">
            <v-list-item-action>
              <v-icon>schedule</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="DrawerStore.toggleOffDrawer">
              <v-list-item-title>
                {{ $t("menu.groups.tasks.schedule") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="UserStore.hasFullAccess">
          <v-list-item color="primary" :to="{ name: 'taskApproval' }">
            <v-list-item-action>
              <v-icon>done</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="DrawerStore.toggleOffDrawer">
              <v-list-item-title>{{ $t("task_approval") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template>
          <v-list-item color="primary" :to="{ name: 'completedTaskApproval' }">
            <v-list-item-action>
              <v-icon>done_outline</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="DrawerStore.toggleOffDrawer">
              <v-list-item-title>
                {{ $t("completed_task_approval") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="UserStore.hasFullAccess">
          <v-list-item color="primary" :to="{ name: 'newTasks' }">
            <v-list-item-action>
              <v-icon>note_add</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="DrawerStore.toggleOffDrawer">
              <v-list-item-title>
                {{ $t("menu.groups.tasks.newTasks") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="UserStore.hasFullAccess">
          <v-list-item color="primary" :to="{ name: 'incompleteTasks' }">
            <v-list-item-action>
              <v-icon>remove_done</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="DrawerStore.toggleOffDrawer">
              <v-list-item-title>
                {{ $t("menu.groups.tasks.incomplete") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="UserStore.hasFullAccess">
          <v-list-item color="primary" :to="{ name: 'completed' }">
            <v-list-item-action>
              <v-icon>assignment_turned_in</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="DrawerStore.toggleOffDrawer">
              <v-list-item-title>
                {{ $t("menu.groups.tasks.completed") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <template v-if="UserStore.hasFullAccess">
        <v-subheader>{{ $t("menu.groups.teams.header") }}</v-subheader>

        <v-list-item color="primary" :to="{ name: 'teams' }">
          <v-list-item-action>
            <v-icon>group</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>
              {{ $t("menu.groups.teams.organize") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>{{ $t("menu.groups.employees.header") }}</v-subheader>
        <v-list-item color="primary" :to="{ name: 'employees' }">
          <v-list-item-action>
            <v-icon>account_box</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>
              {{ $t("menu.groups.employees.manage") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-subheader>{{ $t("menu.groups.working_hours.header") }}</v-subheader>
      <v-list-item color="primary" :to="{ name: 'workingHours' }">
        <v-list-item-action>
          <v-icon>today</v-icon>
        </v-list-item-action>
        <v-list-item-content @click="DrawerStore.toggleOffDrawer">
          <v-list-item-title>
            {{ $t("menu.groups.working_hours.working_hours") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-if="UserStore.hasFullAccess">
        <v-subheader>{{ $t("menu.groups.map.header") }}</v-subheader>
        <v-list-item color="primary" :to="{ name: 'graveAssignment' }">
          <v-list-item-action>
            <v-icon>edit_location</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>
              {{ $t("menu.groups.map.assign") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" :to="{ name: 'newGraves' }">
          <v-list-item-action>
            <v-icon>edit_location</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>
              {{ $t("menu.groups.map.add") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" :to="{ name: 'contracts' }">
          <v-list-item-action>
            <v-icon>book</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>{{ $t("contracts") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="primary" :to="{ name: 'graves' }">
          <v-list-item-action>
            <v-icon>edit</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>{{ $t("graves") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="primary" :to="{ name: 'logecExport' }">
          <v-list-item-action>
            <v-icon>save_alt</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>{{ "Logec Export" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="primary" :to="{ name: 'logecImport' }">
          <v-list-item-action>
            <v-icon>add</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>{{ "Logec Import" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="UserStore.isSuperAdmin || UserStore.hasFullAccess">
        <v-list-item color="primary" :to="{ name: 'customers' }">
          <v-list-item-action>
            <v-icon>edit</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>{{ $t("customers") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="UserStore.hasFullAccess">
        <v-list-item color="primary" :to="{ name: 'backup' }">
          <v-list-item-action>
            <v-icon>save</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>{{ $t("backup") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-if="false">
        <v-list-item color="primary" :to="{ name: 'fieldManager' }">
          <v-list-item-action>
            <v-icon>save</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="DrawerStore.toggleOffDrawer">
            <v-list-item-title>{{ "Fieldmanager" }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useDrawerStore } from "@store/drawer.js";
import { useAppStateStore } from "@store/appState.js";
import { useUserStore } from "@store/user.js";

export default {
  name: "TheDrawer",
  setup() {
    const DrawerStore = useDrawerStore();
    const AppStateStore = useAppStateStore();
    const UserStore = useUserStore();
    return { DrawerStore, AppStateStore, UserStore };
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
