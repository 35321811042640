var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-5"},[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-icon":"search","label":_vm.$t('search'),"single-line":"","hide-details":"","left":""},model:{value:(_vm.searchFieldContent),callback:function ($$v) {_vm.searchFieldContent=$$v},expression:"searchFieldContent"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.graveyards,"search":_vm.searchFieldContent,"footer-props":{
      itemsPerPageText: '',
      itemsPerPageAllText: _vm.$t('all'),
      'items-per-page-options': [10, 100, 200, 500, -1],
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("graveyards") + ": " + _vm.customerName)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.newGraveyardDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("new_graveyard"))+" ")]),(_vm.newGraveyardDialog)?_c('NewGraveyardDialog',{on:{"createGraveyard":_vm.createGraveyard,"cancel":function($event){_vm.newGraveyardDialog = false}}}):_vm._e()],1)]},proxy:true},{key:"item.name",fn:function({ item }){return [_vm._v(_vm._s(item.name))]}},{key:"item.delete",fn:function({ item }){return [_c('v-btn',{attrs:{"fab":"","small":"","dark":"","color":"red"},on:{"click":function($event){_vm.graveyardToDelete = item.id;
          _vm.deleteGraveyardDialog = true;}}},[_c('v-icon',[_vm._v("delete")])],1)]}},{key:`footer.page-text`,fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" / "+_vm._s(items.itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("no_data_text"))+" ")]},proxy:true}],null,true)},[_c('template',{slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("no_results"))+" ")])],2),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('ConfirmDialog',{attrs:{"text":_vm.$t('delete_question_graveyard'),"show":_vm.deleteGraveyardDialog},on:{"confirm":function($event){_vm.deleteGraveyard();
        _vm.deleteGraveyardDialog = false;},"cancel":function($event){_vm.deleteGraveyardDialog = false;
        _vm.graveyardToDelete = '';}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }