<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-data-table
      :headers="itemHeaders"
      :items="items"
      :item-class="itemRowBackground"
      disable-sort
      dense
      :items-per-page="getItemsPerPage"
      disable-items-per-page
      item-key="name"
      :footer-props="{
        disableItemsPerPage: true,
        itemsPerPageText: '',
        itemsPerPageOptions: [getItemsPerPage],
      }"
      class="fixed-height-datatable"
    >
      <v-spacer></v-spacer>
      <template #item.name="{ item }">
        {{ item.name }}
      </template>

      <template #item.quantity="{ item }">
        <v-edit-dialog
          :return-value.sync="item.quantity"
          large
          persistent
          @save="
            $emit('actionPerformed', { type: 'setItemQuantity', item: item })
          "
          :save-text="$t('save')"
          :cancel-text="$t('cancel')"
        >
          <div>{{ item.quantity }}</div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $t("update_quantity") }}
            </div>
          </template>
          <template v-slot:input>
            <v-text-field
              v-model="item.quantity"
              :label="$t('edit')"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template #item.description="{ item }">
        <v-edit-dialog
          :return-value.sync="item.description"
          large
          persistent
          @save="
            $emit('actionPerformed', {
              type: 'setItemDescription',
              item: item,
            })
          "
          :save-text="$t('save')"
          :cancel-text="$t('cancel')"
        >
          <div>{{ item.description }}</div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $t("update_quantity") }}
            </div>
          </template>
          <template v-slot:input>
            <v-text-field
              v-model="item.description"
              :label="$t('edit')"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} /
        {{ items.itemsLength }}
      </template>

      <template v-slot:footer>
        <v-btn block color="primary" dark @click="openNewItemDialog">
          <v-icon>add</v-icon>
          {{ $t("add_new_item") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialogs.newItem"
          max-width="500px"
          :retain-focus="false"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ $t("new_item") }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="8">
                    <v-select
                      v-model="item"
                      :items="itemList"
                      :item-text="(item) => item.name"
                      return-object
                      :label="$t('name')"
                      required
                      append-icon="add"
                      @click:append="dialogs.addItemToList = true"
                      :no-data-text="$t('no_data_text')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      type="number"
                      v-model="item.quantity"
                      :label="$t('quantity')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.description"
                      :label="$t('description')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeNewItemDialog()">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="validateNewItem">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template slot="no-results">
        {{ $t("no_results") }}
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_text") }}
      </template>
    </v-data-table>
    <v-layout row justify-center>
      <v-dialog
        v-model="dialogs.addItemToList"
        persistent
        max-width="400"
        :retain-focus="false"
      >
        <v-card text>
          <v-card-title class="justify-center title">
            {{ $t("addItemToList") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="listItemName"
                    :label="$t('name')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="clearNewListItemDialog()">
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                addNewItemToList();
                dialogs.addItemToList = false;
              "
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<style>
.fixed-height-datatable .v-data-table__wrapper {
  height: calc(32px * 5);
}
</style>
<script>
import { useAppStateStore } from "@store/appState.js";
export default {
  name: "ItemTable",
  props: {
    items: Array,
    taskId: {
      default: -1,
      type: Number,
    },
  },
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
  data() {
    return {
      dialogs: {
        addItemToList: false,
        newItem: false,
      },
      listItemName: "",
      itemList: [],
      item: {
        id: null,
        name: "",
        quantity: 1,
        description: "",
      },
    };
  },
  computed: {
    getItemsPerPage() {
      if (this.$vuetify.breakpoint.xs) return 1;
      return 4;
    },
    itemHeaders() {
      return [
        {
          text: this.$t("name"),
          sortable: true,
          value: "name",
        },
        { text: this.$t("quantity"), value: "quantity", sortable: true },
        { text: this.$t("description"), value: "description", sortable: true },
      ];
    },
  },
  methods: {
    openNewItemDialog() {
      this.itemList = this.itemList.filter(
        (listItem) => !this.items.some((item) => item.id === listItem.id)
      );
      this.dialogs.newItem = true;
    },
    async addNewItemToList() {
      if (this.listItemName) {
        let formData = new FormData();
        formData.set("addNewItem", true);
        formData.set("name", this.listItemName);

        await this.$root.postData(formData);
        this.listItemName = "";
        this.getLists();
      }
    },
    itemRowBackground(item) {
      return !!item.changed ? "blue-grey lighten-4" : "";
    },
    validateNewItem() {
      if (
        this.item.id &&
        this.item.quantity &&
        this.validate(this.item.quantity.toString().replace(",", "."))
      ) {
        if (
          this.items.filter(function (e) {
            return e.id == this;
          }, this.item.id).length == 0
        ) {
          this.item.quantity = this.item.quantity.toString().replace(",", ".");

          this.$emit("actionPerformed", {
            type: "addItemToTask",
            item: JSON.parse(JSON.stringify(this.item)),
            task_id: this.taskId,
          });
          this.clearNewItemDialog(true);
        } else {
          this.AppStateStore.createError(
            this.$options._componentTag,
            "alreadyInItemList"
          );
        }
      } else {
        this.AppStateStore.createError(
          this.$options._componentTag,
          "wrongInput"
        );
      }
    },
    clearNewListItemDialog() {
      this.listItemName = "";
      this.dialogs.addItemToList = false;
    },
    closeNewItemDialog() {
      this.dialogs.newItem = false;
    },
    clearNewItemDialog() {
      let itemId = this.item.id;
      this.itemList = this.itemList.filter((item) => item.id !== itemId);
      this.item = { ...this.itemList[0] };
      this.closeNewItemDialog();
    },

    async getLists() {
      const lists = await this.$root.getLists();
      this.itemList = lists["items"];
    },
    validate(s) {
      let rgx = /^[0-9]*\.?[0-9]*$/;
      return s.match(rgx);
    },
  },
  async mounted() {
    await this.getLists();
  },
};
</script>
