<template>
  <v-overlay
    v-if="AppStateStore.getAppLoading || AppStateStore.getServerLoading"
    :z-index="9999"
  >
    <v-progress-linear
      v-if="AppStateStore.getAppLoading"
      style="display: block; width: 200px; margin: 0 auto"
      rounded
      stream
      height="16"
      :value="AppStateStore.getAppProgress"
    ></v-progress-linear>

    <v-progress-circular
      v-if="AppStateStore.getServerLoading && !AppStateStore.getAppLoading"
      width="6"
      color="info"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import { useAppStateStore } from "@store/appState.js";
export default {
  name: "ProgressBar",
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
};
</script>
