<template>
  <vl-layer-vector render-mode="image" :z-index="997">
    <template>
      <vl-source-vector :features="features"></vl-source-vector>
      <vl-style-box>
        <template v-if="icon.display">
          <vl-style-icon :src="iconSrc" :scale="1"></vl-style-icon>
        </template>
        <template v-else>
          <vl-style-circle :radius="6">
            <vl-style-fill color="#76FF03"></vl-style-fill>
          </vl-style-circle>
        </template>
      </vl-style-box>
    </template>
  </vl-layer-vector>
</template>

<script>
export default {
  name: "MapFeatureCollection",
  props: {
    features: Array,
    icon: {
      type: Object,
      default: () => {
        return { display: false };
      },
    },
  },
  computed: {
    iconSrc() {
      const iconsContext = require.context("@assets/icons/", false, /\.svg$/);
      try {
        return iconsContext("./" + this.icon.path);
      } catch (e) {
        return defaultIcon;
      }
    },
  },
};
</script>
