<template>
  <v-main style="background-color: white" id="guest">
    <v-layout fill-height>
      <GuestMap
        v-show="AppStateStore.getCurrentTab === DashboardTab.MAP"
        :tabToGraveWithToolbar="tabToGraveWithToolbar"
        :currentTab="AppStateStore.getCurrentTab"
      />
      <GuestGrave
        v-if="AppStateStore.getCurrentTab === DashboardTab.GRAVE"
        :grave="grave"
      />
    </v-layout>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import GuestMap from "@components/guest/GuestMap.vue";
import GuestGrave from "@components/guest/GuestGrave.vue";
import { useAppBarStore } from "@store/appBar.js";
import { useAppStateStore } from "@store/appState.js";

import { DashboardTab } from "@utils/constants";

export default {
  components: {
    GuestMap,
    GuestGrave,
  },
  setup() {
    const AppBarStore = useAppBarStore();
    const AppStateStore = useAppStateStore();
    return { AppBarStore, AppStateStore };
  },
  data: () => ({
    DashboardTab: DashboardTab,
    grave: {},
  }),
  methods: {
    async tabToGraveWithToolbar(id) {
      await this.getGrave(id);
      this.setGraveToolbar();
      this.tabToGrave();
    },
    setGraveToolbar() {
      this.AppBarStore.setAppBarTitle(this.$t("grave_details"));
    },
    tabToGrave() {
      this.AppStateStore.setCurrentTabAndBackTo(DashboardTab.GRAVE);
    },
    async getGrave(id) {
      let formData = new FormData();
      formData.set("getGrave", true);
      formData.set("id", id);

      try {
        const response = await this.$root.postData(formData);
        this.grave = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
    initTab() {
      this.AppStateStore.switchTabTo(DashboardTab.MAP);
    },
  },

  mounted() {
    EventBus.$on("refreshComponent", this.initTab);
    this.initTab();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.initTab);
  },
};
</script>
