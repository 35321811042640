<template>
  <v-main class="pb-0">
    <v-container class="pa-0" fluid style="background-color: white">
      <v-card-text class="grey--text headline text-xs-left">
        {{ "Field Manager" }}
      </v-card-text>
      <v-divider></v-divider>

      <template v-for="graveyard in graveyards">
        <GraveyardFieldsTable
          :key="graveyard.id"
          @fieldClicked="handleClick"
          @fieldRandomChanged="getFields"
          :graveyard="graveyard"
          class="mb-10"
        />
      </template>

      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="400">
          <template v-slot:activator="{ on_test }"></template>
          <v-card text>
            <v-container grid-list-xl fluid>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select
                    v-model="selectedField.neighbours"
                    :items="graveyardFields"
                    item-text="ord1"
                    chips
                    multiple
                    required
                    :no-data-text="$t('no_data_text')"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-btn text @click="reset()">
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="saveFields()">
                {{ $t("add") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { EventBus } from "@events/EventBus";
import { useAppStateStore } from "@store/appState.js";
import GraveyardFieldsTable from "@components/fieldManager/GraveyardFieldsTable";
export default {
  setup() {
    const AppStateStore = useAppStateStore();
    return { AppStateStore };
  },
  components: {
    GraveyardFieldsTable,
  },
  data() {
    return {
      graveyards: [],
      fields: [],
      selectedField: "",
      graveyardFields: [],
      dialog: false,
    };
  },
  computed: {},
  methods: {
    reset() {
      this.dialog = false;
      this.selectedField = "";
      this.graveyardFields = [];
    },
    async saveFields() {
      this.selectedField.neighbours = this.selectedField.neighbours.sort();

      let formData = new FormData();
      formData.set("saveFields", true);
      formData.set("id", this.selectedField.graveyard_field_id);
      formData.set("neighbours", JSON.stringify(this.selectedField.neighbours));

      await this.$root.postData(formData);

      this.reset();
    },

    handleClick(eventData) {
      this.selectedField = JSON.parse(JSON.stringify(eventData.field));
      this.graveyardFields = eventData.ord1Array;
      this.dialog = true;
    },
    async getFields() {
      let formData = new FormData();
      formData.set("listGraveyardFields", true);

      try {
        const response = await this.$root.postData(formData);
        this.graveyards = response;
      } catch (error) {
        this.AppStateStore.createErrorFromParam(error);
      }
    },
  },
  mounted() {
    EventBus.$on("refreshComponent", this.getFields);
    this.getFields();
  },
  beforeDestroy() {
    EventBus.$off("refreshComponent", this.getFields);
  },
};
</script>
